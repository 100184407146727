import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MdTrendingUp } from 'react-icons/md';
import QuillEditor from '../../Editor/QuillEditor';

const ValueProposition = ({ stepData, workflowId, refreshWorkflow }) => {
  console.log('Value Proposition Data:', stepData);

  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-red-100 dark:bg-red-900 rounded-full">
          <MdTrendingUp className="text-2xl text-red-button" />
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 dark:text-white">Value Proposition</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Understanding your company&lsquo;s unique value and market position
          </p>
        </div>
      </div>

      <div className="prose prose-lg dark:prose-invert max-w-none">
        <QuillEditor
          content={stepData?.content?.value_proposition || ''}
          workflowId={workflowId}
          stepType="value_proposition"
          onUpdate={(newContent) => {
            console.log('Proposal updated:', newContent);
          }}
          refreshWorkflow={refreshWorkflow}
        />
      </div>
    </div>
  );
};

export default ValueProposition;
