import React from 'react';
import { MdAnalytics } from 'react-icons/md';
import QuillEditor from '../../Editor/QuillEditor';

const Analysis = ({ analysisData, workflowId, refreshWorkflow }) => {
  console.log('Analysis Data:', analysisData);
  console.log('Analysis Content:', analysisData?.content?.analysis);

  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-red-100 dark:bg-red-900 rounded-full">
          <MdAnalytics className="text-2xl text-red-button" />
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 dark:text-white">Analysis Results</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Detailed analysis of your company information
          </p>
        </div>
      </div>

      <div className="prose prose-lg dark:prose-invert max-w-none">
        <QuillEditor
          content={analysisData?.content?.analysis || ''}
          workflowId={workflowId}
          stepType="company_analysis"
          onUpdate={(newContent) => {
            console.log('Analysis updated:', newContent);
          }}
          refreshWorkflow={refreshWorkflow}
        />
      </div>
    </div>
  );
};

export default Analysis;
