import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { ChatContextProvider } from './context/chatContext';
import ChatViewResponsive from './components/ChatViewResponsive';
import RealSignUp from './components/RealSignUp';
import Home from './components/Home';
import NotFound from './components/NotFound';
import WorkFlow from './components/WorkFlow';
import People from './components/People';
import SalesWorkflowPage from './components/WorkFlow/salesWorkflow';
import ReleaseNotesPage from './pages/ReleaseNotesPage/ReleaseNotesPage';

/**
 * Main application component that handles routing and authentication states.
 *
 * The App component sets up the application's routing structure using React Router.
 * It manages authenticated and unauthenticated states using MSAL templates
 * and provides appropriate redirects based on authentication status.
 *
 * @returns {JSX.Element} The rendered application with routing configuration
 */
function App() {
  return (
    <Router>
      <ChatContextProvider>
        <Routes>
          {/* Redirect authenticated users from login to home */}
          <Route
            path="/login"
            element={
              <AuthenticatedTemplate>
                <Navigate to="/home" replace />
              </AuthenticatedTemplate>
            }
          />
          {/* Chat thread view with thread ID and optional selection parameter */}
          <Route
            path="/threads/:threadId/:select?"
            element={
              <>
                <AuthenticatedTemplate>
                  <ChatViewResponsive />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <RealSignUp />
                </UnauthenticatedTemplate>
              </>
            }
          />

          {/* Chat threads landing page */}
          <Route
            path="/threads/"
            element={
              <>
                <AuthenticatedTemplate>
                  <ChatViewResponsive />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            }
          />
          {/* Home page for authenticated users */}
          <Route
            path="/home"
            element={
              <>
                <AuthenticatedTemplate>
                  <Home />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            }
          />
          {/* Landing/signup page for unauthenticated users */}
          <Route
            path="/"
            element={
              <>
                <UnauthenticatedTemplate>
                  <RealSignUp />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <Navigate to="/home" replace />
                </AuthenticatedTemplate>
              </>
            }
          />
          {/* Workflow routes */}
          <Route path="/workflow" element={<SalesWorkflowPage />} />
          <Route path="/workflow/sales" element={<SalesWorkflowPage />} />
          <Route path="/workflow/sales/:workflowId" element={<WorkFlow />} />
          {/* People management page */}
          <Route path="/people" element={<People />} />
          {/* 404 page */}
          <Route path="*" element={<NotFound />} />
          {/* Release notes page */}
          <Route path="/release-notes" element={<ReleaseNotesPage />} />
        </Routes>
      </ChatContextProvider>
    </Router>
  );
}

export default App;
