import React, { useEffect, useState } from 'react';
import Layout from '../../components/ReleaseNotesPage/Layout';
import useAxiosInstance from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { formatDate } from '../../utils/formatStringUtils';

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const axiosInstance = useAxiosInstance();
  console.log(releaseNotes);

  //import release notes from the database
  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        const response = await axiosInstance.get('/api/release-notes/');
        setReleaseNotes(response.data);
      } catch (error) {
        toast.error('Failed to fetch release notes');
      }
    };
    fetchReleaseNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div
        id="release-notes-page"
        className=" w-full h-full flex-1 bg-white dark:bg-dark-bg rounded-3xl flex flex-col overflow-y-scroll  items-center justify-start "
      >
        {/* Wrapper */}
        <div className="z-10 h-full w-[70%] md:w-[60%] flex flex-col  items-start justify-start py-5 2xl:py-10 gap-12">
          {/* Header */}
          <div className="flex flex-col items-start gap-2">
            <h1 className="text-3xl 2xl:text-4xl font-semibold text-black">Release Notes</h1>
            <span className="text-xs 2xl:text-base">
              A Changelog of the latest updates of Elysium
            </span>
            <p className="text-base mt-6 text-black">
              Beta 1 is now accessible with the newest features and updates for mobile applications.
              To begin, download the beta on your device. Elysium Beta is open to both users and
              testers, so ensure to check your device&apos;s compatibility and release any necessary
              updates. Explore the new features and provide your feedback using our issue tracker.
            </p>
          </div>
          {/* Release Notes */}
          <div className="w-full h-full pb-10 flex flex-col">
            {releaseNotes?.map((note) => (
              //Note Card
              <div
                className="w-full h-max border border-slight-gray dark:border-light-white rounded-lg p-3 xl:p-6 flex flex-col gap-3 xl:gap-6"
                key={note?.id}
                id={`${note?.id}`}
              >
                {/* Note Header */}
                <h1 className="text-base xl:text-lg font-semibold text-black capitalize">
                  <span>{note.title}</span>
                  <span> ({formatDate(note.release_date)})</span>
                </h1>
                <p className="text-sm text-black font-light xl:text-base capitalize">
                  {note.description}
                </p>
                <ul className="list-disc px-4 text-base font-light text-black">
                  {note?.changes?.map((change) => (
                    <li key={note?.ids}>{change?.description}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReleaseNotes;
