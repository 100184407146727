import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import { FaRegDotCircle } from 'react-icons/fa';
import { FiGitCommit } from 'react-icons/fi';
import blackLogo from '../assets/blackLogo.png';
import whiteLogo from '../assets/whiteLogo.png';
import useAxiosInstance from '../utils/axiosInstance';
import { useChatContext } from '../context/chatContext';
import AgentMenu from './AgentMenu';
import robo from '../assets/robo.png';
import carter from '../assets/carter.png';
import chiron from '../assets/chiron.png';
import eli from '../assets/eli.png';
import justine from '../assets/justine.png';
import teresa from '../assets/teresa.png';
import { NewDropdown, StatusTextarea, WorkLocationMenu } from './DropdownComponents';
import { ReactComponent as LogoutSvg } from '../Svg/logout.svg';
import { GoHome } from 'react-icons/go';
import { ReactComponent as OfficeIcon } from '../Svg/office.svg';
import { useMsal } from '@azure/msal-react';

// SVGs imported as React Components
import { ReactComponent as AgentsIcon } from '../Svg/agents_filter.svg';
import { ReactComponent as ArrowDropDownIcon } from '../Svg/arrow_drop_down.svg';
import { ReactComponent as WriteIcon } from '../Svg/write.svg';
import { ReactComponent as ArrowRightIcon } from '../Svg/arrow_right.svg';
import { ReactComponent as StatusIcon } from '../Svg/status.svg';
import { ReactComponent as LocationIcon } from '../Svg/location.svg';
import defaultProfilePicture from '../assets/defaultProfileImgIcon.png';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const dropdownRef = useRef(null);
  const axiosInstance = useAxiosInstance();
  const [isAgentMenuOpen, setIsAgentMenuOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Bring in our chat context
  const {
    setMessages,
    setDefaultThread,
    setShowHistoryGrid,
    setShowWelcomeModal,
    setShowSuggestionGrid,
    profilePicture,
    user,
    userStatusDescription,
    setUserStatusDescription,
    userStatus,
    setUserStatus,
    userLocation,
    setUserLocation,
    getUserStatus,
  } = useChatContext();

  const navigate = useNavigate();
  const applicationVersion = process.env.REACT_APP_VERSION;

  // Add new state for toggling the location dropdown
  const [isNewDropdownOpen, setIsNewDropdownOpen] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [isStatusTextareaOpen, setIsStatusTextareaOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { instance } = useMsal();

  // For writing custom status
  const [statusText, setStatusText] = useState(userStatusDescription);

  // Toggles the entire user menu
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => {
      // Close submenus if the main dropdown is closing
      if (prev) {
        setIsNewDropdownOpen(false);
        setIsLocationDropdownOpen(false);
        setIsStatusTextareaOpen(false);
      }
      return !prev;
    });
  };

  // Toggle submenus
  const toggleNewDropdown = () => {
    if (isUpdating) return;
    setIsNewDropdownOpen((prev) => {
      if (!prev) {
        setActiveDropdown('Available');
        setIsLocationDropdownOpen(false);
        setIsStatusTextareaOpen(false);
      } else {
        setActiveDropdown(null);
      }
      return !prev;
    });
  };

  const toggleLocationDropdown = () => {
    if (isUpdating) return;
    setIsLocationDropdownOpen((prev) => {
      if (!prev) {
        setActiveDropdown('SetWorkLocation');
        setIsNewDropdownOpen(false);
        setIsStatusTextareaOpen(false);
      } else {
        setActiveDropdown(null);
      }
      return !prev;
    });
  };

  const toggleStatusTextarea = () => {
    if (isUpdating) return;
    setIsStatusTextareaOpen((prev) => {
      if (!prev) {
        setActiveDropdown('WriteStatus');
        setIsNewDropdownOpen(false);
        setIsLocationDropdownOpen(false);
      } else {
        setActiveDropdown(null);
      }
      return !prev;
    });
  };

  // Fetch user data
  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get('/api/users/me/');
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error.response?.data || error.message);
    }
  };

  // Handle agent menu
  const toggleAgentMenu = () => {
    setIsAgentMenuOpen((prev) => !prev);
  };

  const handleAgentSelect = (agentName) => {
    const updatedAgents = agents.map((agent) =>
      agent.name === agentName ? { ...agent, isActive: true } : { ...agent, isActive: false },
    );
    setAgents(updatedAgents);
  };

  const [agents, setAgents] = useState([
    { name: 'Ava', image: robo, isActive: false },
    { name: 'Justine', image: justine, isActive: false },
    { name: 'Chiron', image: chiron, isActive: false },
    { name: 'Carter', image: carter, isActive: false },
    { name: 'Eli', image: eli, isActive: false },
    { name: 'Teresa', image: teresa, isActive: false },
  ]);

  // Set user status
  const handleStatusSelect = async (status) => {
    setIsUpdating(true);
    try {
      setUserStatus(status);
      await axiosInstance.post('/api/user-status/', {
        user: user.id,
        status: status,
      });
    } catch (error) {
      console.error('Error submitting status:', error.response?.data || error.message);
    } finally {
      setIsUpdating(false);
      setIsNewDropdownOpen(false);
    }
  };

  // Set user location
  const handleWorkLocationSelect = async (location) => {
    setIsUpdating(true);
    try {
      // Post location changes to the server
      await axiosInstance.post('/api/user-status/', {
        user: user.id,
        work_location: location,
      });
      setUserLocation(location);
      // Optionally update local user data if you display it
      setUserData((prev) => ({
        ...prev,
        work_location: location,
      }));
    } catch (error) {
      console.error('Error updating work location:', error.response?.data || error.message);
    } finally {
      setIsLocationDropdownOpen(false);
      setIsUpdating(false);
    }
  };

  // Save custom status message
  const saveStatusDescription = async (newDescription) => {
    setIsUpdating(true);
    try {
      const saved_status = await axiosInstance.post('/api/user-status/', {
        user: user.id,
        description: newDescription,
      });
      setUserStatusDescription(saved_status.data.description);
    } catch (error) {
      console.error('Error submitting status:', error.response?.data || error.message);
    } finally {
      setIsStatusTextareaOpen(false);
      setIsUpdating(false);
    }
  };

  // Handle the click on the main logo
  const handleLogoClick = (e) => {
    e.preventDefault();
    // Reset messages
    setMessages([]);
    setDefaultThread(null);
    // Show welcome screen
    setShowWelcomeModal(true);
    setShowHistoryGrid(true);
    setShowSuggestionGrid(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/home');
  };

  useEffect(() => {
    fetchUserData();
    getUserStatus();
  }, []);

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsNewDropdownOpen(false);
        setIsLocationDropdownOpen(false);
        setIsStatusTextareaOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Render status icons
  const renderStatusIcon = () => {
    switch (userStatus) {
      case 'Available':
        return <BsCheckCircle className="text-success" />;
      case 'Do not disturb':
        return <FaRegDotCircle className="text-red-button" />;
      case 'Set away':
        return <FiGitCommit className="text-close-button" />;
      default:
        return <BsCheckCircle className="text-success" />;
    }
  };

  const renderLocationIcon = () => {
    switch (userLocation) {
      case 'office':
        return (
          <OfficeIcon
            width="20"
            height="20"
            className="fill-icon-light-fill dark:fill-white"
            role="img"
            aria-label="Office Icon"
          />
        );
      case 'home':
        return (
          <GoHome
            className="fill-icon-light-fill dark:fill-white"
            width="20"
            height="20"
            aria-label="Home Icon"
          />
        );
      default:
        return (
          <LocationIcon
            width="20"
            height="20"
            className="fill-icon-light-fill dark:fill-white"
            role="img"
            aria-label="Location Icon"
          />
        );
    }
  };
  // Logout

  const handleLogoutHome = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin + '/',
      });
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  return (
    <header className="flex justify-between items-center bg-background-gray h-[60px] dark:bg-black dark:text-white z-50">
      {/* Left Section: Logo & Agents */}
      <div className="flex items-center space-x-2 ml-3">
        <div className="text-close-button p-2 rounded-lg dark:text-gray-icon cursor-not-allowed ">
          {/**under development */}
          {/*className="text-close-button cursor-pointer hover:bg-text-gray p-2 rounded-lg dark:hover:bg-grid-gray dark:text-gray-icon" */}
          {/*onClick={toggleAgentMenu}*/}
          <AgentsIcon
            width="22"
            height="22"
            className="fill-icon-light-fill dark:fill-white"
            role="img"
            aria-label="Agents"
            title="Coming Soon"
            disabled
          />
        </div>
        <div className=" z-50">
          <a
            href="/threads"
            onClick={handleLogoClick} // Handle logo click
            className="flex items-center"
          >
            {/* Light Mode Logo (PNG remains as <img>) */}
            <img
              src={blackLogo}
              alt="Logo"
              className="ml-2 cursor-pointer 2xl:w-[169px] xl:w-[159px] lg:w-[139px] md:w-[129px] sm:w-[119px] w-[109px]  dark:hidden"
            />
            {/* Dark Mode Logo (PNG remains as <img>) */}
            <img
              src={whiteLogo}
              alt="Dark Logo"
              className="ml-2 cursor-pointer 2xl:w-[169px]  xl:w-[159px] lg:w-[139px] md:w-[129px] sm:w-[119px] w-[109px]  hidden dark:block"
            />
            {applicationVersion && (
              <span className="ml-2 text-xs text-text-black dark:text-gray-icon">
                v.{applicationVersion}
              </span>
            )}
          </a>
        </div>
      </div>

      {/* Right Section: User Info & Dropdown */}
      <div
        className="relative w-auto h-auto flex items-center bg-welcome mr-4 rounded-lg shadow-sm cursor-pointer dark:bg-grid-gray"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        {/* Avatar */}
        {profilePicture === null ? (
          // Loader while the profile picture is being fetched
          <img
            src={defaultProfilePicture}
            alt="User"
            className="2xl:w-[40px] 2xl:h-[40px] xl:w-[31px] xl:h-[31px] lg:w-[30px] lg:h-[30px] md:w-[28px] md:h-[28px] sm:w-[26px] sm:h-[26px] w-[24px] h-[24px] rounded-full object-cover ml-1"
          />
        ) : profilePicture ? (
          <img
            src={profilePicture}
            alt="User"
            className="2xl:w-[32px] 2xl:h-[32px] xl:w-[31px] xl:h-[31px] lg:w-[30px] lg:h-[30px] md:w-[28px] md:h-[28px] sm:w-[26px] sm:h-[26px] w-[24px] h-[24px] ml-1 rounded-full object-cover ml-1"
          />
        ) : (
          <div className="2xl:w-[32px] 2xl:h-[32px] xl:w-[31px] xl:h-[31px] lg:w-[30px] lg:h-[30px] md:w-[28px] md:h-[28px] sm:w-[26px] sm:h-[26px] w-[24px] h-[24px] rounded-full object-cover ml-1 bg-gray-200" />
        )}
        {/* Name and Email */}
        <div className="ml-2 flex flex-col">
          {userData ? (
            <>
              <span className="2xl:text-[15px] xl:text-[14px] lg:text-[12px] md:text-[10px] sm:text-[8px] text-[7px] font-normal text-black dark:text-white">
                {userData.username}
              </span>
              <span className="2xl:text-[12px] xl:text-[11px] lg:text-[10px] md:text-[8px] sm:text-[6px] text-[5px] font-normal text-text-black dark:text-gray-icon">
                {userData.email}
              </span>
            </>
          ) : (
            <span className="2xl:text-[15px] xl:text-[14px] lg:text-[12px] md:text-[10px] sm:text-[8px] text-[7px] font-semibold text-black dark:text-white">
              Loading...
            </span>
          )}
        </div>

        {/* Replace <img src={ArrowDropDownIcon} .../> with SVG + fill classes */}
        <ArrowDropDownIcon
          width="22"
          height="22"
          className="
            fill-icon-light-fill 
            dark:fill-white 
            ml-auto mr-3 
            2xl:text-[24px] 
            xl:text-[22px] 
            lg:text-[20px] 
            md:text-[18px] 
            sm:text-[16px] 
            text-[12px] 
            text-close-button 
            dark:text-white
          "
          role="img"
          aria-label="Toggle Dropdown"
        />

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div
            className="dark:bg-black dark:text-white absolute 2xl:top-[44px] xl:top-[40px] lg:top-[44px] md:top-[40px] sm:top-[36px] top-[30px] right-0 2xl:w-[220px] lg:w-[182px] md:w-[172px] sm:w-[162px] w-[152px] bg-white rounded-lg shadow-md z-[999]"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="2xl:p-4 xl:p-2 lg:p-3 md:p-2.5 sm:p-2 p-1.5 flex items-center justify-between">
              <div className="flex-grow">
                {userData ? (
                  <>
                    <div className="flex items-start justify-start gap-2">
                      <p className="flex flex-col flex-1 min-w-0">
                        <span className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] font-bold text-black dark:text-white truncate">
                          {userData.username}
                        </span>
                        <span className="2xl:text-[12px] xl:text-[11px] lg:text-[10px] md:text-[8px] sm:text-[6px] text-[5px] text-text-black dark:text-white truncate">
                          {userData.email}
                        </span>
                      </p>
                      {profilePicture ? (
                        <img
                          src={profilePicture}
                          alt="User"
                          className="2xl:w-[40px] 2xl:h-[40px] xl:w-[31px] xl:h-[31px] lg:w-[30px] lg:h-[30px] md:w-[28px] md:h-[28px] sm:w-[26px] sm:h-[26px] w-[24px] h-[24px] rounded-full object-cover flex-shrink-0"
                        />
                      ) : (
                        <img
                          src={defaultProfilePicture}
                          alt="User"
                          className="2xl:w-[40px] 2xl:h-[40px] xl:w-[31px] xl:h-[31px] lg:w-[30px] lg:h-[30px] md:w-[28px] md:h-[28px] sm:w-[26px] sm:h-[26px] w-[24px] h-[24px] rounded-full object-cover flex-shrink-0"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <p className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] font-semibold text-black dark:bg-black dark:text-white">
                    Loading...
                  </p>
                )}
              </div>
            </div>
            <div className="mb-2">
              <hr className="bg-[rgba(20,20,20,0.10)] h-[1px]" />
            </div>
            <div
              className={`flex items-center pt-[8px] pb-[8px] pr-[12px] pl-[12px] cursor-pointer ${
                isUpdating ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={(e) => {
                if (!isUpdating) {
                  e.stopPropagation();
                  toggleNewDropdown();
                }
              }}
            >
              {renderStatusIcon()}
              <span
                className={`pl-3 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] text-text-black dark:text-white ${
                  activeDropdown === 'Available' ? 'text-[var(--Black,#141414)]' : ''
                }`}
              >
                {userStatus || 'Available'}
              </span>

              {/* Replace <img src={ArrowRightIcon} .../> with SVG + fill classes */}
              <ArrowRightIcon
                width="20"
                height="20"
                className="
                  fill-icon-light-fill 
                  dark:fill-white 
                  text-close-button 
                  dark:text-white 
                  ml-auto
                  2xl:text-[20px] 
                  xl:text-[19px] 
                  lg:text-[18px] 
                  md:text-[15px] 
                  sm:text-[13px] 
                  text-[10px]
                "
                role="img"
                aria-label="Next"
              />
            </div>
            {/* Status Options Dropdown */}
            <NewDropdown
              isOpen={isNewDropdownOpen}
              toggleNewDropdown={setIsNewDropdownOpen}
              onSelect={handleStatusSelect}
              options={['Available', 'Set away', 'Do not disturb']}
              selectedOption={userStatus}
            />

            {/* Location Option (the new part) */}
            <div
              className={`flex items-center pt-[8px] pb-[8px] pr-[12px] pl-[12px] cursor-pointer ${
                isUpdating ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={(e) => {
                if (!isUpdating) {
                  e.stopPropagation();
                  toggleLocationDropdown();
                }
              }}
            >
              {renderLocationIcon()}
              <span className="pl-3 text-sm dark:text-white capitalize">
                {userLocation || 'Set work location'}
              </span>
              <ArrowRightIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white ml-auto"
                role="img"
                aria-label="Next"
              />
            </div>
            {/* Location Dropdown */}
            <WorkLocationMenu
              isOpen={isLocationDropdownOpen}
              toggleWorkLocationMenu={setIsLocationDropdownOpen}
              onSelect={handleWorkLocationSelect}
            />

            {/* Custom Status Option */}
            <div
              className={`flex items-center py-2 px-3 cursor-pointer ${
                isUpdating ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={(e) => {
                if (!isUpdating) {
                  e.stopPropagation();
                  toggleStatusTextarea();
                }
              }}
            >
              <StatusIcon
                width="20"
                height="20"
                className="
      fill-icon-light-fill 
      dark:fill-white 
      text-close-button 
      dark:text-white 
      2xl:text-[20px] 
      xl:text-[19px] 
      lg:text-[18px] 
      md:text-[15px] 
      sm:text-[13px] 
      text-[10px] 
      mr-2
      min-w-[20px]
      min-h-[20px]
    "
                role="img"
                aria-label="Status Icon"
              />
              <span className="text-sm dark:text-white">
                {userStatusDescription
                  ? `${userStatusDescription.slice(0, 12)}${
                      userStatusDescription.length > 12 ? '...' : ''
                    }`
                  : 'No status'}
              </span>

              <ArrowRightIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white ml-auto min-w-[20px] min-h-[20px]"
                role="img"
                aria-label="Next"
              />
            </div>
            {/* Textarea Dropdown for custom status */}
            <StatusTextarea
              isOpen={isStatusTextareaOpen}
              setIsStatusTextareaOpen={setIsStatusTextareaOpen}
              onSubmit={saveStatusDescription}
              statusText={statusText}
              setStatusText={setStatusText}
            />

            <div className="mb-2 mt-2">
              <hr className="bg-[rgba(20,20,20,0.10)] h-[1px]" />
            </div>

            {/* Logout */}
            <div className="flex items-center py-2 px-3 cursor-pointer" onClick={handleLogoutHome}>
              <LogoutSvg
                width="24"
                height="24"
                className="
      fill-icon-light-fill 
      dark:fill-white 
      text-close-button 
      dark:text-white 
      2xl:text-[20px] 
      xl:text-[19px] 
      lg:text-[18px] 
      md:text-[15px] 
      sm:text-[13px] 
      text-[10px] 
      mr-2
    "
                role="img"
                aria-label="Logout Icon"
              />
              <span className="text-sm dark:text-white">Logout</span>
            </div>
          </div>
        )}
      </div>

      {/* Agent Menu */}
      {isAgentMenuOpen && (
        <AgentMenu
          onClose={() => setIsAgentMenuOpen(false)}
          agents={agents}
          onSelectAgent={handleAgentSelect}
        />
      )}
    </header>
  );
};

export default Header;
