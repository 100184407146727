import React from 'react';
import { ChatContextProvider } from '../../context/chatContext';
import WorkFlow from './WorkFlow';
import Header from '../Header';

const WorkFlowPage = () => {
  return (
    <ChatContextProvider>
      <div className="flex flex-col h-screen overflow-hidden bg-background-gray dark:bg-black ">
        <div className="flex-1 overflow-y-auto">
          <WorkFlow />
        </div>
      </div>
    </ChatContextProvider>
  );
};

export default WorkFlowPage;
