import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import CopyIcon from '../Svg/copy.svg';
import DeleteIcon from '../Svg/delete.svg';
import { toast, Toaster } from 'react-hot-toast';
import useAxiosInstance from '../utils/axiosInstance';
import defaultProfilePicture from '../assets/defaultProfileImgIcon.png';

const ShareChat = ({ isOpen, onClose }) => {
  const location = useLocation();
  const [shareableLink, setShareableLink] = useState('');
  const [emailOrName, setEmailOrName] = useState('');
  const [sharedUsers, setSharedUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareStatus] = useState(null);
  const axiosInstance = useAxiosInstance();
  const suggestionsRef = useRef(null);
  

  // Fetch registered users when the component mounts
  // useEffect(() => {
  //   const fetchRegisteredUsers = async () => {
  //     try {
  //       const response = await fetch('/api/registered-users'); // Replace with your actual endpoint
  //       const data = await response.json();
  //       setRegisteredUsers(data); // Assuming `data` is an array of user objects
  //     } catch (error) {
  //       console.error('Error fetching registered users:', error);
  //     }
  //   };

  //   fetchRegisteredUsers();
  // }, []);

  // Set the shareable link when the component mounts or when isOpen changes
  useEffect(() => {
    if (isOpen) {
      // Get the full URL including the protocol, hostname, and pathname
      const currentUrl = window.location.origin + location.pathname;
      setShareableLink(currentUrl);
    }
  }, [isOpen, location]);

  // Fetch suggestions using your API endpoint
  const fetchSuggestions = async (query) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/user-suggestions?search=${query}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Add debouncing to prevent too many API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (emailOrName) {
        fetchSuggestions(emailOrName);
      } else {
        setSuggestions([]);
      }
    }, 300); // 300ms delay

    return () => clearTimeout(timeoutId);
  }, [emailOrName]);

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailOrName(value);
    setShowSuggestions(true);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setEmailOrName(suggestion.email);
    setShowSuggestions(false);
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleShare = async () => {
    
    if (!emailOrName || !shareableLink) {
      console.log('Missing required data');
      return;
    }

    setIsSharing(true);
    try {
      const selectedUser = suggestions.find(
        suggestion => suggestion.email === emailOrName
      );
      if(!selectedUser){
        toast.error('User not registered');
        return;
      }
      const payload = {
        email: selectedUser?.email || emailOrName,
        user_name: selectedUser?.name || '',
        thread_link: shareableLink
      };
      
      await axiosInstance.post('/api/share-chat/', payload);
      toast.success('Chat shared successfully');
      setEmailOrName('');
      if (onClose) onClose();
    } catch (error) {
      console.error('Error sharing chat:', error);
    } finally {
      setIsSharing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-[400px] p-6 dark:bg-black">
        <Toaster />
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-black text-lg font-semibold dark:text-white">Share Chat</h2>
          <MdClose
            className="text-close-button text-xl cursor-pointer dark:text-white"
            onClick={onClose}
          />
        </div>

        {/* Share Read-Only Link */}
        <div className="mb-5">
          <div className="flex justify-between items-center">
            <p className="text-black text-md font-medium dark:text-white">Share read-only link</p>
          </div>
          <p className="text-close-button mb-4 dark:text-white">
            Anyone with the link can view this file, but won&apos;t be able to edit it.
          </p>
          <div className="flex items-center border rounded-lg px-3 py-2 bg-[#1414141A]">
            <input
              type="text"
              value={shareableLink}
              readOnly
              className="flex-grow text-black bg-transparent focus:outline-none dark:text-white"
            />
            <img
              src={CopyIcon}
              alt="Copy"
              width="20"
              height="20"
              onClick={() => {
                navigator.clipboard.writeText(shareableLink);
                toast.success("Link copied to clipboard!", {
                  position: "top-center",
                  style: {
                    backgroundColor: "#4BB543",
                    color: "#fff",
                    fontSize: "14px",
                  },
                });
              }}
              className="ml-2 cursor-pointer dark:text-white"
            />
          </div>
        </div>
        <hr className="border-[#F1F1F1] mb-4" />

        {/* Share with Team Member */}
        <div>
          <p className="text-black font-medium dark:text-white">Share with team member</p>
          <p className="text-close-button mb-2 dark:text-white">
            Anyone with the link can view this file.
          </p>
          <div className="flex items-center gap-2">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Share by email or name"
                value={emailOrName}
                onChange={handleInputChange}
                className="w-full border text-black bg-transparent rounded-lg px-3 py-2 focus:outline-none dark:text-white"
              />
              
              {/* Updated Suggestions dropdown */}
              {showSuggestions && (emailOrName.length > 0) && (
                <div className="absolute z-50 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                  {isLoading ? (
                    <div className="px-4 py-2 text-gray-500 dark:text-gray-400">
                      Loading...
                    </div>
                  ) : suggestions.length > 0 ? (
                    suggestions.map((suggestion, index) => (
                      <div
                        key={suggestion.id || index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <div className="flex items-center space-x-3">
                          {/* Profile Picture */}
                          <div className="flex-shrink-0">
                            <img 
                              src={suggestion.profile_picture || defaultProfilePicture}
                              alt=""
                              className="w-8 h-8 rounded-full object-cover"
                            />
                          </div>
                          
                          {/* Name and Email - Updated logic */}
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-900 dark:text-white">
                              {suggestion.username || suggestion.email}
                            </span>
                            {suggestion.username && (
                              <span className="text-xs text-gray-500 dark:text-gray-400">
                                {suggestion.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-2 text-gray-500 dark:text-gray-400">
                      No suggestions found
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Updated Share button with loading state */}
            <button
              onClick={handleShare}
              disabled={!emailOrName || isSharing}
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                !emailOrName || isSharing
                  ? 'bg-gray-300 cursor-not-allowed dark:bg-gray-700'
                  : 'bg-red-button hover:bg-red-hover text-white'
              }`}
            >
              {isSharing ? (
                <div className="flex items-center">
                  <svg 
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24"
                  >
                    <circle 
                      className="opacity-25" 
                      cx="12" 
                      cy="12" 
                      r="10" 
                      stroke="currentColor" 
                      strokeWidth="4"
                    />
                    <path 
                      className="opacity-75" 
                      fill="currentColor" 
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Sharing...
                </div>
              ) : (
                'Share'
              )}
            </button>
          </div>
        </div>

        {/* Shared Users Display */}
        <div className="mt-4">
          {sharedUsers.map((user, index) => (
            <div key={index} className="flex items-center justify-between p-2 rounded mb-2">
              <div className="flex items-center">
                <img
                  src={user.image || "default-profile-image-url"}
                  alt={user.name}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <span className="text-sm text-black dark:text-white">{user.email}</span>
              </div>
              <button
                type="button"
                onClick={() => setSharedUsers((prev) => prev.filter((_, i) => i !== index))}
                className="text-red-500 hover:text-red-700"
              >
                <img
                  src={DeleteIcon}
                  alt="Delete"
                  width="20"
                  height="20"
                  className="mr-2 text-close-button dark:text-white"
                />
              </button>
            </div>
          ))}
        </div>

        {/* Status messages */}
        {shareStatus && (
          <div className={`absolute top-full mt-2 left-0 right-0 p-2 rounded text-center ${
            shareStatus === 'success' 
              ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' 
              : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
          }`}>
            {shareStatus === 'success' 
              ? 'Chat shared successfully!' 
              : 'Failed to share chat. Please try again.'}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareChat;
