import React, { useState, useEffect, useRef } from 'react';

const TypingRenderer = ({ text, setIsElysiumTyping, className, typingSpeed = 30 }) => {
  const [renderedContent, setRenderedContent] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [hasStartedTyping, setHasStartedTyping] = useState(true);
  const animationFrameRef = useRef(null);
  const lastTimeRef = useRef(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!text) return;

    let mounted = true;
    setRenderedContent('');
    setHasStartedTyping(true);
    lastTimeRef.current = 0;
    timeoutRef.current = setTimeout(() => {
      if (!mounted) return;

      setHasStartedTyping(true);

      const animate = (currentTime) => {
        if (!mounted) return;

        if (!lastTimeRef.current) lastTimeRef.current = currentTime;
        const deltaTime = currentTime - lastTimeRef.current;

        if (deltaTime >= typingSpeed) {
          lastTimeRef.current = currentTime;

          setRenderedContent((prev) => {
            if (prev.length >= text.length) {
              setIsTyping(false);
              setIsElysiumTyping(false);
              return prev;
            }
            return text.slice(0, prev.length + 1);
          });
        }

        if (renderedContent.length < text.length) {
          animationFrameRef.current = requestAnimationFrame(animate);
        }
      };

      animationFrameRef.current = requestAnimationFrame(animate);
    }, 500);

    return () => {
      mounted = false;
      clearTimeout(timeoutRef.current);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [text, typingSpeed]);

  return (
    <div
      className={className}
      style={{
        display: 'block',
        wordWrap: 'break-word',
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        boxSizing: 'border-box',
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html:
            renderedContent +
            (hasStartedTyping && isTyping ? '<span class="animate-pulse">▋</span>' : ''),
        }}
      />
    </div>
  );
};

export default TypingRenderer;
