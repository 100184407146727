import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header';
import toast, { Toaster } from 'react-hot-toast';
import SideBar from '../../SideBar';
import useAxiosInstance from '../../../utils/axiosInstance';
import CompanyDetails from '../components/Steps/StepContent/CompanyDetails';
import { useWorkflow } from '../hooks/useWorkflow';

const getStatusColor = (currentStep) => {
  switch (currentStep) {
    case 'completed':
      return 'bg-green-100 text-green-800';
    case 'initial':
      return 'bg-gray-100 text-gray-800';
    case 'analysis':
      return 'bg-blue-100 text-blue-800';
    case 'proposal':
      return 'bg-yellow-100 text-yellow-800';
    default:
      return 'bg-blue-100 text-blue-800';
  }
};

const SalesWorkflow = () => {
  const [showHistoryPanel, setShowHistoryPanel] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [showFeatureUnavailable, setShowFeatureUnavailable] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  const { formData, handleInputChange, errors, validateForm, setErrors } = useWorkflow();

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get('/api/workflows/');
        console.log('Workflows response:', response.data);
        setWorkflows(response.data);
      } catch (error) {
        console.error('Error fetching workflows:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkflows();
  }, []);

  const handleWorkflowClick = (workflowId) => {
    navigate(`/workflow/sales/${workflowId}`);
  };

  const handleCreateNewWorkflow = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Submit called');

    if (!validateForm()) {
      console.log('Form validation failed');
      toast.error('Please fix the errors before submitting');
      return;
    }

    try {
      console.log('Submitting form data:', formData);
      const response = await axiosInstance.post('/api/workflows/', formData);

      if (response.status === 200 || response.status === 201) {
        const { workflow_id } = response.data;
        console.log('Received workflow ID:', workflow_id);
        toast.success('Company details submitted successfully');
        setIsModalOpen(false);

        // Navigate to the new workflow
        navigate(`/workflow/sales/${workflow_id}`);
      }
    } catch (error) {
      console.error('Form submission error:', error);
      console.error('Error response:', error.response?.data);
      if (error.response?.data) {
        const apiErrors = error.response.data;
        setErrors(apiErrors);
        const errorMessage = Object.values(apiErrors)[0];
        toast.error(errorMessage);
      } else {
        toast.error('Error submitting company details');
      }
    }
  };

  return (
    <div className="h-screen">
      <Header />
      <Toaster position="top-right" reverseOrder={false} />

      {/* Company Details Modal */}
      <CompanyDetails
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        errors={errors}
      />

      <div className="flex bg-background-white dark:bg-black">
        <SideBar
          showHistoryPanel={showHistoryPanel}
          setShowHistoryPanel={setShowHistoryPanel}
          setCanvasWidth={setCanvasWidth}
          setShowFeatureUnavailable={setShowFeatureUnavailable}
        />

        {/* Main Content */}
        <div className="flex-1 p-8 h-[87vh] overflow-y-auto bg-white dark:bg-gray-900 rounded-3xl mr-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold dark:text-white">Sales Workflows</h2>
            <button
              onClick={handleCreateNewWorkflow}
              className="flex items-center gap-2 px-4 py-2 bg-red-button text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Create New Workflow
            </button>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {workflows.map((workflow) => (
                <div
                  key={workflow.workflow_id}
                  onClick={() => handleWorkflowClick(workflow.workflow_id)}
                  className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg cursor-pointer 
                    hover:shadow-xl transition-shadow duration-200"
                >
                  <h3 className="text-lg font-semibold mb-2 dark:text-white">
                    {workflow.target_company_name}
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 text-sm space-y-2">
                    <p>{workflow.target_industry}</p>
                    <p>{workflow.target_location}</p>
                    <p>Created: {new Date(workflow.created_at).toLocaleDateString()}</p>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Steps: {workflow.steps_count}/6
                    </span>
                    <span
                      className={`px-3 py-1 rounded-full text-xs ${getStatusColor(
                        workflow.current_step,
                      )}`}
                    >
                      {workflow.current_step}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Disclaimer */}
      <div
        className={`flex bg-background-gray dark:bg-black justify-center 2xl:ml-[40px] xl:ml-[60px] lg:ml-[70px] md:ml-[60px] sm:ml-[60px] ml-[50px] ${
          showHistoryPanel
            ? '2xl:mr-[350px] xl:mr-[300px] lg:mr-[200px] md:mr-[200px] sm:mr-[170px] mr-[180px]'
            : ''
        } transition-[width,margin] duration-1000`}
      >
        <h1
          className={`2xl:w-[848px] xl:w-[780px] lg:w-[760px] md:w-[560px] sm:w-[460px] w-[260px] ${
            showHistoryPanel ? 'text-left' : 'text-left'
          } transition-colors duration-150 dark:text-gray-icon 2xl:text-[12px] xl:text-[10px] lg:text-[8px] md:text-[6px] sm:text-[5px] text-[4px] py-2`}
        >
          Disclaimer: Elysium Workspace chats are private and not used to improve AI models. Elysium
          AI may occasionally provide inaccurate or incomplete
          <br />
          information; please verify critical details independently.
        </h1>
      </div>
    </div>
  );
};

export default SalesWorkflow;
