/* eslint-disable react/jsx-no-bind */

import React, { useEffect } from 'react';
import { useChatContext } from '../context/chatContext';
import { MdGrain } from 'react-icons/md';
import { MdRestartAlt } from 'react-icons/md';

const SuggestionGrid = ({ showHistoryPanel, onSendMessage }) => {
  const { showSuggestionGrid, getSuggestions, isSuggestionsLoading, suggestions } =
    useChatContext();

  // extra conditions to ensure it runs only once
  useEffect(() => {
    if (showSuggestionGrid) {
      if (!isSuggestionsLoading && !suggestions.length > 0) {
        getSuggestions();
      }
    }
  }, [showSuggestionGrid, getSuggestions, isSuggestionsLoading, suggestions]);

  if (!showSuggestionGrid) return null;

  const handleSuggestionClick = (suggestion) => {
    // Append the suggestion to existing text
    // const currentText = formValue || '';
    // const separator = currentText ? ' ' : '';
    // const newText = currentText + separator + suggestion;

    // setFormValue(newText);
    // onClick();

    // Call sendMessage with the new text
    onSendMessage({ preventDefault: () => {}, target: { value: suggestion } });
  };

  return (
    <div className="inset-0 flex h-full items-center justify-center w-[95%]  lg:w-[60%] z-10">
      {/* to avoid showing spinner when loading chats spinner is shown */}
      {isSuggestionsLoading || !suggestions.length > 0 ? (
        <span></span>
      ) : (
        <div className="p-4 rounded-lg ">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 ">
            {suggestions.map((suggestion, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`  flex items-start justify-center bg-text-gray p-[16px] text-black rounded-lg shadow-md cursor-pointer dark:bg-grid-gray  h-full transition-all duration-300`}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <MdGrain
                  className={`text-close-button flex-shrink-0 2xl:mr-4 lg:mr-3.5 md:mr-3 sm:mr-2.5 mr-2 dark:text-white ${
                    showHistoryPanel
                      ? '2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[16px] sm:text-[24px] text-[20px]'
                      : '2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[24px] text-[20px]'
                  }`}
                />
                <span
                  className={`dark:text-white ${
                    showHistoryPanel
                      ? '2xl:text-[13px] xl:text-[12px] lg:text-[9px] md:text-[9px] sm:text-[16px] '
                      : '2xl:text-[15px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[16px] '
                  }`}
                >
                  {suggestion}
                </span>
              </div>
            ))}
          </div>
          <div className="flex items-center space-x-2 mt-3 cursor-pointer" onClick={getSuggestions}>
            <MdRestartAlt
              className=" 2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[24px] text-[24px] cursor-pointer dark:text-gray-icon"
            />
            <p className="font-semibold 2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[16px] text-[16px]">
              Refresh prompts
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuggestionGrid;
