import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig';
import { validateToken } from './tokenValidation';

// Function to get the token and configure Axios
const useAxiosInstance = () => {
  const { instance, accounts } = useMsal();

  console.log('MSAL Instance State:', {
    hasAccounts: !!accounts,
    numberOfAccounts: accounts?.length,
    isInitialized: !!instance
  });

  // get baseurl from env
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  console.log('API Base URL:', baseUrl);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  // Track if a token refresh is in progress
  let isRefreshingToken = false;
  // Store pending requests that are waiting for token refresh
  let pendingRequests = [];

  // Function to refresh token
  const refreshToken = async () => {
    if (isRefreshingToken) {
      // If a refresh is already in progress, return a promise that resolves when it's done
      return new Promise((resolve, reject) => {
        pendingRequests.push({ resolve, reject });
      });
    }

    isRefreshingToken = true;
    console.log('🔄 Starting token refresh process');

    try {
      // Check if we have an active account
      if (!accounts || accounts.length === 0) {
        console.log('⚠️ No active accounts found, redirecting to login');
        instance.loginRedirect(loginRequest);
        throw new Error('No active account found');
      }

      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      console.log('Initializing MSAL instance...');
      await instance.initialize();
      
      console.log('Attempting silent token acquisition...');
      const response = await instance.acquireTokenSilent(request);
      const account = accounts[0];
      
      console.log('Token refreshed successfully');

      // Store account and token
      sessionStorage.setItem('msal.account.keys', JSON.stringify(account));
      sessionStorage.setItem('accesstoken', JSON.stringify(response.accessToken));
      
      // Set this account as active
      instance.setActiveAccount(account);
      
      // Resolve all pending requests
      pendingRequests.forEach(request => request.resolve(response.accessToken));
      pendingRequests = [];
      
      return response.accessToken;
    } catch (error) {
      console.error('🚨 Token refresh failed:', error);
      
      // Reject all pending requests
      pendingRequests.forEach(request => request.reject(error));
      pendingRequests = [];
      
      if (error.name === 'BrowserAuthError' || error.name === 'InteractionRequiredAuthError') {
        console.log('🔄 Auth error detected, redirecting to login');
        instance.loginRedirect(loginRequest);
      }
      throw error;
    } finally {
      isRefreshingToken = false;
    }
  };

  axiosInstance.interceptors.request.use(
    async (config) => {
      console.log('🔍 Starting request interceptor');
      
      // First try to validate existing token
      try {
        console.log('Attempting to validate existing token...');
        const existingToken = validateToken();
        if (existingToken) {
          console.log('✅ Valid existing token found, using it');
          config.headers.Authorization = `Bearer ${existingToken}`;
          return config;
        }
        console.log('❌ No valid existing token found');
      } catch (error) {
        console.log('Token validation failed:', error.message);
      }

      // If no valid token, try to refresh
      try {
        const token = await refreshToken();
        config.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        console.error('Failed to acquire token for request:', error);
      }
      
      return config;
    },
    (error) => {
      console.error('❌ Request interceptor error:', error);
      return Promise.reject(error);
    },
  );

  // Add response interceptor to handle 401 errors (token expired)
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      
      // If we get a 401 error and we haven't already tried to refresh the token for this request
      if (error.response?.status === 401 && !originalRequest._retry) {
        console.log('🔒 Received 401 error, attempting to refresh token');
        originalRequest._retry = true;
        
        try {
          // Refresh the token
          const token = await refreshToken();
          
          // Update the authorization header
          originalRequest.headers.Authorization = `Bearer ${token}`;
          
          // Retry the original request
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh failed after 401 error:', refreshError);
          
          // If token refresh fails, redirect to login
          if (instance) {
            console.log('Redirecting to login after token refresh failure');
            instance.loginRedirect(loginRequest);
          }
          
          return Promise.reject(refreshError);
        }
      }
      
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxiosInstance;
