import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { marked } from 'marked';
import 'react-quill/dist/quill.snow.css';
import { MdEdit, MdSave, MdClose } from 'react-icons/md';
import useAxiosInstance from '../../../../utils/axiosInstance';
import { toast } from 'react-hot-toast';

const QuillEditor = ({ content, workflowId, stepType, onUpdate, refreshWorkflow }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    // Convert markdown to HTML when content changes
    if (content) {
      try {
        const htmlContent = marked(content);
        setEditedContent(htmlContent);
      } catch (error) {
        console.error('Error converting markdown to HTML:', error);
        setEditedContent(content);
      }
    }
  }, [content]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };

  const handleSave = async () => {
    try {
      const response = await axiosInstance.patch(`/api/workflows/${workflowId}/update_step/`, {
        step_type: stepType,
        content: editedContent,
      });

      if (response.status === 200) {
        toast.success('Content updated successfully');
        setIsEditing(false);
        if (onUpdate) onUpdate(editedContent);
        // Refresh workflow data after successful update
        if (refreshWorkflow) refreshWorkflow();
      }
    } catch (error) {
      console.error('Error updating content:', error);
      toast.error('Failed to update content');
    }
  };

  const handleCancel = () => {
    // Convert markdown to HTML again when canceling
    const htmlContent = marked(content || '');
    setEditedContent(htmlContent);
    setIsEditing(false);
  };

  return (
    <div className="relative">
      {/* Edit Controls */}
      <div className="absolute right-2 top-2 flex gap-2 z-10">
        {isEditing ? (
          <>
            <button
              onClick={handleSave}
              className="flex items-center gap-1 px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              <MdSave />
              Save
            </button>
            <button
              onClick={handleCancel}
              className="flex items-center gap-1 px-3 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600"
            >
              <MdClose />
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="flex items-center gap-1 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            <MdEdit />
            Edit
          </button>
        )}
      </div>

      {/* Quill Editor */}
      <ReactQuill
        theme="snow"
        value={editedContent}
        onChange={setEditedContent}
        modules={modules}
        readOnly={!isEditing}
        className={`${isEditing ? 'border-2 border-blue-200' : ''} bg-white dark:bg-gray-800`}
      />
    </div>
  );
};

export default QuillEditor;
