import React from 'react';
import { MdOutlinePriorityHigh } from 'react-icons/md';
import QuillEditor from '../../Editor/QuillEditor';

const ProblemIdentification = ({ stepData, workflowId, refreshWorkflow }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-red-100 dark:bg-red-900 rounded-full">
          <MdOutlinePriorityHigh className="text-2xl text-red-button" />
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 dark:text-white">Problem Identification</h3>
          <p className="text-gray-600 dark:text-gray-300">Key challenges and problems identified</p>
        </div>
      </div>

      <div className="space-y-6">
        <div className="prose prose-lg dark:prose-invert max-w-none">
          <QuillEditor
            content={stepData?.content?.problems || ''}
            workflowId={workflowId}
            stepType="problem_identification"
            onUpdate={(newContent) => {
              console.log('Problems updated:', newContent);
            }}
            refreshWorkflow={refreshWorkflow}
          />
        </div>
      </div>
    </div>
  );
};

export default ProblemIdentification;
