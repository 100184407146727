import React, { useEffect, useState, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { MdSend } from 'react-icons/md';
import { useChatContext } from '../context/chatContext';
import {
  SpeechConfig,
  AudioConfig,
  SpeechRecognizer,
  ResultReason,
} from 'microsoft-cognitiveservices-speech-sdk';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';

// Import SVGs as React Components
import { ReactComponent as AttachFileIcon } from '../Svg/attach_file.svg';
import { ReactComponent as EmojiIcon } from '../Svg/emoji.svg';
import { ReactComponent as FileIcon } from '../Svg/file.svg';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';
import { ReactComponent as MicIcon } from '../Svg/mic.svg';
import { ReactComponent as MicOffIcon } from '../Svg/mic_off.svg';

const ChatInput = ({
  formValue,
  setFormValue,
  sendMessage,
  inputRef,
  fileInputRef,
  handleFileUpload,
  isGeneratingAnswer,
  defaultThread,
  showEmojiPicker,
  setShowEmojiPicker,
  handleEmojiClick,
  emojiPickerRef,
  attachedFiles,
  handleRemoveFile,
  isElysiumTyping,
  setIsElysiumTyping,
  isStreaming,
}) => {
  const { darkMode, LastVisitedThreadId } = useChatContext();
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recognizer, setRecognizer] = useState(null);
  const [isToastShowing, setIsToastShowing] = useState(false);

  useEffect(() => {
    // ✅ Check if there is a pending message and threadId stored
    const savedMessage = sessionStorage.getItem('pendingMessage');
    const savedThreadId = sessionStorage.getItem('pendingThreadId');

    if (savedMessage && savedThreadId && defaultThread === savedThreadId) {
      // ✅ Set message in input field
      setFormValue(savedMessage);

      // ✅ Auto-send the message after setting the value
      setTimeout(() => {
        sendMessage({ preventDefault: () => {}, target: { value: savedMessage } });
      }, 500); // Delay to ensure state updates correctly

      // ✅ Clear session storage to prevent re-sending on refresh
      sessionStorage.removeItem('pendingMessage');
      sessionStorage.removeItem('pendingThreadId');
    }
    //function is unstable and would need to be resolved in future
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultThread]); // Run when `defaultThread` (threadId) is available

  const handleBlur = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  // Count words by splitting on whitespace and filtering out empty strings
  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const showLimitToast = (errorMessage) => {
    if (!isToastShowing) {
      setIsToastShowing(true);
      toast.error(errorMessage || 'Input text size is exceeding the limit(5000 words).');
      setTimeout(() => setIsToastShowing(false), 4000);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');

    // Get selection start and end positions
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;

    // Create the new text by replacing selected portion (if any)
    const beforeSelection = formValue.substring(0, selectionStart);
    const afterSelection = formValue.substring(selectionEnd);

    // Check for invisible characters or encoding issues
    const cleanPastedText = pastedText.replace(/\r\n/g, '\n');

    const resultingText = beforeSelection + cleanPastedText + afterSelection;

    // Count words instead of characters
    const wordCount = countWords(resultingText);

    if (wordCount <= 5000) {
      setFormValue(resultingText);

      // Set cursor position after the pasted text
      const newCursorPosition = selectionStart + cleanPastedText.length;
      setTimeout(() => {
        e.target.selectionStart = newCursorPosition;
        e.target.selectionEnd = newCursorPosition;
      }, 0);
    } else {
      showLimitToast('Pasted text size is exceeding the limit(5000 words).');
    }
  };

  const handleTextChange = (e) => {
    const inputText = e.target.value;
    const wordCount = countWords(inputText);

    if (wordCount <= 5000) {
      setFormValue(inputText);
    } else {
      showLimitToast();
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target) && !formValue.trim()) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [formValue]);

  useEffect(() => {
    if (attachedFiles.length > 0) {
      inputRef.current?.focus();
      setIsFocused(true);
    }
  }, [attachedFiles, inputRef]);

  const startRecording = async () => {
    try {
      const speechConfig = SpeechConfig.fromSubscription(
        process.env.REACT_APP_AZURE_SPEECH_KEY,
        process.env.REACT_APP_AZURE_SPEECH_REGION || 'eastus',
      );

      speechConfig.speechRecognitionLanguage = 'en-US';
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const speechRecognizer = new SpeechRecognizer(speechConfig, audioConfig);

      speechRecognizer.recognized = (s, e) => {
        if (e.result.reason === ResultReason.RecognizedSpeech) {
          setFormValue(formValue + ' ' + e.result.text);
          inputRef.current?.focus();
        }
      };

      speechRecognizer.startContinuousRecognitionAsync(() => {
        setIsRecording(true);
        setRecognizer(speechRecognizer);
      });
    } catch (error) {
      console.error('Error starting speech recognition:', error);
    }
  };

  const stopRecording = () => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(
        () => {
          setIsRecording(false);
          recognizer.close();
          setRecognizer(null);
        },
        (err) => {
          console.error('Error stopping recognition:', err);
          setIsRecording(false);
          setRecognizer(null);
        },
      );
    }
  };

  return (
    // <form
    //   className="form bg-white dark:bg-[#1B1C1E]"
    //   onSubmit={(e) => {
    //     e.preventDefault();
    //     if (formValue.length > MAX_TEXT_LENGTH) {
    //       toast.error('Input text size is exceeding the limit.');
    //       return;
    //     }
    //     if (!formValue.trim() && attachedFiles.length === 0) return;
    //     if (isGeneratingAnswer || isElysiumTyping || isRecording) return;
    //     sendMessage(formValue.replace(/\n/g, '\\n'));
    //     setFormValue('');
    //     setIsElysiumTyping(true);
    //   }}
    // >
    <form
      className="form bg-white dark:bg-[#1B1C1E]"
      onSubmit={(e) => {
        e.preventDefault();

        // ✅ Prevent sending empty messages
        if (!formValue.trim() && attachedFiles.length === 0) return;

        // ✅ Prevent sending while generating or recording
        if (isGeneratingAnswer || isElysiumTyping || isRecording) return;

        // ✅ Send message and reset input
        sendMessage({ preventDefault: () => {}, target: { value: formValue } });
        setFormValue('');
        setIsElysiumTyping(true);
      }}
    >
      <div className="relative w-full" ref={containerRef}>
        <div className="flex justify-center items-center mb-4 px-2 sm:px-4 md:px-12 lg:px-0">
          <div
            className={clsx(
              'relative flex flex-col border border-[#14141499] dark:border-[#FFFFFF80] rounded-lg py-2 w-full lg:w-[61%]',
              isFocused && 'border-b-red-500 border-b-2 dark:border-b-red-500',
            )}
          >
            <div
              style={{
                backgroundColor: darkMode ? '#1B1C1E' : 'white',
              }}
              className={clsx('grid', attachedFiles.length > 0 && 'grid-rows-[auto_1fr]', 'mr-3')}
            >
              {attachedFiles.length > 0 && (
                <div className="px-2 pt-1 flex overflow-x-auto gap-2">
                  <div className="flex flex-row overflow-x-scroll gap-2 mb-2  max-h-[80px] w-full items-center justify-start ">
                    {attachedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="group flex items-center gap-2 max-h-[80px] bg-gray-50 dark:bg-gray-800 rounded-lg px-3 py-2 
                    border border-gray-200 dark:border-gray-700 hover:border-red-200 dark:hover:border-red-800 
                    transition-all duration-200 shadow-sm hover:shadow-md"
                      >
                        <div className="flex items-center gap-2 max-w-[200px] overflow-hidden">
                          <FileIcon
                            width="16"
                            height="16"
                            className="flex-shrink-0 fill-gray-400 dark:fill-gray-500 group-hover:fill-red-400 
                        dark:group-hover:fill-red-500 transition-colors duration-200"
                          />
                          <div className="flex flex-col min-w-0">
                            <span
                              className="text-sm text-gray-700 dark:text-gray-300 font-medium whitespace-nowrap overflow-hidden text-ellipsis"
                              title={file.name}
                            >
                              {file.name}
                            </span>
                            <span className="text-xs text-gray-400 dark:text-gray-500 uppercase">
                              {file.type || file.name.split('.').pop() || 'Unknown'}
                            </span>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(index)}
                          className="ml-2 p-1.5 rounded-full hover:bg-red-100 dark:hover:bg-red-900/30 
                      group-hover:bg-red-50 dark:group-hover:bg-red-900/20 transition-colors duration-200"
                          aria-label="Remove file"
                          disabled={isRecording}
                        >
                          <CloseIcon
                            width="12"
                            height="12"
                            className="fill-gray-400 dark:fill-gray-500 group-hover:fill-red-500 
                        dark:group-hover:fill-red-400 transition-colors duration-200"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="relative flex">
                <textarea
                  ref={inputRef}
                  style={{
                    height: isFocused ? '77px' : '40px',
                    outline: 'none',
                    transition: isFocused ? 'height 0.3s ease-in-out' : undefined,
                  }}
                  className={clsx(
                    'chatview__textarea-message resize-none overflow-auto w-full max-w-[95%] max-h-[50px] overflow-y-scroll',
                    isFocused
                      ? 'px-3 mb-10'
                      : clsx(
                          '2xl:pl-[90px] xl:pl-[80px] lg:pl-[100px] md:pl-[90px] sm:pl-[80px] pl-[70px]',
                          '2xl:pt-[6px] xl:pt-[6px] lg:pt-[6px] md:pt-[6px] sm:pt-[6px] pt-[6px]',
                          'sm:text-[16px]',
                          'inline-flex justify-center items-center',
                        ),
                    '2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] text-[16px]',
                    '2xl:h-auto lg:h-auto md:h-auto transition-height duration-300 ease-in-out',
                    isGeneratingAnswer && 'cursor-not-allowed',
                    isFocused && 'placeholder:top-2 placeholder:left-2 placeholder:opacity-50',
                  )}
                  placeholder="Ask whatever you want..."
                  value={formValue}
                  onKeyDown={(e) =>
                    e.key === 'Enter' &&
                    !isElysiumTyping &&
                    !e.shiftKey &&
                    !isRecording &&
                    sendMessage(e)
                  }
                  onChange={(e) => handleTextChange(e)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={handleBlur}
                  disabled={isGeneratingAnswer || isRecording}
                  onPaste={handlePaste}
                />

                {/* Add Mic Icon before the Send button */}
                {isRecording ? (
                  <MicOffIcon
                    width="22"
                    height="22"
                    onClick={stopRecording}
                    className={clsx(
                      'absolute top-1/2 transform -translate-y-1/2',
                      isStreaming || isGeneratingAnswer || isElysiumTyping
                        ? 'fill-gray-400 dark:fill-gray-600 cursor-not-allowed'
                        : 'fill-red-500 dark:fill-red-500 cursor-pointer',
                      isFocused ? 'mt-6' : 'mt-0',
                      '2xl:right-16 xl:right-16 lg:right-16 md:right-16 sm:right-16 right-14',
                      '2xl:size-[24px] xl:size-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]',
                    )}
                    role="img"
                    aria-label="Stop Recording"
                    style={{
                      pointerEvents:
                        isStreaming || isGeneratingAnswer || isElysiumTyping ? 'none' : 'auto',
                    }}
                  />
                ) : (
                  <MicIcon
                    width="22"
                    height="22"
                    onClick={startRecording}
                    className={clsx(
                      'absolute top-1/2 transform -translate-y-1/2',
                      isStreaming || isGeneratingAnswer || isElysiumTyping
                        ? 'fill-gray-400 dark:fill-gray-600 cursor-not-allowed'
                        : 'fill-icon-light-fill dark:fill-white cursor-pointer',
                      isFocused ? 'mt-6' : 'mt-0',
                      '2xl:right-16 xl:right-16 lg:right-16 md:right-16 sm:right-16 right-14',
                      '2xl:size-[24px] xl:size-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]',
                    )}
                    role="img"
                    aria-label="Start Recording"
                    style={{
                      pointerEvents:
                        isStreaming || isGeneratingAnswer || isElysiumTyping ? 'none' : 'auto',
                    }}
                  />
                )}

                <button
                  type="submit"
                  className={clsx(
                    'chatview__btn-send',
                    isFocused ? 'mt-4' : 'mt-0',
                    '2xl:w-9 2xl:h-9 xl:w-9 xl:h-9 lg:w-9 lg:h-9 md:w-9 md:h-9 sm:w-6 sm:w-[40px] sm:h-[40px] w-[40px] h-[40px]',
                    formValue &&
                      !isGeneratingAnswer &&
                      !isElysiumTyping &&
                      !isStreaming &&
                      !isRecording
                      ? 'bg-red-button dark:bg-red-button'
                      : 'bg-gray-600 dark:bg-gray-600 cursor-not-allowed',
                  )}
                  disabled={
                    !formValue ||
                    isGeneratingAnswer ||
                    isElysiumTyping ||
                    isStreaming ||
                    isRecording
                  }
                >
                  <MdSend className="text-white" />
                </button>

                {/* Attach File Icon */}
                <AttachFileIcon
                  width="22"
                  height="22"
                  onClick={() => !isRecording && fileInputRef.current.click()}
                  className={clsx(
                    'fill-icon-light-fill dark:fill-white absolute',
                    isFocused ? 'mt-6' : 'mt-0',
                    '2xl:left-4 xl:left-4 lg:left-4 md:left-4 sm:left-4 left-2',
                    'top-1/2 transform -translate-y-1/2',
                    isRecording ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
                    'text-close-button',
                    '2xl:size-[24px] xl:size-[22px] lg:text-[20px] md:text-[18px] sm:text-[24px] text-[12px]',
                    'dark:text-gray-icon',
                  )}
                  role="img"
                  aria-label="Attach File Icon"
                />

                <input
                  type="file"
                  id="fileUpload"
                  accept="*/*"
                  ref={fileInputRef}
                  multiple
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  disabled={isRecording}
                />

                {/* Emoji Icon */}
                <EmojiIcon
                  width="22"
                  height="22"
                  onClick={() => !isRecording && setShowEmojiPicker((prev) => !prev)}
                  className={clsx(
                    'fill-icon-light-fill dark:fill-white absolute',
                    isFocused ? 'mt-6' : 'mt-0',
                    '2xl:left-14 xl:left-13 lg:left-12 md:left-10 sm:left-10 left-10',
                    'top-1/2 transform -translate-y-1/2',
                    isRecording ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
                    'text-close-button',
                    '2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]',
                    'dark:text-gray-icon',
                  )}
                  role="img"
                  aria-label="Emoji Icon"
                />
              </div>
            </div>
          </div>
        </div>
        {showEmojiPicker && !isRecording && (
          <div ref={emojiPickerRef} className="absolute bottom-[80px] left-[80px] z-10">
            <EmojiPicker 
              onEmojiClick={handleEmojiClick}
              theme={darkMode ? 'dark' : 'light'}
              width={300}
              height={400}
              searchPlaceholder="Search emoji..."
              skinTonesDisabled
              lazyLoadEmojis
              previewConfig={{
                showPreview: false
              }}
              categories={[
                {
                  name: "Smileys & Emotion",
                  category: "smileys_people"
                },
                {
                  name: "Animals & Nature",
                  category: "animals_nature"
                },
                {
                  name: "Food & Drink",
                  category: "food_drink"
                },
                {
                  name: "Activities",
                  category: "activities"
                },
                {
                  name: "Travel & Places",
                  category: "travel_places"
                },
                {
                  name: "Objects",
                  category: "objects"
                },
                {
                  name: "Symbols",
                  category: "symbols"
                },
                {
                  name: "Flags",
                  category: "flags"
                }
              ]}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default ChatInput;
