import React, { useState } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import { ReactComponent as PanelIcon } from '../../Svg/panel_open.svg';
import Disclaimer from './Disclaimer';
import ReleaseNotesTOC from './ReleaseNotesTOC';
import bgIcon from '../../assets/vector.png';
const Layout = ({ children }) => {
  const [isTOCOpen, setIsTOCOpen] = useState(false);
  return (
    <div className="w-full h-screen flex flex-col gap-2 overflow-hidden ">
      <Header />
      {/* The relative handles TOC panel */}
      <div className="flex h-full flex-1 relative">
        <SideBar
          showHistoryPanel={false}
          setShowHistoryPanel={() => true}
          setCanvasWidth={() => true}
          setShowFeatureUnavailable={() => true}
        />
        {/* The relative handles bg-image .i.e.elysium logo */}
        <div
          className={`relative w-full h-full flex flex-col ${
            isTOCOpen ? 'lg:mr-[350px]' : 'mr-0'
          } transition-all duration-300`}
        >
          {children}
          <Disclaimer />
          <img src={bgIcon} alt="dev8" className="absolute z-10 top-0 left-0" />
        </div>

        {!isTOCOpen && (
          <div className="w-max px-4 py-2">
            <PanelIcon
              width="22"
              height="22"
              onClick={() => setIsTOCOpen(true)}
              className="fill-icon-light-fill dark:fill-white cursor-pointer"
              role="img"
              aria-label="Panel Icon"
            />
          </div>
        )}

        <ReleaseNotesTOC isTOCOpen={isTOCOpen} onClose={() => setIsTOCOpen(false)} />
      </div>
    </div>
  );
};

export default Layout;
