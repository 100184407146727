import React, { useState, useRef } from 'react';
import { MdRestartAlt } from 'react-icons/md';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useChatContext } from '../context/chatContext';
import useAxiosInstance from '../utils/axiosInstance.js';
import { ReactComponent as DeleteIcon } from '../Svg/delete.svg';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';
import { ReactComponent as SearchIcon } from '../Svg/search.svg';

const HistoryGrid = ({ showHistoryPanel = false, handleChatClick }) => {
  const {
    user,
    historyItems,
    handleDeleteClick,
    setDefaultThread,
    setShowHistoryGrid,
    setShowWelcomeModal,
    isLoadingThreads,
    fetchThreads,
  } = useChatContext();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef(null);

  const handleSearchIconClick = () => {
    setShowSearchBar((prev) => !prev);
    if (!showSearchBar) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const handleCloseSearchBar = () => {
    setShowSearchBar(false);
    setSearchQuery('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    setSearchQuery('');
  };

  const handleOpenThread = async (threadId) => {
    setDefaultThread(threadId);
    const data = {
      thread: threadId,
      user: user.id,
    };

    try {
      await axiosInstance.post('/api/last-accessed-threads/', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      navigate(`/threads/${threadId}`);
    } catch (error) {
      console.error('Error updating last accessed thread:', error);
    }

    setShowHistoryGrid(false);
    setShowWelcomeModal(false);
  };

  const filteredHistoryItems = historyItems.filter((item) =>
    item.thread_name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  // Function to highlight matching text
  const highlightText = (text) => {
    if (!searchQuery) return text; // Return original text if no search query
    const parts = text.split(new RegExp(`(${searchQuery})`, 'gi')); // Split text by search query
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} className="bg-yellow-300 dark:bg-orange-500 text-black">
          {part}
        </span> // Highlight matching part
      ) : (
        part
      ),
    );
  };

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="flex items-center justify-between mb-4">
          {/* Clickable Container for Restart Icon + History Header */}
          <div
            className="flex items-center space-x-2 cursor-pointer hover:opacity-80 transition-opacity"
            onClick={fetchThreads}
          >
            <MdRestartAlt className="2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] dark:text-gray-icon" />
            <h2 className="2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[8px] text-close-button font-semibold dark:text-gray-icon">
              History
            </h2>
          </div>

          <SearchIcon
            className="fill-icon-light-fill dark:fill-white cursor-pointer 2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px]"
            onClick={handleSearchIconClick}
          />
        </div>

        {showSearchBar && (
          <div className="relative mb-4">
            <input
              type="text"
              ref={searchInputRef}
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              className="w-full px-4 py-1.5 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[8px] text-black rounded-lg bg-transparent border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white dark:placeholder:text-white"
              onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
            />
            <CloseIcon
              className="absolute right-2 top-2.5 text-gray-500 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[8px] cursor-pointer hover:text-gray-700 dark:text-white"
              onClick={handleCloseSearchBar}
            />
          </div>
        )}

        <div
          className={clsx(
            'grid overflow-y-none 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-4 transition-all duration-300 h-max 2xl:w-[860px] xl:w-[760px] lg:w-[580px] md:w-[500px] sm:w-[420px] w-[240px]',
          )}
        >
          {isLoadingThreads && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10 dark:bg-black dark:bg-opacity-80">
              <div className="loading-spinner"></div>
            </div>
          )}

          {filteredHistoryItems.map((item) => (
            <div
              key={item.thread_id}
              className="bg-text-gray 2xl:p-4 xl:p-3 lg:p-3 md:p-3 sm:p-2 p-2 h-auto rounded-lg shadow-sm dark:bg-grid-gray cursor-pointer"
              onClick={() => handleChatClick(item.thread_id)}
            >
              <div className="flex justify-between items-center mb-2">
                <p className="text-gray-500 2xl:text-[12px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[12px] sm:text-400 text-[5px] dark:text-gray-icon">
                  {new Date(item.created_at).toLocaleString()}
                </p>
                <DeleteIcon
                  className="fill-icon-light-fill dark:fill-white cursor-pointer"
                  role="img"
                  aria-label="Delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(item);
                  }}
                />
              </div>
              <h3
                className="text-black 2xl:text-[18px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[16px] sm:text-700 text-[8px] font-semibold mb-1 dark:text-white"
                title={item.thread_name}
              >
                {highlightText(item.thread_name)}
              </h3>
              <p
                className="text-black 2xl:text-[16px] xl:text-[13px] lg:text-[11px] md:text-[10px] sm:text-[16px] sm:text-400 text-[8px] dark:text-white truncate"
                title={
                  item.chats.length > 0
                    ? item.chats[item.chats.length - 1]?.text
                    : 'No messages yet.'
                }
              >
                {item.chats.length > 0
                  ? item.chats[item.chats.length - 1]?.text
                  : 'No messages yet.'}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HistoryGrid;
