import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useChatContext } from '../../context/chatContext';
import { useParams, useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { toast, Toaster } from 'react-hot-toast';
import { usePDF } from 'react-to-pdf';
import SideBar from '../SideBar';
import useAxiosInstance from '../../utils/axiosInstance.js';
import Header from '../Header';
import ThreadPanel from '../ThreadPanel';
import { MdCheck, MdArrowForward, MdClose } from 'react-icons/md';
import { Dialog } from '@headlessui/react';
import { useWorkflow } from './hooks/useWorkflow';
import { WORKFLOW_STEPS } from './constants/steps';
import CompanyDetails from './components/Steps/StepContent/CompanyDetails';
import Analysis from './components/Steps/StepContent/Analysis';
import Recommendations from './components/Steps/StepContent/Recommendations';
import ValueProposition from './components/Steps/StepContent/ValueProposition';
import Proposal from './components/Steps/StepContent/Proposal';
import Email from './components/Steps/StepContent/Email';
import Presentation from './components/Steps/StepContent/Presentation';

import { ReactComponent as PanelIcon } from '../../Svg/panel_open.svg';
import ProblemIdentification from './components/Steps/StepContent/ProblemIdentification.js';

const WorkFlowComponent = () => {
  const messagesEndRef = useRef();
  const chatContainerRef = useRef();
  const fileInputRef = useRef(null);
  const axiosInstance = useAxiosInstance();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const {
    messages,
    setMessages,
    defaultThread,
    setDefaultThread,
    isGeneratingAnswer,
    setIsGeneratingAnswer,
    showWelcomeModal,
    setShowWelcomeModal,
    showSuggestionGrid,
    setShowSuggestionGrid,
    showHistoryGrid,
    setShowHistoryGrid,
    showSuggestionGridAfterSpinner,
    setShowSuggestionGridAfterSpinner,
    setDarkMode,
    handleDeleteClick,
    user,
    showPanelIcon,
    isLoadingThreads,
    lastVisitedThreadId,
    setLastVisitedThreadId,
    setChats,
    setHistoryItems,
    handleNewThread,
    chats,
    isLoadingChats,
    setIsLoadingChats,
    searchQuery,
    setSearchResultExists,
    isSuggestionsLoading,
    fetchThreads,
  } = useChatContext();
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [isUserNearBottom, setIsUserNearBottom] = useState(true);
  const { threadId } = useParams();
  const { toPDF, targetRef } = usePDF({ filename: `${threadId || 'chat_history'}.pdf` });
  const navigate = useNavigate();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [showHistoryPanel, setShowHistoryPanel] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [isDocumentUploadEnabled, setIsDocumentUploadEnabled] = useState(true);
  const isThreadSelected = threadId && messages.length > 0;
  const [showFilesSection, setShowFilesSection] = useState(false);
  const [showFeatureUnavailable, setShowFeatureUnavailable] = useState(false);
  const [showChatInput, setShowChatInput] = useState(true);

  const {
    currentStep,
    setCurrentStep,
    isModalOpen,
    setIsModalOpen,
    formData,
    handleInputChange,
    handleSubmit,
    errors,
    stepData,
    setStepData,
    proceedToNextStep,
    validateForm,
    setErrors,
  } = useWorkflow();

  const [workflowData, setWorkflowData] = useState(null);
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);

  const steps = WORKFLOW_STEPS;

  const { workflowId } = useParams();

  // Add new state for selected step
  const [selectedStep, setSelectedStep] = useState(null);

  const handleImageIconClick = () => {
    setShowFilesSection(true);
    setShowChatInput(false);
    setShowFeatureUnavailable(false);
  };

  const handleChatIconClick = () => {
    // Debug log
    setShowFilesSection(false);
    setShowChatInput(true);
    setShowFeatureUnavailable(false);
    setShowSuggestionGrid(true); // Ensure this sets to true
  };

  const fetchChats = async (threadId) => {
    setIsLoadingChats(true); // Show spinner while loading
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/threads/${threadId}`,
      );

      if (response.data && Array.isArray(response.data.chats)) {
        setMessages(response.data.chats);
      }
    } catch (error) {
      console.error('Error fetching chats:', error.response?.data || error.message);
    } finally {
      setIsLoadingChats(false); // Hide spinner when done
    }
  };

  const fetchThreadsInternal = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/threads/');
      const sortedThreads = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setChats(sortedThreads);
      setHistoryItems(sortedThreads);
      if (sortedThreads.length > 0 && !defaultThread) {
        setDefaultThread(sortedThreads[0].thread_id);
      }
    } catch (error) {
      console.error('Error fetching threads:', error.response?.data || error.message);
    }
  }, [axiosInstance, defaultThread]);

  const handleNewChat = async () => {
    try {
      const modelType = 'azure';
      const threadName = `New Chat`;

      const response = await axiosInstance.post('/api/threads/', {
        thread_name: threadName,
        model_name: modelType,
      });

      const newChat = response.data;
      setLastVisitedThreadId(newChat.thread_id);
      return newChat.thread_id;
    } catch (error) {
      console.error('Error creating thread:', error.message);
    }
  };

  useEffect(() => {
    if (messages.length === 0) {
      if (showWelcomeModal === false && showHistoryGrid == false) {
        setShowSuggestionGridAfterSpinner(true);
        setShowSuggestionGrid(true);
      }
    } else {
      setShowSuggestionGridAfterSpinner(false);
      setShowSuggestionGrid(false);
    }
  });

  useEffect(() => {
    // Only show SuggestionGrid if explicitly triggered (e.g., chat icon click)
    if (messages.length === 0 && !isGeneratingAnswer) {
      setShowSuggestionGridAfterSpinner(false); // Reset to prevent accidental flicker
      setShowSuggestionGrid(false);
    }
  }, [messages, isGeneratingAnswer]);

  // Navigate to specific thread and dismiss HistoryGrid
  const handleChatClick = useCallback(
    async (threadId) => {
      setDefaultThread(threadId);
      setShowHistoryGrid(false); // Dismiss the HistoryGrid
      setShowSuggestionGrid(false); // Dismiss the SuggestionGrid
      const data = {
        thread: threadId,
        user: user.id,
      };

      try {
        const response = await axiosInstance.post('/api/last-accessed-threads/', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Optionally navigate to the thread after successful API call
        navigate(`/threads/${threadId}`);
      } catch (error) {
        console.error('Error updating last accessed thread:', error);
      }
      navigate(`/threads/${threadId}`);
    },
    [navigate, setDefaultThread, setShowHistoryGrid, setShowSuggestionGrid],
  );

  // Toggle canvas width for history panel
  const toggleCanvas = (width) => {
    setCanvasWidth(width);
    setShowHistoryPanel((prev) => !prev);
  };

  const checkIfUserIsNearBottom = () => {
    const container = chatContainerRef.current;
    const isNearBottom =
      container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
    setIsUserNearBottom(isNearBottom);
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkIfUserIsNearBottom);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkIfUserIsNearBottom);
      }
    };
  }, []);

  useEffect(() => {
    if (isGeneratingAnswer) {
      ensureScrollToBottom();
    }
  }, [isGeneratingAnswer]);

  useEffect(() => {
    if (messages.length > 0) {
      const foundResult = messages.some((message) =>
        message.text.toLowerCase().includes(searchQuery.trim().toLowerCase()),
      );
      if (foundResult) {
        setSearchResultExists(true);
      } else {
        setSearchResultExists(false);
      }
    }
  }, [messages, searchQuery]);

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the emoji picker
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //this function will keeps scrolling until the bottom is reached
  //by default the scroll behaviour is smooth by force = true it will scroll to the bottom immediately
  const ensureScrollToBottom = (force = false) => {
    const endRef = messagesEndRef.current;
    const container = endRef?.parentElement; // Get the scrolling container

    if (!container) return;

    const scrollToEnd = () => {
      requestAnimationFrame(() => {
        endRef.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          const atBottom =
            container.scrollHeight - container.scrollTop <= container.clientHeight + 10;

          if (!atBottom) {
            scrollToEnd();
          }
        }, 100);
      });
    };

    if (force) {
      container.scrollTop = container.scrollHeight;
    }

    scrollToEnd(); // Start scrolling process
  };

  useEffect(() => {
    ensureScrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Reset state when no threadId is available
    if (!threadId) {
      setMessages([]);
      setShowHistoryGrid(true);
      setShowWelcomeModal(true);
      setShowSuggestionGrid(false);
      // fetchLastVisitedThreadId();
      return;
    }

    // Clear messages and fetch chats for the selected thread
    setMessages([]);
    fetchChats(threadId);
    fetchThreadsInternal();

    // Ensure grids and modals are appropriately dismissed
    setShowHistoryGrid(false);
    setShowWelcomeModal(false);
    setShowSuggestionGrid(true); // Ensure SuggestionGrid is dismissed by default

    // Show WelcomeModal or SuggestionGrid based on thread state
    const welcomeShownKey = `welcomeShown_${threadId}`;
    if (!localStorage.getItem(welcomeShownKey)) {
      setShowSuggestionGrid(true); // Show SuggestionGrid only for new threads
      localStorage.setItem(welcomeShownKey, 'true');
    }

    // Focus the input field
    inputRef.current?.focus();
  }, [threadId]);

  useEffect(() => {
    if (!isGeneratingAnswer) {
      inputRef.current?.focus();
    }
  }, [isGeneratingAnswer]);

  // On component mount, apply the stored mode
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedMode);
    document.documentElement.classList.toggle('dark', savedMode);
  }, []);

  // Fetch workflow data function
  const fetchWorkflowData = async () => {
    if (!workflowId) return;

    try {
      setIsLoadingWorkflow(true);
      const response = await axiosInstance.get(`/api/workflows/${workflowId}/`);
      setWorkflowData(response.data);

      // First determine the current step from the workflow data
      let currentStepNumber;
      if (response.data.status === 'initial') {
        currentStepNumber = 0;
      } else if (response.data.status === 'completed') {
        currentStepNumber = 6;
      } else {
        // Map the status to step number
        const stepMap = {
          company_analysis: 1,
          problem_identification: 2,
          value_proposition: 3,
          proposal: 4,
          email: 5,
          presentation: 6,
        };
        currentStepNumber = stepMap[response.data.status] || 1;
      }
      setCurrentStep(currentStepNumber);

      // Find the current step data based on the latest status
      const currentStepData = response.data.steps.find(
        (step) => step.step_type === response.data.status,
      );
      setStepData(currentStepData);

      // Set selected step to show the last completed step
      if (response.data.steps.length > 0) {
        const lastCompletedStep = response.data.steps[response.data.steps.length - 1];
        const stepMap = {
          company_analysis: 1,
          problem_identification: 2,
          value_proposition: 3,
          proposal: 4,
          email: 5,
          presentation: 6,
        };
        const lastCompletedStepNumber = stepMap[lastCompletedStep.step_type];
        setSelectedStep(lastCompletedStepNumber);
        setStepData(lastCompletedStep);
      }
    } catch (error) {
      console.error('Error fetching workflow:', error);
      toast.error('Error fetching workflow data');
    } finally {
      setIsLoadingWorkflow(false);
    }
  };

  // Add useEffect to fetch initial data
  useEffect(() => {
    if (workflowId) {
      fetchWorkflowData();
    }
  }, [workflowId]);

  // Modify handleNextStep to include a delay
  const handleNextStep = async () => {
    if (!workflowId) {
      toast.error('No active workflow found');
      return;
    }

    try {
      setIsLoadingWorkflow(true);
      await proceedToNextStep(workflowId);

      // Add a delay of 500ms (0.5 seconds)
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Fetch updated workflow data after the delay
      await fetchWorkflowData();
    } catch (error) {
      console.error('Error in handleNextStep:', error);
    } finally {
      setIsLoadingWorkflow(false);
    }
  };

  // Modify the step click handler
  const handleStepClick = (stepNumber) => {
    // Find the step data for this step
    const stepType = WORKFLOW_STEPS[stepNumber - 1].endpoint;
    const stepData = workflowData?.steps.find((step) => step.step_type === stepType);

    if (stepData) {
      setSelectedStep(stepNumber);
      setStepData(stepData);
    } else {
      toast.error('Step data not available yet');
    }
  };

  // Modify renderStepContent to use selectedStep instead of currentStep
  const renderStepContent = () => {
    if (isLoadingWorkflow) {
      return (
        <div className="flex items-center justify-center">
          <div className="loading-spinner"></div>
        </div>
      );
    }

    const stepToShow = selectedStep || currentStep;

    switch (stepToShow) {
      case 1:
        return (
          <Analysis
            analysisData={stepData}
            workflowData={workflowData}
            workflowId={workflowId}
            refreshWorkflow={fetchWorkflowData}
          />
        );
      case 2:
        return (
          <ProblemIdentification
            stepData={stepData}
            workflowData={workflowData}
            workflowId={workflowId}
            refreshWorkflow={fetchWorkflowData}
          />
        );
      case 3:
        return (
          <ValueProposition
            stepData={stepData}
            workflowData={workflowData}
            workflowId={workflowId}
            refreshWorkflow={fetchWorkflowData}
          />
        );
      case 4:
        return (
          <Proposal
            stepData={stepData}
            workflowData={workflowData}
            workflowId={workflowId}
            refreshWorkflow={fetchWorkflowData}
          />
        );
      case 5:
        return (
          <Email
            stepData={stepData}
            workflowData={workflowData}
            workflowId={workflowId}
            refreshWorkflow={fetchWorkflowData}
          />
        );
      case 6:
        return (
          <Presentation
            stepData={stepData}
            workflowData={workflowData}
            workflowId={workflowId}
            refreshWorkflow={fetchWorkflowData}
          />
        );
      default:
        return <div>Process is not started yet</div>;
    }
  };

  return (
    <div className="h-screen">
      <Header />
      <Toaster position="top-right" reverseOrder={false} />

      <div className="flex bg-background-gray dark:bg-black">
        <SideBar
          showHistoryPanel={showHistoryPanel}
          setShowHistoryPanel={setShowHistoryPanel}
          setCanvasWidth={setCanvasWidth}
          setShowFeatureUnavailable={setShowFeatureUnavailable}
        />
        <div className="absolute 2xl:top-[300px] 2xl:left-[300px] xl:top-[200px] xl:left-[200px] lg:top-[150px] lg:left-[150px] md:top-[100px] md:left-[120px] sm:top-[100px] sm:left-[100px] top-[90px] left-[90px] transform -translate-x-1/2 -translate-y-1/2 2xl:w-[622px] 2xl:h-[600px] xl:w-[422px] xl:h-[400px] lg:w-[322px] lg:h-[300px] md:w-[250px] md:h-[220px] sm:w-[220px] sm:h-[200px] w-[200px] h-[180px] bg-[url('/src/assets/vector.png')] bg-contain bg-no-repeat opacity-100 z-0"></div>

        {/* Main Content */}
        <div
          className={`flex-1 p-8  rounded-3xl bg-white dark:bg-gray-900 ${
            showHistoryPanel ? 'mr-80' : 'mr-12'
          } h-[88vh] overflow-y-auto`}
        >
          {/* Steps Progress */}
          <div className="flex justify-between items-center gap-4 max-w-4xl mx-auto mb-8 z-50 overflow-x-scroll">
            {steps.map((step, index) => {
              const isActive = currentStep === step.id;
              const isCompleted = currentStep > step.id;
              const isSelected = selectedStep === step.id;
              const hasData = workflowData?.steps.some((s) => s.step_type === step.endpoint);

              return (
                <div key={step.id} className={`relative flex-1 ${!hasData ? 'opacity-50' : ''}`}>
                  {/* Connection lines */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute h-[2px] w-full 
                        left-1/2 top-1/2 transform -translate-y-1/2 z-0 
                        ${isCompleted ? 'bg-red-button' : 'bg-gray-300 dark:bg-gray-700'}`}
                    />
                  )}

                  {/* Step Card */}
                  <div
                    onClick={() => !isLoadingWorkflow && hasData && handleStepClick(step.id)}
                    className={`
                      relative z-10 flex items-center justify-center
                      h-14 px-4 rounded-lg transition-all duration-300
                      ${hasData ? 'cursor-pointer hover:shadow-lg' : 'cursor-not-allowed'}
                      ${
                        isSelected
                          ? 'bg-blue-500 text-white'
                          : isActive
                          ? 'bg-red-button text-white'
                          : isCompleted
                          ? 'bg-green-500 text-white'
                          : 'bg-gray-100 dark:bg-gray-800'
                      }
                    `}
                  >
                    {/* Step Number/Check Icon */}
                    <div className="flex items-center gap-2">
                      <span className="text-lg font-medium">
                        {isCompleted ? <MdCheck className="w-5 h-5" /> : step.id}
                      </span>
                      <h3 className="text-sm font-medium whitespace-nowrap">{step.title}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between max-w-4xl mx-auto mt-8 px-4">
            <button
              className="px-6 py-3 rounded-lg flex items-center gap-2 bg-gray-100 dark:bg-gray-800 cursor-not-allowed opacity-50 text-sm sm:text-base"
              disabled={true}
            >
              Previous
            </button>
            <button
              onClick={handleNextStep}
              className={`px-6 py-3 rounded-lg flex items-center gap-2 text-sm sm:text-base
                ${
                  currentStep < 6 && workflowId
                    ? 'bg-red-button text-white hover:bg-red-600'
                    : 'bg-gray-100 dark:bg-gray-800 cursor-not-allowed opacity-50'
                } `}
              disabled={isLoadingWorkflow}
            >
              Next
              <MdArrowForward />
            </button>
          </div>

          {/* Current Step Content */}
          <div className="max-w-4xl mx-auto mt-8 rounded-lg p-4 z-50">{renderStepContent()}</div>
        </div>

        <div className="absolute 2xl:top-[88px] xl:top-[80px] lg:top-[75px] md:top-[70px] sm:top-[65px] top-[75px] 2xl:right-5 xl:right-4 lg:right-3 md:right-2 sm:right-1.5 right-10 text-close-button cursor-pointer 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px] dark:text-gray-icon">
          {/* <PanelIcon
            width="22"
            height="22"
            className="fill-icon-light-fill dark:fill-white"
            onClick={() => toggleCanvas(350)}
            role="img"
            aria-label="Panel Icon"
          /> */}
        </div>

        {/* Thread Panel */}
        {/* <div className="overflow-x-hidden">
          <ThreadPanel
            showHistoryPanel={showHistoryPanel}
            toggleCanvas={toggleCanvas}
            isLoadingThreads={isLoadingThreads}
            chats={chats}
            handleDeleteClick={handleDeleteClick}
            handleChatClick={handleChatClick}
          />
        </div> */}
      </div>

      {/* Disclaimer */}
      <div
        className={`flex bg-background-gray dark:bg-black justify-center 2xl:ml-[40px] xl:ml-[60px] lg:ml-[70px] md:ml-[60px] sm:ml-[60px] ml-[50px] ${
          showHistoryPanel
            ? '2xl:mr-[350px] xl:mr-[300px] lg:mr-[200px] md:mr-[200px] sm:mr-[170px] mr-[180px]'
            : ''
        } transition-[width,margin] duration-1000`}
      >
        <h1
          className={`2xl:w-[848px] xl:w-[780px] lg:w-[760px] md:w-[560px] sm:w-[460px] w-[260px] ${
            showHistoryPanel ? 'text-left' : 'text-left'
          } transition-colors duration-150 dark:text-gray-icon 2xl:text-[12px] xl:text-[10px] lg:text-[8px] md:text-[6px] sm:text-[5px] text-[4px] py-2`}
        >
          Disclaimer: Elysium Workspace chats are private and not used to improve AI models. Elysium
          AI may occasionally provide inaccurate or incomplete
          <br />
          information; please verify critical details independently.
        </h1>
      </div>
    </div>
  );
};

export default WorkFlowComponent;
