import React from 'react';
import CompanyDetailsForm from '../../Forms/CompanyDetailsForm';
import { MdBusinessCenter } from 'react-icons/md';

const CompanyDetails = ({
  isModalOpen,
  setIsModalOpen,
  formData,
  handleInputChange,
  handleSubmit,
  errors,
  setCurrentStep,
}) => {
  return (
    <div className="">
      <CompanyDetailsForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formData={formData}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CompanyDetails;
