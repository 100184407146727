import { useState } from 'react';
import { toast } from 'react-hot-toast';
import useAxiosInstance from '../../../utils/axiosInstance';

export const useWorkflow = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowId, setWorkflowId] = useState(null);
  const [formData, setFormData] = useState({
    target_company_name: '',
    target_location: '',
    target_portfolio_link: '',
    target_description: '',
    target_industry: '',
    target_company_size: '',
  });
  const [errors, setErrors] = useState({});
  const [stepData, setStepData] = useState(null);

  const axiosInstance = useAxiosInstance();

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.target_portfolio_link && !isValidUrl(formData.target_portfolio_link)) {
      newErrors.target_portfolio_link = 'Please enter a valid URL (e.g., https://example.com)';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Submit called');

    if (!validateForm()) {
      console.log('Form validation failed');
      toast.error('Please fix the errors before submitting');
      return;
    }

    try {
      console.log('Submitting form data:', formData);
      const response = await axiosInstance.post('/api/workflows/', formData);
      console.log('Form submission response:', response);

      if (response.status === 200 || response.status === 201) {
        const { workflow_id } = response.data;
        console.log('Received workflow ID:', workflow_id);
        setWorkflowId(workflow_id);
        toast.success('Company details submitted successfully');
        setIsModalOpen(false);
        setCurrentStep(1);
      }
    } catch (error) {
      console.error('Form submission error:', error);
      console.error('Error response:', error.response?.data);
      if (error.response?.data) {
        const apiErrors = error.response.data;
        setErrors(apiErrors);
        const errorMessage = Object.values(apiErrors)[0];
        toast.error(errorMessage);
      } else {
        toast.error('Error submitting company details');
      }
    }
  };

  const getStepEndpoint = (step) => {
    switch (step) {
      case 1:
        return 'company_analysis';
      case 2:
        return 'problem_identification';
      case 3:
        return 'value_proposition';
      case 4:
        return 'proposal';
      case 5:
        return 'email';
      case 6:
        return 'presentation';
      default:
        return '';
    }
  };

  const proceedToNextStep = async (workflowId) => {
    if (!workflowId) {
      toast.error('No active workflow found');
      return;
    }

    try {
      const nextStep = currentStep + 1;
      const endpoint = getStepEndpoint(nextStep);

      if (!endpoint) {
        console.error('Invalid step');
        return;
      }

      console.log(`Processing next step (${nextStep}): ${endpoint}`);

      const response = await axiosInstance.post(`/api/workflows/${workflowId}/${endpoint}/`);

      if (response.status === 200 || response.status === 201) {
        setStepData(response.data);
        setCurrentStep(nextStep);
        toast.success('Proceeding to next step');
        return response.data; // Return the response data
      }
    } catch (error) {
      if (error.response?.data?.error === 'Workflow is complete') {
        toast.success('Workflow completed successfully!');
      } else {
        toast.error('Error proceeding to next step');
        console.error('Error:', error);
      }
      throw error; // Propagate the error
    }
  };

  return {
    currentStep,
    setCurrentStep,
    isModalOpen,
    setIsModalOpen,
    formData,
    handleInputChange,
    handleSubmit,
    errors,
    stepData,
    setStepData,
    proceedToNextStep,
    validateForm,
    setErrors,
  };
};
