import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

const TextToSpeech = forwardRef(({ text, isPlaying, onEnd }, ref) => {
  const [utterance, setUtterance] = useState(null);
  const [synth, setSynth] = useState(null);
  const [voices, setVoices] = useState([]);

  // Get available voices and set female voice
  useEffect(() => {
    const speechSynth = window.speechSynthesis;
    setSynth(speechSynth);

    // Function to get and set voices
    const loadVoices = () => {
      const availableVoices = speechSynth.getVoices();
      setVoices(availableVoices);
    };

    loadVoices();
    // Some browsers need a listener for voices to load
    speechSynth.onvoiceschanged = loadVoices;

    return () => {
      speechSynth.onvoiceschanged = null;
    };
  }, []);

  // Create new utterance whenever text changes or playback starts
  useEffect(() => {
    if (!synth || !voices.length) return;

    // Cancel any ongoing speech
    synth.cancel();

    // Create new utterance
    const newUtterance = new SpeechSynthesisUtterance(text);

    // Find a female English voice
    const femaleVoice =
      voices.find(
        (voice) =>
          voice.name.includes('female') ||
          voice.name.includes('Female') ||
          voice.name.includes('Samantha') ||
          voice.name.includes('Victoria'),
      ) ||
      voices.find((voice) => voice.lang.startsWith('en-')) ||
      voices[0];

    if (femaleVoice) {
      newUtterance.voice = femaleVoice;
    }

    newUtterance.pitch = 1.2;
    newUtterance.rate = 1.0;

    newUtterance.onend = () => {
      onEnd();
      setUtterance(null);
    };

    setUtterance(newUtterance);

    if (isPlaying) {
      synth.speak(newUtterance);
    }

    return () => {
      synth.cancel();
    };
  }, [text, isPlaying, onEnd, synth, voices]);

  useImperativeHandle(
    ref,
    () => ({
      startPlayback: () => {
        if (synth && utterance) {
          synth.cancel();
          const newUtterance = new SpeechSynthesisUtterance(text);

          // Apply voice settings
          const femaleVoice =
            voices.find(
              (voice) =>
                voice.name.includes('female') ||
                voice.name.includes('Female') ||
                voice.name.includes('Samantha') ||
                voice.name.includes('Victoria'),
            ) ||
            voices.find((voice) => voice.lang.startsWith('en-')) ||
            voices[0];

          if (femaleVoice) {
            newUtterance.voice = femaleVoice;
          }

          newUtterance.pitch = 1.2;
          newUtterance.rate = 1.0;
          newUtterance.onend = onEnd;

          setUtterance(newUtterance);
          synth.speak(newUtterance);
        }
      },
      stopPlayback: () => {
        if (synth) {
          synth.cancel();
        }
      },
    }),
    [synth, text, onEnd, voices],
  );

  return null;
});

TextToSpeech.displayName = 'TextToSpeech';

export default TextToSpeech;
