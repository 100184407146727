import React from 'react';
import { MdEmail } from 'react-icons/md';
import QuillEditor from '../../Editor/QuillEditor';

const Email = ({ stepData, workflowData, workflowId, refreshWorkflow }) => {
  if (!workflowId) {
    console.warn('Email component: workflowId is missing');
    return null;
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-red-100 dark:bg-red-900 rounded-full">
          <MdEmail className="text-2xl text-red-button" />
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 dark:text-white">Email Draft</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Professional email template for your communication
          </p>
        </div>
      </div>

      <div className="prose prose-lg dark:prose-invert max-w-none">
        <QuillEditor
          content={stepData?.content?.email_template || ''}
          workflowId={workflowId}
          stepType="email"
          onUpdate={(newContent) => {
            // Handle content update if needed
            console.log('Content updated:', newContent);
          }}
          refreshWorkflow={refreshWorkflow}
        />
      </div>
    </div>
  );
};

export default Email;
