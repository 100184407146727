import React, { useState } from 'react';
import { useChatContext } from '../context/chatContext';
import useAxiosInstance from '../utils/axiosInstance';
import { ReactComponent as FileIcon } from '../Svg/copy.svg';
import { ReactComponent as DeleteIcon } from '../Svg/delete.svg';
import { ReactComponent as DownloadIcon } from '../Svg/download.svg'; // Add a Download icon SVG

import { toast } from 'react-hot-toast';
import clsx from 'clsx';

const FilesSection = () => {
  const { uploadedFiles, setUploadedFiles } = useChatContext();
  const axiosInstance = useAxiosInstance();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (fileId) => {
    setSelectedFiles(
      (prevSelected) =>
        prevSelected.includes(fileId)
          ? prevSelected.filter((id) => id !== fileId) // Unselect file
          : [...prevSelected, fileId], // Select file
    );
  };

  const handleDeleteFile = async (fileId) => {
    setIsLoading(true);
    if (!fileId) {
      toast.error('Invalid file ID');
      return;
    }

    try {
      const requestBody = {
        file_ids: [fileId],
      };

      const response = await axiosInstance.post('/api/files/delete-files/', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
        setIsLoading(false);
        toast.success('File deleted successfully');
      } else {
        setIsLoading(false);
        toast.error(`Failed to delete file: ${response.data.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error deleting file:', error.response?.data || error.message);
      setIsLoading(false);
      toast.error('Failed to delete file. Please try again.');
    }
  };

  const handleDeleteSelectedFiles = async () => {
    if (selectedFiles.length === 0) {
      toast.error('No files selected!');
      return;
    }
    setIsLoading(true);
    try {
      const requestBody = { file_ids: selectedFiles }; // Send selected file IDs as array
      const response = await axiosInstance.post('/api/files/delete-files/', requestBody, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => !selectedFiles.includes(file.id)),
        );
        setIsLoading(false);
        setSelectedFiles([]); // Clear selected files after deletion
        toast.success('Selected files deleted successfully');
      } else {
        setIsLoading(false);
        toast.error(`Failed to delete files: ${response.data.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error deleting files:', error.response?.data || error.message);
      toast.error('Failed to delete files. Please try again.');
    }
  };
  const handleDownloadFile = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      if (!response.ok) throw new Error('Failed to fetch the file');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Force download with the correct filename
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up memory
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Failed to download file. Please try again.');
    }
  };

  return (
    <div className="py-8 pr-6 pl-12 2xl:w-[896px] xl:w-[800px] lg:w-[610px] md:w-[570px] sm:w-[450px] w-[270px] mx-auto relative z-10">
      <div className="flex flex-row items-center justify-start">
        <h2 className="pt-1 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[16px] sm:text-[14px] text-[12px] text-black font-semibold dark:text-white">
          Files
        </h2>
        {isLoading ? (
          <div className="flex items-center justify-center gap-2 px-3 rounded-lg transition-all duration-300">
            <div className="w-5 h-5 border-2 border-red-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        ) : (
          <>
            {selectedFiles.length > 0 && (
              <button
                onClick={handleDeleteSelectedFiles}
                className="text-white rounded-lg ms-3 transition-colors duration-200"
              >
                <DeleteIcon className="fill-icon-light-fill dark:fill-white hover:fill-red-700 dark:hover:fill-red-700" />
              </button>
            )}
          </>
        )}
      </div>
      {uploadedFiles.length > 0 ? (
        <div className="mt-4 max-h-[calc(100vh-300px)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent">
          <ul className="space-y-4">
            {uploadedFiles.map((file) => (
              <li
                key={file.id}
                className={clsx(
                  'flex items-center justify-between',
                  'p-1.5',
                  'bg-white dark:bg-[#1B1C1E]',
                  'border-b border-gray-200 dark:border-gray-700',
                  'transition-colors duration-200',
                )}
              >
                <div className="flex min-w-0 flex-row items-center gap-3">
                  {/* Checkbox for selecting files */}
                  <input
                    type="checkbox"
                    checked={selectedFiles.includes(file.id)}
                    onChange={() => handleCheckboxChange(file.id)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                  />

                  <FileIcon className="fill-icon-light-fill dark:fill-white" />

                  <p
                    className={clsx(
                      '2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px]',
                      'text-black dark:text-white',
                      'truncate',
                    )}
                    onClick={() => window.open(file.url, '_blank')}
                  >
                    {file.original_filename}
                  </p>

                  {/* <button
                    onClick={() => handleDownloadFile(file.url, file.original_filename)}
                    className="p-2 rounded-full hover:bg-blue-50 dark:hover:bg-blue-900 transition-colors duration-200 text-blue-600 dark:text-blue-400"
                    title="Download file"
                  >
                    <DownloadIcon className="fill-icon-light-fill dark:fill-white" />
                  </button> */}

                  {/* Single Delete Button */}

                  {!isLoading && (
                    <button
                      onClick={() => handleDeleteFile(file.id)}
                      className="p-2 rounded-full hover:bg-red-50 dark:hover:bg-red-900 transition-colors duration-200 text-red-600 dark:text-red-400"
                      title="Delete file"
                    >
                      X
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>

          {/* Delete Selected Files Button */}
        </div>
      ) : (
        <div
          className={clsx(
            'flex flex-col items-center justify-center',
            'p-8 text-center',
            'bg-gray-50 dark:bg-gray-800',
            'rounded-lg',
            'border-2 border-dashed border-gray-300 dark:border-gray-700',
          )}
        >
          <p className="2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] text-gray-500 dark:text-gray-400">
            No files uploaded yet
          </p>
          <p className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[10px] sm:text-[8px] text-[6px] text-gray-400 dark:text-gray-500 mt-2">
            Upload files using the chat input
          </p>
        </div>
      )}
    </div>
  );
};

export default FilesSection;
