import React from 'react';
import { ChatContextProvider } from '../../../context/chatContext';
import SalesWorkflow from './SalesWorkflow';

const SalesWorkflowPage = () => {
  return (
    <ChatContextProvider>
      <div className="flex flex-col h-screen overflow-hidden bg-background-gray dark:bg-black ">
        <div className="flex-1 overflow-y-auto">
          <SalesWorkflow />
        </div>
      </div>
    </ChatContextProvider>
  );
};

export default SalesWorkflowPage;
