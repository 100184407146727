import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useChatContext } from '../context/chatContext'; // Import the context
import useAxiosInstance from '../utils/axiosInstance.js';
import { ReactComponent as DeleteIcon } from '../Svg/delete.svg';
import { ReactComponent as EditIcon } from '../Svg/edit.svg';
import { ReactComponent as SearchIcon } from '../Svg/search.svg';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';

const ThreadPanel = ({
  showHistoryPanel,
  toggleCanvas,
  isLoadingThreads,
  chats,
  handleDeleteClick,
  handleChatClick,
}) => {
  const {
    setChats,
    updateThreadTitle,
    isLoadingMore,
    setIsLoadingMore,
    pagination,
    darkMode,
    fetchThreads,
  } = useChatContext();
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [newThreadName, setNewThreadName] = useState('');
  const axiosInstance = useAxiosInstance();
  const [searchQuery, setSearchQuery] = useState(''); // New state for search query
  const [activeChatId, setActiveChatId] = useState(null); // New state to track active chat
  const scrollContainerRef = useRef(null); // Reference to the scrollable container
  const [loadedPages, setLoadedPages] = useState(new Set([1])); // Track which pages we've loaded
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [initialFetchDone, setInitialFetchDone] = useState(false);

  // Initial fetch when component mounts - only run once
  useEffect(() => {
    const initialFetch = async () => {
      if (!initialFetchDone) {
        try {
          // Initial fetch for first page only (typically 6 items)
          await fetchThreads(1, false); // false means replace, not append
          setInitialFetchDone(true);
        } catch (error) {
          console.error('Error in initial fetch:', error);
        }
      }
    };

    initialFetch();
    // We intentionally exclude fetchThreads to ensure this runs only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchDone]);

  // Track loaded pages when pagination changes
  useEffect(() => {
    if (pagination?.current_page && !loadedPages.has(pagination.current_page)) {
      setLoadedPages((prev) => new Set([...prev, pagination.current_page]));
    }
  }, [pagination, loadedPages]);

  // Function to check if we need to load more and load them
  const checkAndLoadMore = useCallback(() => {
    if (!scrollContainerRef.current || !pagination || isLoadingMore || searchQuery) return;

    const container = scrollContainerRef.current;
    const { scrollTop, scrollHeight, clientHeight } = container;
    const bottomThreshold = 200; // Increased threshold for better experience
    const isNearBottom = scrollHeight - scrollTop - clientHeight < bottomThreshold;

    if (isNearBottom && pagination.has_next) {
      const nextPage = pagination.current_page + 1;
      // Only load if we haven't loaded this page already
      if (!loadedPages.has(nextPage)) {
        loadMoreThreads(nextPage);
      }
    }
  }, [pagination, isLoadingMore, searchQuery, loadedPages]);

  // Function to load more threads
  const loadMoreThreads = async (page) => {
    if (!pagination.has_next || isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      await fetchThreads(page, true); // Pass true as second parameter to append rather than replace
    } catch (error) {
      console.error('Error loading more threads:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Setup scroll event handler with interval-based checking for better reliability
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    // Immediate check on mount
    checkAndLoadMore();

    // Use both scroll event and interval for redundancy
    const handleScroll = () => {
      checkAndLoadMore();
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    // Backup interval check (every 1000ms) in case scroll events are missed
    const intervalCheck = setInterval(() => {
      if (scrollContainer) {
        checkAndLoadMore();
      }
    }, 1000);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      clearInterval(intervalCheck);
    };
  }, [checkAndLoadMore]);

  const inputRef = useRef(null);
  const handleEditClick = (thread, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setEditingThreadId(thread.thread_id);
    setNewThreadName(thread.thread_name); // Pre-fill the input with the current name

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleEditSave = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    try {
      const threadId = editingThreadId;
      const updatedName = newThreadName;

      // Update the backend
      await axiosInstance.put(`/api/threads/${threadId}/`, {
        thread_name: updatedName,
      });

      // Optimistically update the UI immediately after the successful API call
      updateThreadTitle(threadId, updatedName); // Use the update function here

      // Don't re-fetch the threads, just update locally
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.thread_id === threadId ? { ...chat, thread_name: updatedName } : chat,
        ),
      );
    } catch (error) {
      console.error('Error updating thread:', error.response?.data || error.message);
    } finally {
      // Clear the editing state and input field
      setEditingThreadId(null);
      setNewThreadName('');
    }
  };

  // Cancel editing and reset the state
  const handleCancelEdit = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setEditingThreadId(null);
    setNewThreadName('');
  };

  const filteredChats = chats.filter(
    (chat) =>
      chat.thread_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (chat.chats.length > 0 &&
        chat.chats[chat.chats.length - 1]?.text?.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  // Function to highlight matching text
  const highlightText = (text) => {
    if (!searchQuery) return text; // Return original text if no search query
    const parts = text.split(new RegExp(`(${searchQuery})`, 'gi')); // Split text by search query
    return parts.map((part, partIndex) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span
          key={`highlight-${partIndex}-${part}`}
          className="bg-yellow-300 dark:bg-orange-500 text-black"
        >
          {part}
        </span> // Better unique key for highlighted parts
      ) : (
        part
      ),
    );
  };

  // Simple thread click handler with minimal logic - prevent refetching
  const handleThreadClick = (threadId, e) => {
    if (e) e.preventDefault();

    // Only proceed if we're not in edit mode
    if (editingThreadId !== null) return;

    // Update the active chat immediately
    setActiveChatId(threadId);

    // Call the parent handler but don't wait for it
    // The second parameter (true) might be causing a refetch, change to false
    handleChatClick(threadId, false);
  };

  return (
    <div
      className={`fixed z-18 right-0 h-[calc(100vh-64px)] bg-background-gray transform transition-all duration-500 ease-in-out  
          w-[250px] 
          sm:w-[200px] 
          md:w-[240px] 
          lg:w-[300px] 
          xl:w-[330px]
          2xl:w-[350px] 
          ${
            showHistoryPanel
              ? 'translate-x-0 opacity-100 visible'
              : 'translate-x-full opacity-0 invisible pointer-events-none'
          } dark:bg-black dark:text-gray-icon`}
      onClick={(e) => e.stopPropagation()} // Stop event bubbling
      onKeyDown={(e) => {
        if (e.key === 'Escape') toggleCanvas(0);
      }}
      role="complementary"
      aria-label="Thread history panel"
    >
      <div className="flex flex-col h-full p-4">
        {/* Header section */}
        <div className="flex justify-between items-center">
          <h3 className="text-text-black font-semibold 2xl:text-[16px] xl:text-[15px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] dark:bg-black dark:text-white">
            History
          </h3>
          <button
            type="button"
            aria-label="Close history panel"
            onClick={() => toggleCanvas(0)}
            className="bg-transparent border-0 p-0 cursor-pointer"
          >
            <CloseIcon
              width="20"
              height="20"
              className="fill-icon-light-fill dark:fill-white cursor-pointer 2xl:text-[24px] xl:text-[22px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px]"
            />
          </button>
        </div>

        <hr className="border-t border-text-black my-4 dark:border-white" />

        {/* Search section */}
        <div className="relative w-full mb-4">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-text-black bg-text-gray text-black 2xl:text-[16px] xl:text-[15px] lg:text-[13px] md:text-[10px] sm:text-[8px] text-[6px] 
                       placeholder:text-[#141414] 2xl:placeholder:text-[16px] xl:placeholder:text-[15px] lg:placeholder:text-[14px] md:placeholder:text-[12px] sm:placeholder:text-[10px] placeholder:text-[8px]
                       rounded-md p-2 2xl:pl-10 xl:pl-10 lg:pl-9 md:pl-8 sm:pl-7 pl-6 w-full dark:border-white dark:text-white dark:placeholder:text-white"
          />
          <SearchIcon
            role="img"
            aria-label="Search"
            width="20"
            height="20"
            className="absolute 2xl:top-[22px] xl:top-[20px] lg:top-[19px] md:top-[17px] sm:top-[15px] top-[16px] left-3 transform -translate-y-1/2 fill-icon-light-fill dark:fill-white 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
          />
        </div>

        {/* Thread list with scrollable container */}
        <div
          ref={scrollContainerRef}
          className="flex-1 overflow-y-auto pr-1 thread-scroll-container"
          style={{ overscrollBehavior: 'contain' }} // Prevent scroll chaining
          onScroll={checkAndLoadMore} // Direct onScroll handler
        >
          {/* Loading spinner for initial load */}
          {isLoadingThreads && chats.length === 0 ? (
            <div className="flex items-center justify-center h-20">
              <div className="loading-spinner" />
            </div>
          ) : (
            <>
              {/* Thread items */}
              {filteredChats.map((chat) => (
                <div
                  key={chat.thread_id}
                  className={`text-left pb-2 px-2 rounded-lg w-full mb-2 ${
                    activeChatId === chat.thread_id ? 'bg-white dark:bg-black' : ''
                  }`}
                >
                  <div className="flex my-3 justify-between">
                    <div className="2xl:text-[12px] xl:text-[11px] lg:text-[11px] md:text-[10px] sm:text-[8px] text-[6px]">
                      {new Date(chat.created_at).toLocaleString()}
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        aria-label="Edit thread"
                        onClick={(e) => handleEditClick(chat, e)}
                        className="bg-transparent border-0 p-0 cursor-pointer"
                      >
                        <EditIcon
                          role="img"
                          aria-hidden="true"
                          width="20"
                          height="20"
                          className="fill-icon-light-fill dark:fill-white 2xl:mr-6 xl:mr-5 lg:mr-4 md:mr-3 sm:mr-2 mr-1 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[13px] text-[13px]"
                        />
                      </button>
                      <button
                        type="button"
                        aria-label="Delete thread"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDeleteClick(chat);
                        }}
                        className="bg-transparent border-0 p-0 cursor-pointer"
                      >
                        <DeleteIcon
                          role="img"
                          aria-hidden="true"
                          width="20"
                          height="20"
                          className="fill-icon-light-fill dark:fill-white 2xl:mr-6 xl:mr-5 lg:mr-4 md:mr-1 sm:mr-1 mr-1 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[13px] text-[13px]"
                        />
                      </button>
                    </div>
                  </div>
                  {editingThreadId === chat.thread_id ? (
                    <div
                      className="flex flex-col items-start"
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                      role="presentation"
                    >
                      <input
                        ref={inputRef}
                        type="text"
                        value={newThreadName}
                        onChange={(e) => {
                          e.stopPropagation();
                          setNewThreadName(e.target.value);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                          if (e.key === ' ' && e.target === document.activeElement) {
                            e.stopPropagation();
                          }
                          if (e.key === 'Enter') {
                            handleEditSave(e);
                          }
                          if (e.key === 'Escape') {
                            handleCancelEdit(e);
                          }
                        }}
                        className="border-2 border-b-red-button bg-transparent rounded-lg 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] 2xl:p-1.5 xl:p-1.5 lg:p-1.5 md:p-1 sm:p-1 p-1.5 mb-2 w-full dark:text-white"
                      />
                      <div className="flex space-x-4 w-full">
                        <button
                          type="button"
                          onClick={(e) => handleEditSave(e)}
                          className="2xl:px-3 2xl:py-1 xl:px-3 xl:py-1 lg:px-2.5 lg:py-0.5 md:px-2 md:py-0.5 sm:px-2 sm:py-0.5 px-2 py-0.5 
                          text-white bg-red-button 
                          2xl:rounded-lg xl:rounded-lg lg:rounded-lg md:rounded-md sm:rounded-md rounded-md 
                          2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={(e) => handleCancelEdit(e)}
                          className="2xl:px-3 2xl:py-1 xl:px-3 xl:py-1 lg:px-2.5 lg:py-0.5 md:px-2 md:py-0.5 sm:px-2 sm:py-0.5 px-2 py-0.5 
                          bg-background-gray text-close-button
                          2xl:rounded-lg xl:rounded-lg lg:rounded-lg md:rounded-md sm:rounded-md rounded-md 
                          2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => handleThreadClick(chat.thread_id, e)}
                      className="bg-transparent border-0 p-0 text-left w-full cursor-pointer"
                    >
                      <h4 className="font-semibold text-black 2xl:text-[18px] xl:text-[17px] lg:text-[16px] md:text-[14px] sm:text-[12px] text-[10px] dark:bg-black dark:text-white">
                        {highlightText(chat.thread_name)}
                      </h4>
                      <p className="text-gray-500 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[10px] sm:text-[8px] text-[6px] dark:bg-black dark:text-white">
                        {chat.chats.length > 0
                          ? chat.chats[chat.chats.length - 1]?.text
                              ?.split(' ')
                              .slice(0, 10)
                              .join(' ') +
                            (chat.chats[chat.chats.length - 1]?.text?.split(' ').length > 10
                              ? '...'
                              : '')
                          : 'No messages yet'}
                      </p>
                    </button>
                  )}
                </div>
              ))}

              {/* Loading indicator and status messages */}
              {!searchQuery && (
                <div className="py-4 flex justify-center items-center">
                  {isLoadingMore ? (
                    <div className="loading-spinner-small" />
                  ) : pagination?.has_next ? (
                    <button
                      type="button"
                      className="text-center text-gray-500 text-sm dark:text-gray-400 cursor-pointer hover:underline border-0 bg-transparent"
                      onClick={() => loadMoreThreads(pagination.current_page + 1)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') loadMoreThreads(pagination.current_page + 1);
                      }}
                    >
                      Click or scroll for more
                    </button>
                  ) : chats.length > 0 ? (
                    <div className="text-center text-gray-500 text-sm dark:text-gray-400">
                      No more threads to load
                    </div>
                  ) : null}
                </div>
              )}

              {/* No threads found with search */}
              {filteredChats.length === 0 && searchQuery && (
                <div className="text-center text-gray-500 py-4 text-sm dark:text-gray-400">
                  No threads match your search
                </div>
              )}
            </>
          )}
        </div>

        {/* Footer with thread count indicator */}
        {/* <div className="text-center text-gray-500 text-xs dark:text-gray-400 pt-2 border-t border-gray-200 dark:border-gray-700 mt-2">
          Showing {chats.length} of {pagination?.total_threads || 0} threads
        </div> */}
      </div>
    </div>
  );
};

export default ThreadPanel;
