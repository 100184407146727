/* eslint-disable react/jsx-no-bind */

import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import useAxiosInstance from '../../utils/axiosInstance';
import { useChatContext } from '../../context/chatContext';
import { getMaxTokenLabel, getTemperatureLabel } from './SettingsUtils';
import { Link } from 'react-router-dom';
const Setting = ({ setModalOpen }) => {
  const axiosInstance = useAxiosInstance();
  const { user } = useChatContext();
  const [instructions, setInstructions] = useState('');
  const [maxTokens, setMaxTokens] = useState(1000);
  const [temperature, setTemperature] = useState(0.5);
  const [settingsId, setSettingsId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //fetch settings from the database
  useEffect(() => {
    const fetchSettings = async () => {
      if (user && user.id) {
        try {
          const response = await axiosInstance.get(`/api/settings/${user.id}/`);
          const settings = response.data;

          if (settings) {
            const { id, instructions: savedInstructions, max_tokens, temperature } = settings;
            setSettingsId(id);
            setInstructions(savedInstructions || '');
            setMaxTokens(max_tokens || 1000);
            setTemperature(temperature || 0.5);
          }
        } catch (error) {
          toast.error('Failed to fetch settings');
        } finally {
          setIsLoading(false); // Stop loading after fetching
        }
      } else {
        setIsLoading(false); // Stop loading when user is unavailable
      }
    };

    fetchSettings();
    //axios instance is unstable function and should be fixed in the future
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //handle input change for instructions, maxTokens, and temperature
  const handleInputChange = (value, type) => {
    let numValue; // Moved outside case blocks

    switch (type) {
      case 'instructions':
        setInstructions(value);
        break;
      case 'maxTokens':
        numValue = parseInt(value) || 600;
        setMaxTokens(numValue);

        break;
      case 'temperature':
        setTemperature(Math.min(1, Math.max(0, parseFloat(value))));
        break;
      default:
        break;
    }
  };

  const updateSettings = async (e) => {
    e.preventDefault();

    // Validate max tokens before saving
    if (maxTokens < 300 || maxTokens > 4096) {
      toast.error('Please enter a valid max tokens value (300-4096)');
      return;
    }

    const data = {
      instructions: instructions,
      max_tokens: maxTokens,
      temperature: temperature,
    };

    setIsSaving(true);

    try {
      await axiosInstance.patch(`/api/settings/${settingsId}/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsSaving(false);
      toast.success('Settings updated successfully');
      setModalOpen(false);
    } catch (error) {
      setIsSaving(false);
      toast.error('Failed to update settings');
      setModalOpen(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full lg:max-h-[400px] overflow-y-scroll z-[900] overflow-hidden">
      <form className="flex flex-col gap-4">
        <div>
          <label className="block text-black font-medium mb-2 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
            Max Tokens: {getMaxTokenLabel(maxTokens)}
          </label>
          <div className="flex flex-col gap-2">
            <input
              type="range"
              min="300"
              max="4096"
              value={maxTokens}
              onChange={(e) => handleInputChange(e.target.value, 'maxTokens')}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-xs text-gray-500 dark:text-gray-400">
              <span>Concise</span>
              <span>Balanced</span>
              <span>Expansive</span>
            </div>
          </div>
        </div>

        <div>
          <label className="block text-black font-medium mb-2 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
            Temperature: {getTemperatureLabel(temperature)}
          </label>
          <div className="flex flex-col gap-2">
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={temperature}
              onChange={(e) => handleInputChange(e.target.value, 'temperature')}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-xs text-gray-500 dark:text-gray-400">
              <span>Accurate</span>
              <span>Balanced</span>
              <span>Creative</span>
            </div>
          </div>
        </div>

        <div>
          <label className="block text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] font-medium mb-2 dark:text-white">
            Instructions
          </label>
          <div className="w-full h-auto border border-gray-300 bg-white rounded-md p-1 dark:bg-black">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <textarea
                value={instructions}
                onChange={(e) => handleInputChange(e.target.value, 'instructions')}
                rows={4}
                className="w-full 2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] text-black border-none bg-transparent focus:outline-none resize-none dark:bg-black dark:text-white"
              />
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setModalOpen(false)}
            className="flex bg-background-gray text-close-button py-2 px-3 rounded-md 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[10px]"
          >
            Close
          </button>
          <button
            type="button"
            onClick={updateSettings}
            className={`flex py-2 px-3 rounded-md 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[10px] ${
              isSaving ? 'bg-success text-white cursor-not-allowed' : 'bg-red-button text-white'
            }`}
          >
            {isSaving ? 'Saving...' : 'Update'}
          </button>
        </div>
        <Link
          to={'/release-notes'}
          className="p-2 text-base text-black border border-light-gray rounded-md cursor-pointer hover:bg-background-gray transition-all duration-300 w-full"
        >
          {' '}
          Release Notes{' '}
        </Link>
      </form>
    </div>
  );
};

export default Setting;
