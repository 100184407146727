/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line react/jsx-no-bind
// JSX PROPS should not use as arrow functions and use proper or callback functions
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosInstance from '../utils/axiosInstance';
import DeleteModal from '../components/DeleteModal'; // Import the modular DeleteModal component
import { toast } from 'react-hot-toast';
const ChatContext = createContext();

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
};

export const ChatContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [defaultThread, setDefaultThread] = useState(null);
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
  const [user, setUser] = useState({ username: 'User', title: 'Member', id: null, oid: null });
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showSuggestionGrid, setShowSuggestionGrid] = useState(false);
  const [showHistoryGrid, setShowHistoryGrid] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  const [chats, setChats] = useState([]); // chats state
  const [historyItems, setHistoryItems] = useState([]);
  const [isLoadingThreads, setIsLoadingThreads] = useState(false);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [showSuggestionGridAfterSpinner, setShowSuggestionGridAfterSpinner] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [showPanelIcon, setShowPanelIcon] = useState(false);
  const [lastVisitedThreadId, setLastVisitedThreadId] = useState('');
  const [formValue, setFormValue] = useState('');
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search input
  const [searchResultExists, setSearchResultExists] = useState(false); // New state for search input

  const [profilePicture, setProfilePicture] = useState(null);
  const [greetingMessage, setGreetingMessage] = useState(null);
  const [userStatusDescription, setUserStatusDescription] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [playingMessageId, setPlayingMessageId] = useState(null);
  const [inputText, setInputText] = useState('');
  const axiosInstance = useAxiosInstance();
  const [isStreaming, setIsStreaming] = useState(false);
  const [activeAudioId, setActiveAudioId] = useState(null); // Track single active audio

  // Add new states for pagination
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 1,
    total_threads: 0,
    has_next: false,
    has_previous: false,
  });

  // Add these states if not already present
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const DEFAULT_USER_ICON = `data:image/svg+xml;base64,${btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
</svg>
`)}`;

  // Fetch last visited thread ID
  const fetchLastVisitedThreadId = useCallback(
    async (pageNumber) => {
      if (isLoadingChats) return;
      try {
        setIsLoadingChats(true);
        const response = await axiosInstance.get(`/api/last-accessed-threads/${pageNumber}/`);
        if (response.data && response.data.thread) {
          setLastVisitedThreadId(response.data.thread);
        }
      } catch (error) {
        console.error('Error fetching last visited thread ID:', error);
      } finally {
        setIsLoadingChats(false);
      }
    },
    [axiosInstance, isLoadingChats],
  );

  const getUserStatus = async () => {
    try {
      // Make a POST request to the user-status API
      const saved_status = await axiosInstance.get('/api/user-status/');
      setUserStatusDescription(saved_status.data?.[0]?.description);
      setUserStatus(saved_status.data?.[0]?.status);
      setUserLocation(saved_status.data?.[0]?.work_location);
    } catch (error) {
      toast.error('Error submitting status');
    }
  };

  // Modified fetchThreads to handle empty results properly
  const fetchThreads = useCallback(
    async (page = 1) => {
      if (isLoadingThreads) return;
      // setIsLoadingThreads(true);
      try {
        const response = await axiosInstance.get('/api/threads/', {
          params: { page, page_size: 6 },
        });

        const { results, pagination } = response.data;

        // Update pagination info
        setPagination(pagination);

        if (results && results.length > 0) {
          const sortedThreads = results.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
          );

          // For page 1, replace threads; for subsequent pages, append them
          if (page === 1) {
            // setChats(sortedThreads);
            setHistoryItems(sortedThreads);
          } else {
            // For subsequent pages, append the new threads to existing ones
            // Use a Map to ensure no duplicates by thread_id
            const existingThreadIds = new Map(chats.map((chat) => [chat.thread_id, true]));
            const newThreads = sortedThreads.filter(
              (thread) => !existingThreadIds.has(thread.thread_id),
            );

            setChats((prevChats) => [...prevChats, ...newThreads]);
            // setHistoryItems(prevItems => [...prevItems, ...newThreads]);
          }

          if (!defaultThread && page === 1) {
            setDefaultThread(sortedThreads[0].thread_id);
          }
        } else if (page === 1) {
          // Only clear threads if it's the first page and no results
          setChats([]);
          setHistoryItems([]);
        }

        // Only fetch last visited thread if we have threads and this is page 1
        if (pagination.total_threads > 0 && pagination.total_pages > 0 && page === 1) {
          await fetchLastVisitedThreadId(pagination.current_page);
        } else if (page === 1) {
          // Clear last visited thread ID if no threads exist (only on first page)
          setLastVisitedThreadId('');
        }
      } catch (error) {
        console.error('Error fetching threads:', error);
      } finally {
        setIsLoadingThreads(false);
        setIsLoadingMore(false); // Ensure loading more state is reset
      }
    },
    [axiosInstance, isLoadingThreads, defaultThread, fetchLastVisitedThreadId, chats],
  );

  // Fetch user info
  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/users/me/');
      const { username, title, id, oid } = response.data;
      setUser({ username, title, id, oid });
    } catch (error) {
      toast.error('Error fetching user info');
    }
  }, [axiosInstance]);

  // Clear chat messages
  const clearMessages = () => setMessages([]);

  // Handle new thread creation
  const handleNewThread = async (newThread) => {
    if (isCreatingThread) return;
    setIsCreatingThread(true);
    setShowSuggestionGrid(false);
    setShowSuggestionGridAfterSpinner(false);

    try {
      if (!newThread) {
        const response = await axiosInstance.post('/api/threads/', {
          thread_name: `New Chat ${Date.now()}`,
          model_name: 'azure',
        });

        newThread = response.data;
      }

      setDefaultThread(newThread.thread_id);
      setShowHistoryGrid(false);
      setShowWelcomeModal(false);
    } catch (error) {
      toast.error('Error creating thread');
    } finally {
      setIsCreatingThread(false);
      setShowSuggestionGridAfterSpinner(true);
    }
  };

  const fetchUploadedFiles = async (threadId) => {
    const response = await axiosInstance.get('/api/files/', {
      params: { thread_id: threadId },
    });
    setUploadedFiles(response.data.files);
  };

  // Handle delete confirmation modal
  const handleDeleteClick = (chat) => {
    if (!chat || !chat.thread_id) {
      toast.error('Invalid chat object provided for deletion.');
      return;
    }
    setChatToDelete(chat);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!chatToDelete || !chatToDelete.thread_id) {
      toast.error('No valid thread selected for deletion.');
      return;
    }
    try {
      await axiosInstance.delete(`/api/threads/${chatToDelete.thread_id}/`);
      await fetchThreads();
      if (chats.length > 1) {
        setDefaultThread(chats[1]?.thread_id);
      } else {
        setDefaultThread(null);
      }
      if (chatToDelete.thread_id === defaultThread) {
        navigate('/home');
      }
    } catch (error) {
      toast.error('Error deleting thread');
    } finally {
      setDeleteModalOpen(false);
    }
  };

  // Function to update thread title in chats and historyItems state
  const updateThreadTitle = async (threadId, newThreadName) => {
    try {
      // Make the API call to update the thread name
      await axiosInstance.put(`/api/threads/${threadId}/`, {
        thread_name: newThreadName,
      });

      // Update the local state
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.thread_id === threadId ? { ...chat, thread_name: newThreadName } : chat,
        ),
      );

      setHistoryItems((prevHistoryItems) =>
        prevHistoryItems.map((item) =>
          item.thread_id === threadId ? { ...item, thread_name: newThreadName } : item,
        ),
      );
    } catch (error) {
      toast.error('Error updating thread title');
    }
  };
  const fetchProfilePicture = async () => {
    try {
      if (user.oid != null) {
        // Define the API endpoint to get the profile picture
        const pictureUrl = `https://graph.microsoft.com/v1.0/users/${user.oid}/photo/$value`;

        // Make the API call to get the profile picture
        const response = await axiosInstance.get(pictureUrl, { responseType: 'arraybuffer' });

        if (response.data) {
          const base64Image = await arrayBufferToBase64(response.data);
          // Set the image URL as base64
          const imageUrl = `data:image/jpeg;base64,${base64Image}`;
          setProfilePicture(imageUrl);
        } else {
          // Set default user icon if no picture data
          setProfilePicture(DEFAULT_USER_ICON);
        }
      } else {
        // Set default user icon if no user.oid
        setProfilePicture(DEFAULT_USER_ICON);
      }
    } catch (error) {
      console.error('Error fetching profile picture, using default icon');
      // Set default user icon on error
      setProfilePicture(DEFAULT_USER_ICON);
    }
  };
  const arrayBufferToBase64 = (arrayBuffer) => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([arrayBuffer]);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 from the result
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const getGreeting = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/greeting/`;

      const response = await axiosInstance.get(url, {
        params: {
          message:
            "Generate greeting for me just for 2-3 lines only based on my information. Do not start from hello or hi; direct start like 'It's great to see you back!' and so on. also add some emoji if possible.",
        },
      });

      if (response.data) {
        const finalMessage = response.data; // Assuming response.data contains the final message

        setGreetingMessage(finalMessage);
        localStorage.setItem('greetingMessage', JSON.stringify(finalMessage)); // Store in local storage
      }
    } catch (error) {
      toast.error('Error sending message to API');
    }
  };

  // Primary initialization effect
  useEffect(() => {
    let isSubscribed = true;

    const initializeApp = async () => {
      try {
        if (!user.oid) {
          await fetchUserInfo();
        }
      } catch (error) {
        console.error('Error in app initialization:', error);
      }
    };

    initializeApp();
    return () => {
      isSubscribed = false;
    };
  }, []); // Run only once on mount

  // User-dependent data loading effect
  useEffect(() => {
    let isSubscribed = true;

    const loadUserData = async () => {
      if (!user.oid) return;

      try {
        // Load user status if not available
        if (!userStatusDescription && isSubscribed) {
          await getUserStatus();
        }

        // Load profile picture if not available
        if (!profilePicture && isSubscribed) {
          await fetchProfilePicture();
        }

        // Load greeting
        if (isSubscribed) {
          const storedGreetingMessage = localStorage.getItem('greetingMessage');
          if (storedGreetingMessage) {
            setGreetingMessage(JSON.parse(storedGreetingMessage));
          } else {
            await getGreeting();
          }
        }

        // Load threads only once when user is available
        if (isSubscribed) {
          await fetchThreads();
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    };

    loadUserData();
    return () => {
      isSubscribed = false;
    };
  }, [user.oid]); // Only run when user.oid changes

  // Fetch suggestions
  const getSuggestions = useCallback(async () => {
    try {
      //if suggestions are already loading, not calling it again
      setIsSuggestionsLoading(true);
      const response = await axiosInstance.get('/api/get-prompts/');
      const jsonPrompts = response?.data?.data?.prompts || [];
      //some responses are in json format, so we need to check the format and  parse them if required
      const cleanString = (str) => {
        try {
          return JSON.parse(str); // Parses if needed
        } catch {
          return str; // Returns as is if already clean
        }
      };
      const cleanedPrompts = Object.values(jsonPrompts).map(cleanString);
      setSuggestions(cleanedPrompts);
    } catch (error) {
      toast.error('Error fetching threads');
    } finally {
      setIsSuggestionsLoading(false);
    }
  }, [axiosInstance]);

  // const handleEditMessage = (messageId, newText) => {
  // TODO:IN FUTURE
  // };

  const handleStreamMessage = async (message, threadId, isDocumentUploadEnabled) => {
    try {
      setIsGeneratingAnswer(true);
      const accessTokenKey = Object.keys(sessionStorage).find((key) => key.includes('accesstoken'));
      const bearerToken = JSON.parse(sessionStorage.getItem(accessTokenKey));

      // Create FormData to handle both message and files
      const formData = new FormData();
      formData.append('message', message);
      formData.append('thread_id', threadId);
      formData.append('document_search', isDocumentUploadEnabled);

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/stream/`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        body: formData,
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Failed to get response: ${response.status}`);
      }

      // Start with an empty response
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now(),
          createdAt: new Date().toISOString(),
          text: '',
          ai: true,
        },
      ]);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';
      let shouldContinue = true;
      while (shouldContinue) {
        const { done, value } = await reader.read();
        if (done) break;

        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          const chat_id = JSON.parse(chunk?.split('\n')[0])?.chat_uuid;
          accumulatedResponse += chunk;

          setMessages((prevMessages) => {
            const updated = [...prevMessages];
            const lastMessage = updated[updated.length - 1];
            lastMessage.text = accumulatedResponse;
            if (chat_id) lastMessage.chat_id = chat_id;
            return updated;
          });
        }
      }
    } catch (error) {
      toast.error('Error streaming message');
      throw error;
    } finally {
      setIsGeneratingAnswer(false);
    }
  };

  const contextValue = {
    messages,
    setMessages,
    clearMessages,
    defaultThread,
    setDefaultThread,
    isGeneratingAnswer,
    setIsGeneratingAnswer,
    handleNewThread,
    user,
    showWelcomeModal,
    setShowWelcomeModal,
    showSuggestionGrid,
    setShowSuggestionGrid,
    showHistoryGrid,
    setShowHistoryGrid,
    handleDeleteClick,
    chats,
    setChats, // Provide setChats so it's accessible in components like ThreadPanel
    historyItems,
    isLoadingThreads,
    showSuggestionGridAfterSpinner,
    setShowSuggestionGridAfterSpinner,
    darkMode,
    setDarkMode,
    showPanelIcon,
    setShowPanelIcon,
    lastVisitedThreadId,
    setLastVisitedThreadId,
    formValue,
    setFormValue,
    updateThreadTitle,
    setHistoryItems,
    isLoadingChats,
    setIsLoadingChats,

    fetchThreads,
    searchQuery,
    setSearchQuery,
    searchResultExists,
    setSearchResultExists,
    profilePicture,
    greetingMessage,
    userStatusDescription,
    setUserStatusDescription,
    userStatus,
    setUserStatus,
    userLocation,
    setUserLocation,
    getUserStatus,
    getSuggestions,
    isSuggestionsLoading,
    setIsSuggestionsLoading,
    suggestions,
    handleStreamMessage,
    playingMessageId,
    setPlayingMessageId,
    inputText,
    setInputText,
    fetchUploadedFiles,
    uploadedFiles,
    setUploadedFiles,
    isStreaming,
    setIsStreaming,
    activeAudioId,
    setActiveAudioId,
    pagination,
    setPagination,
    isLoadingMore,
    setIsLoadingMore,
  };

  return (
    <ChatContext.Provider value={contextValue}>
      {children}

      {/* Delete Modal */}
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </ChatContext.Provider>
  );
};
