import React from 'react';
import { MdDescription } from 'react-icons/md';
import QuillEditor from '../../Editor/QuillEditor';

const Proposal = ({ stepData, workflowId, refreshWorkflow }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-red-100 dark:bg-red-900 rounded-full">
          <MdDescription className="text-2xl text-red-button" />
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 dark:text-white">Project Proposal</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Detailed proposal based on your company&lsquo;s needs
          </p>
        </div>
      </div>

      <div className="prose prose-lg dark:prose-invert max-w-none">
        <QuillEditor
          content={stepData?.content?.proposal || ''}
          workflowId={workflowId}
          stepType="proposal"
          onUpdate={(newContent) => {
            console.log('Proposal updated:', newContent);
          }}
          refreshWorkflow={refreshWorkflow}
        />
      </div>
    </div>
  );
};

export default Proposal;
