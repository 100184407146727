import React from 'react';

const Disclaimer = () => {
  return (
    <div
      className={`flex bg-background-gray dark:bg-black justify-center 2xl:ml-[40px] xl:ml-[60px] lg:ml-[70px] md:ml-[60px] sm:ml-[60px] ml-[50px] transition-[width,margin] duration-1000`}
    >
      <h1
        className={`2xl:w-[848px] xl:w-[780px] lg:w-[760px] md:w-[560px] sm:w-[460px] w-[260px]  transition-colors duration-150 dark:text-gray-icon 2xl:text-[12px] xl:text-[10px] lg:text-[8px] md:text-[6px] sm:text-[5px] text-[4px] py-2`}
      >
        Disclaimer: Elysium Workspace chats are private and not used to improve AI models. Elysium
        AI may occasionally provide inaccurate or incomplete
        <br />
        information; please verify critical details independently.
      </h1>
    </div>
  );
};

export default Disclaimer;
