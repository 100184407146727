const MAX_TOKEN_RANGES = {
  CONCISE: { min: 300, max: 1632 },
  BALANCED: { min: 1633, max: 2964 },
  EXPANSIVE: { min: 2965, max: 4096 },
};

const TEMPERATURE_RANGES = {
  ACCURATE: { min: 0, max: 0.3 },
  BALANCED: { min: 0.31, max: 0.7 },
  CREATIVE: { min: 0.71, max: 1 },
};

export const getMaxTokenLabel = (value) => {
  if (value >= MAX_TOKEN_RANGES.CONCISE.min && value <= MAX_TOKEN_RANGES.CONCISE.max)
    return 'Concise';
  if (value >= MAX_TOKEN_RANGES.BALANCED.min && value <= MAX_TOKEN_RANGES.BALANCED.max)
    return 'Balanced';
  return 'Expansive';
};

export const getTemperatureLabel = (value) => {
  if (value >= TEMPERATURE_RANGES.ACCURATE.min && value <= TEMPERATURE_RANGES.ACCURATE.max)
    return 'Accurate';
  if (value >= TEMPERATURE_RANGES.BALANCED.min && value <= TEMPERATURE_RANGES.BALANCED.max)
    return 'Balanced';
  return 'Creative';
};
