export const WORKFLOW_STEPS = [
  {
    id: 1,
    title: 'Company Analysis',
    description: 'Analyze company background and market position',
    status: 'pending',
    endpoint: 'company_analysis',
  },
  {
    id: 2,
    title: 'Problem Identification',
    description: 'Identify key challenges and opportunities',
    status: 'pending',
    endpoint: 'problem_identification',
  },
  {
    id: 3,
    title: 'Value Proposition',
    description: 'Define unique value proposition',
    status: 'pending',
    endpoint: 'value_proposition',
  },
  {
    id: 4,
    title: 'Proposal',
    description: 'Generate detailed business proposal',
    status: 'pending',
    endpoint: 'proposal',
  },
  {
    id: 5,
    title: 'Email',
    description: 'Create professional outreach email',
    status: 'pending',
    endpoint: 'email',
  },
  {
    id: 6,
    title: 'Presentation',
    description: 'Generate sales presentation',
    status: 'pending',
    endpoint: 'presentation',
  },
];
