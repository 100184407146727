import React, { useEffect, useState, useCallback } from 'react';
import { ReactComponent as Close } from '../../Svg/close.svg';
import { ReactComponent as SearchIcon } from '../../Svg/search.svg';
import useAxiosInstance from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { formatDate } from '../../utils/formatStringUtils';

const ReleaseNotesTOC = ({ isTOCOpen, onClose }) => {
  const axiosInstance = useAxiosInstance();
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeNoteId, setActiveNoteId] = useState(null);

  // fetch releaseNotes
  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        const response = await axiosInstance.get('/api/release-notes/');
        setReleaseNotes(response.data);
        setFilteredNotes(response.data);
      } catch (error) {
        toast.error('Failed to fetch release notes');
      }
    };

    fetchReleaseNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Track visible notes when scrolling
  const checkVisibleNotes = useCallback(() => {
    const container = document.getElementById('release-notes-page');
    if (!container || releaseNotes.length === 0) return;

    const containerRect = container.getBoundingClientRect();
    let topVisibleNote = null;

    // Find the first note that's fully visible in the container
    for (const note of releaseNotes) {
      const element = document.getElementById(note.id);
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const isFullyVisible =
          elementRect.top >= containerRect.top && elementRect.top <= containerRect.bottom - 100; // 100px threshold to consider it visible

        if (isFullyVisible) {
          topVisibleNote = note.id;
          break;
        }
      }
    }

    setActiveNoteId(topVisibleNote);
  }, [releaseNotes]);

  // Add scroll event listener
  useEffect(() => {
    const container = document.getElementById('release-notes-page');
    if (container) {
      container.addEventListener('scroll', checkVisibleNotes);
      // Initial check
      checkVisibleNotes();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkVisibleNotes);
      }
    };
  }, [checkVisibleNotes]);

  // handle search
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredNotes(releaseNotes);
      return;
    }

    const searchTermLower = searchTerm.toLowerCase();
    const filtered = releaseNotes.filter((note) =>
      formatDate(note.release_date).toLowerCase().includes(searchTermLower),
    );
    setFilteredNotes(filtered);
  }, [searchTerm, releaseNotes]);

  // handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Highlight search term in text
  const highlightText = (text) => {
    if (!searchTerm.trim()) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} className="bg-yellow-300 dark:bg-light-white text-black font-medium">
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </>
    );
  };

  // Scroll to release note
  const scrollToReleaseNote = (noteId) => {
    const element = document.getElementById(noteId);
    const container = document.getElementById('release-notes-page');

    if (element && container) {
      // Get the container's scroll position
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      // Calculate the scroll position within the container
      const relativePosition = elementRect.top - containerRect.top + container.scrollTop;

      // Scroll the container
      container.scrollTo({
        top: relativePosition - 20, // Adding a small offset for better visibility
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className={`absolute h-full  bg-background-gray dark:bg-black px-5 py-6 right-0 top-0 z-100 flex gap-4 ${
        isTOCOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-all duration-300 w-[350px] `}
    >
      {/* closeIcon */}
      <div className="w-max h-max mt-3 cursor-pointer" onClick={onClose}>
        <Close width="22" height="22" className="fill-icon-light-fill dark:fill-white" />
      </div>
      {/* container */}
      <div className="w-full flex flex-col h-max overflow-hidden gap-4">
        {/* searchbar */}
        <div className="flex w-full p-3 items-center justify-start gap-2 border rounded-lg  border-slight-gray dark:border-light-white bg-text-gray">
          <SearchIcon
            role="img"
            aria-label="Search"
            width="20"
            height="20"
            className=" dark:fill-white "
          />
          <input
            type="text"
            placeholder="Search"
            className="w-full bg-transparent outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {/* TOC */}
        <div className="w-full h-max overflow-y-scroll flex flex-col">
          {filteredNotes?.length > 0 ? (
            filteredNotes.map((note) => (
              <div
                key={note?.id}
                className={`p-4 w-full rounded-xl cursor-pointer hover:text-red-button transition-all duration-300 ${
                  note.id === activeNoteId
                    ? 'bg-white text-red-button dark:bg-light-grey'
                    : 'hover:bg-white dark:hover:bg-light-grey'
                }`}
                onClick={() => scrollToReleaseNote(note?.id)}
                aria-label={`Navigate to release from ${formatDate(note?.release_date)}`}
              >
                {highlightText(formatDate(note?.release_date))}
              </div>
            ))
          ) : (
            <div className="p-4 w-full text-center">No matching release notes found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReleaseNotesTOC;
