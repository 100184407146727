import React, { useState, useEffect, useCallback, useRef } from 'react';
// import ReactMarkdown from 'react-markdown';
import robo from '../assets/robo.png';
import { MdOutlineStopCircle, MdSend } from 'react-icons/md';
// import DislikeModal from './DislikeModal';
// import LikeModal from './LikeModal';
import { useChatContext } from '../context/chatContext';
import useAxiosInstance from '../utils/axiosInstance';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';
import { ReactComponent as FilesSvg } from '../Svg/file.svg';
// import { MAX_TEXT_LENGTH } from './ChatInput';

// Import SVGs as React components
import { ReactComponent as EditIcon } from '../Svg/edit.svg';
import { ReactComponent as CopyIcon } from '../Svg/copy.svg';
import { ReactComponent as AudioIcon } from '../Svg/text_to_speech.svg';
import { ReactComponent as LikeIcon } from '../Svg/like.svg';
import { ReactComponent as DislikeIcon } from '../Svg/dislike.svg';

import parse from 'html-react-parser';
import TypingRenderer from './TypingRenderer';
import TextToSpeech from './text-to-speech/TextToSpeech';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

// Preprocessing function to replace hashes with corresponding HTML headings
const preprocessMarkdown = (markdownText) => {
  return markdownText
    .split('\n')
    .map((line) => {
      // Match # headings
      const headingMatch = line.match(/^(#{1,6})\s+(.*)$/);
      if (headingMatch) {
        const [_, hashes, content] = headingMatch;
        const headingLevel = hashes.length;

        // Define consistent sizes for headings
        const sizeClass =
          headingLevel === 1
            ? 'text-2xl' // Adjust this for h1
            : headingLevel === 2
            ? 'text-xl' // Adjust this for h2
            : headingLevel === 3
            ? 'text-lg' // Adjust this for h3
            : headingLevel === 4
            ? 'text-base' // Adjust this for h4
            : headingLevel === 5
            ? 'text-base' // Adjust this for h5
            : 'text-base'; // Adjust this for h6

        // Process highlighting within heading content
        const highlightedContent = content.replace(
          /==(.+?)==/g,
          '<mark class="bg-yellow-200 dark:bg-yellow-700">$1</mark>',
        );

        return `<h${headingLevel} class="font-bold ${sizeClass} mb-2 break-words">${highlightedContent}:</h${headingLevel}>`;
      }

      // Match **bold text** for headings
      const boldHeadingMatch = line.match(/^\*\*(.+?)\*\*$/);
      if (boldHeadingMatch) {
        const boldContent = boldHeadingMatch[1];
        // Process highlighting within bold heading
        const highlightedBoldContent = boldContent.replace(
          /==(.+?)==/g,
          '<mark class="bg-yellow-200 dark:bg-yellow-700">$1</mark>',
        );
        return `<h2 class="font-bold text-xl mb-2 break-words">${highlightedBoldContent}</h2>`;
      }

      // Replace inline **bold text**
      const inlineBoldProcessed = line.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

      // Replace ==highlighted text==
      const inlineHighlightedProcessed = inlineBoldProcessed.replace(
        /==(.+?)==/g,
        '<mark class="bg-yellow-200 dark:bg-yellow-700">$1</mark>',
      );

      // Wrap remaining text in a paragraph
      return inlineHighlightedProcessed
        ? `<p class="font-normal text-base mx-2 break-words">${inlineHighlightedProcessed}</p>`
        : '';
    })
    .join('\n');
};

const ChatMessage = ({
  message,
  showHistoryPanel,
  threadId,
  isDocumentUploadEnabled,
  isLastMessage,
  setIsElysiumTyping,
}) => {
  const [isCopied, setIsCopied] = useState(false); // State for copy popover
  const {
    isGeneratingAnswer: contextIsGeneratingAnswer,
    searchQuery,
    profilePicture,
    handleStreamMessage,
    setMessages,
    isStreaming,
    activeAudioId,
    setActiveAudioId,
  } = useChatContext();
  const [editingMessage, setEditingMessage] = useState(false); // New state for editing
  const [newMessageText, setNewMessageText] = useState(message.text); // New state for new message text
  const [isLiked, setIsLiked] = useState(message.is_like); // Track if the message is liked
  const [isDisliked, setIsDisliked] = useState(message.is_dislike); // Track if the message is disliked
  const [aggregatedContent, setAggregatedContent] = useState(''); // Holds the full AI response
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(message.chat_id);

  // Track loading state for references
  const [isLoadingReferences, setIsLoadingReferences] = useState(false);
  // State to hold the actual reference data from API
  const [referenceData, setReferenceData] = useState({
    company: [],
    thread: [],
  });

  // References dropdown states
  const [showReferences, setShowReferences] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    company: false,
    thread: false,
  });
  const [expandedFiles, setExpandedFiles] = useState({});

  const axiosInstance = useAxiosInstance(); // Add this line to get axios instance

  const isThisMessagePlaying = activeAudioId === message.chat_id;
  const audioRef = useRef(null);

  // Add new state for tracking fresh messages
  const [isFreshMessage, setIsFreshMessage] = useState(false);

  const handleEditTextChange = (e) => {
    let newText = e.target.value;
    setNewMessageText(newText);
  };

  useEffect(() => {
    // Show animation only if:
    // 1. This is the last message AND
    // 2. We're currently streaming AND
    // 3. This message has the streaming flag
    const shouldAnimate = isLastMessage && isStreaming && message.isStreaming;
    setIsFreshMessage(shouldAnimate);
  }, [isLastMessage, isStreaming, message.isStreaming]);

  // Parse and aggregate content
  useEffect(() => {
    if (message) {
      setChatId(message.chat_id);
    }
    if (message.text) {
      try {
        const lines = message.text.split('\n'); // Split the text by newlines
        let combinedContent = '';

        lines.forEach((line) => {
          try {
            const parsed = JSON.parse(line.trim() + '\n'); // Parse each line as JSON
            if (parsed.content && parsed.content.trim()) {
              combinedContent += parsed.content; // Append valid content
            }
          } catch {
            // Handle plain text or invalid JSON
            if (line.trim()) {
              combinedContent += line.trim() + '\n';
            }
          }
        });

        setAggregatedContent(combinedContent); // Set the aggregated content
      } catch (error) {
        console.error('Error processing message.text:', error);
      }
    }
  }, [message.text, message.chat_id, chatId]);

  const { text, ai } = message;
  const isHuman = !ai;

  const preprocessTextForHighlighting = (inputText, query) => {
    const trimmedQuery = query.trim();
    if (!trimmedQuery) return inputText; // No highlight if only whitespace or empty

    // Escape special chars
    const escapedQuery = trimmedQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Replace matches, conditionally highlight
    return inputText.replace(new RegExp(`(${escapedQuery})`, 'gi'), (match, p1) => {
      // Check if matched text is purely whitespace
      if (p1.trim() === '') {
        return p1; // Return unchanged if only whitespace
      } else {
        return `==${p1}==`; // Highlight as bold markdown
      }
    });
  };

  // Processed text with highlighting
  const highlightedAggregatedContent = preprocessTextForHighlighting(
    aggregatedContent,
    searchQuery,
  );

  // Apply markdown preprocessing to the highlighted content
  const processedText = preprocessMarkdown(highlightedAggregatedContent.replace(/\\n/g, '<br />'));

  // Replace mockApiRequest with real API calls
  const toggleReaction = async (type) => {
    setIsLoading(true);
    try {
      const endpoint = type === 'like' ? 'toggle_reaction' : 'toggle_dislike';
      await axiosInstance.get(`/api/chat-reactions/${message.chat_id}/${endpoint}/`);
      return { success: true };
    } catch (error) {
      console.error(`Error toggling ${type}:`, error);
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleLikeIconClick = async () => {
    if (!isLoading) {
      try {
        if (isDisliked) {
          // First toggle off dislike if message was disliked
          const dislikeResponse = await toggleReaction('dislike');
          if (!dislikeResponse.success) return;
        }

        if (!isLiked) {
          // Toggle like on if not already liked
          const likeResponse = await toggleReaction('like');
          if (likeResponse.success) {
            setIsLiked(true);
            setIsDisliked(false);
          }
        } else {
          // Toggle like off if already liked
          const likeResponse = await toggleReaction('like');
          if (likeResponse.success) {
            setIsLiked(false);
          }
        }
      } catch (error) {
        console.error('Error handling like:', error);
      }
    }
  };

  const handleDislikeIconClick = async () => {
    if (!isLoading) {
      try {
        if (isLiked) {
          // First toggle off like if message was liked
          const likeResponse = await toggleReaction('like');
          if (!likeResponse.success) return;
        }

        if (!isDisliked) {
          // Toggle dislike on if not already disliked
          const dislikeResponse = await toggleReaction('dislike');
          if (dislikeResponse.success) {
            setIsDisliked(true);
            setIsLiked(false);
          }
        } else {
          // Toggle dislike off if already disliked
          const dislikeResponse = await toggleReaction('dislike');
          if (dislikeResponse.success) {
            setIsDisliked(false);
          }
        }
      } catch (error) {
        console.error('Error handling dislike:', error);
      }
    }
  };

  const handleAudioEnd = useCallback(() => {
    setActiveAudioId(null);
  }, [setActiveAudioId]);

  const toggleAudioState = useCallback(() => {
    if (isThisMessagePlaying) {
      // Stop current audio
      audioRef.current?.stopPlayback();
      setActiveAudioId(null);
    } else {
      // Stop any playing audio and start new one
      if (activeAudioId) {
        // This will trigger stopPlayback for all TextToSpeech components
        // but only the active one will actually stop
        setActiveAudioId(null);
      }
      // Small timeout to ensure previous audio is stopped
      setTimeout(() => {
        setActiveAudioId(message.chat_id);
      }, 50);
    }
  }, [isThisMessagePlaying, message.chat_id, activeAudioId, setActiveAudioId]);

  // Add this effect to handle audio state changes
  useEffect(() => {
    if (isThisMessagePlaying && audioRef.current) {
      audioRef.current.startPlayback();
    } else if (!isThisMessagePlaying && audioRef.current) {
      audioRef.current.stopPlayback();
    }
  }, [isThisMessagePlaying]);

  const handleCopy = () => {
    if (!aggregatedContent) return; // Prevent copying empty content

    navigator.clipboard
      .writeText(aggregatedContent)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset "Copied" message after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };

  const handleEditClick = () => {
    // setFormValue(text);
    // inputRef.current.focus();
    setEditingMessage(true);
  };

  const handleEditPaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');

    let newText = newMessageText + pastedText;
    setNewMessageText(newText);
  };

  const handleEditSave = async () => {
    // Don't proceed if the text hasn't changed
    if (newMessageText === text) {
      setEditingMessage(false);
      return;
    }

    try {
      setIsLoading(true);
      setEditingMessage(false);

      // First update the chat message
      const updateResponse = await axiosInstance.post('/api/update-chat/', {
        chat_id: message.chat_id,
        thread_id: threadId,
        message: newMessageText,
      });

      if (updateResponse.status === 200) {
        // Update the current message in the messages array
        setMessages((prevMessages) => {
          const messageIndex = prevMessages.findIndex((msg) => msg.chat_id === message.chat_id);
          if (messageIndex === -1) return prevMessages;

          const updatedMessages = [...prevMessages];
          updatedMessages[messageIndex] = {
            ...updatedMessages[messageIndex],
            text: newMessageText,
          };

          // Remove all messages after the edited message
          return updatedMessages.slice(0, messageIndex + 1);
        });

        // Get new AI response
        await handleStreamMessage(newMessageText, threadId, isDocumentUploadEnabled);

        // After streaming is complete, fetch the full updated conversation
        try {
          const response = await axiosInstance.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/threads/${threadId}`,
          );

          if (response.data && Array.isArray(response.data.chats)) {
            setMessages(response.data.chats);
          }
        } catch (error) {
          console.error('Error fetching updated chats:', error);
          toast.error('Failed to refresh conversation. Please reload the page.');
        }
      }
    } catch (error) {
      console.error('Error updating message:', error);
      toast.error('Failed to update message. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingMessage(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleEditSave();
    }
  };

  // Clean the text content for speech
  const cleanTextForSpeech = message?.text?.replace(/<[^>]*>/g, '') || '';

  // Shared width logic for both AI and Human messages
  const maxWidthClass = clsx(
    '2xl:max-w-[800px]',
    showHistoryPanel
      ? 'xl:max-w-[600px] lg:max-w-[400px] md:max-w-[270px] sm:max-w-[220px] max-w-[120px]'
      : 'xl:max-w-[700px] lg:max-w-[530px] md:max-w-[490px] sm:max-w-[380px] max-w-[210px]',
  );

  const handleFileClick = (file) => {
    window.open(file.sas_url, '_blank');
  };

  // Fetch reference data from API
  const fetchReferenceData = useCallback(async () => {
    if (!message?.chat_id) return;

    try {
      setIsLoadingReferences(true);
      const response = await axiosInstance.get(`/api/chat/${message.chat_id}/`);

      if (response.data) {
        // Parse and organize the data
        const organizedData = {
          company: [],
          thread: [],
        };

        // Extract context data - it's a JSON string in the response
        if (response.data.context) {
          try {
            // Parse the context string to JSON
            let contextData;
            if (typeof response.data.context === 'string') {
              contextData = JSON.parse(response.data.context);
            } else {
              contextData = response.data.context;
            }

            // Extract company knowledge base documents
            if (contextData.company_knowledge_base) {
              organizedData.company = Object.entries(contextData.company_knowledge_base).map(
                ([filename, content]) => ({
                  filename,
                  value: content,
                  id: `company-${filename}`,
                }),
              );
            }

            // Extract user documents
            if (contextData.user_documents) {
              organizedData.thread = Object.entries(contextData.user_documents).map(
                ([filename, content]) => ({
                  filename,
                  value: content,
                  id: `thread-${filename}`,
                }),
              );
            }
          } catch (parseError) {
            console.error('Error parsing context data:', parseError);
          }
        }

        setReferenceData(organizedData);
      }
    } catch (error) {
      console.error('Error fetching reference data:', error);
      // If API fails, keep using empty reference data
      setReferenceData({
        company: [],
        thread: [],
      });
    } finally {
      setIsLoadingReferences(false);
    }
  }, [message?.chat_id, axiosInstance]);

  // Toggle references dropdown and fetch data if needed
  const toggleReferences = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      const newShowState = !showReferences;
      setShowReferences(newShowState);

      // Fetch data when opening the dropdown if we don't have data yet
      if (newShowState && referenceData.company.length === 0 && referenceData.thread.length === 0) {
        fetchReferenceData();
      }
    },
    [showReferences, referenceData, fetchReferenceData],
  );

  // Toggle section expansion
  const toggleSection = (section, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  // Toggle file expansion
  const toggleFile = (fileId, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setExpandedFiles({
      ...expandedFiles,
      [fileId]: !expandedFiles[fileId],
    });
  };

  return (
    <div className={clsx('mb-6 flex w-full ', message.ai ? 'justify-start' : 'justify-end')}>
      {!message.ai ? (
        <div className="text-black flex justify-center items-end mr-[2px] lg:mr-[0px] xl:mr-[7px] 2xl:mr-[3px]">
          <div className={`flex flex-1 flex-col items-end`}>
            {message.files && message.files.length > 0 && (
              <div className="flex flex-col mb-2 items-end w-full">
                {message.files.map((file) => (
                  <div
                    key={file.id}
                    className="border border-gray-300 dark:border-gray-500 px-3 py-2 rounded-lg flex items-center gap-2 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 mb-2"
                    onClick={() => handleFileClick(file)}
                  >
                    <span className="p-2 rounded-lg bg-gray-100 dark:bg-gray-900">
                      <FilesSvg
                        className="fill-icon-light-fill dark:fill-white"
                        width={24}
                        height={24}
                      />
                    </span>
                    <div className="flex flex-col">
                      <p className="text-xs text-black dark:text-white">{file.original_filename}</p>
                      <p className="text-xs text-gray-500 dark:text-gray-300">
                        {file.content_type === 'application/pdf' ? 'PDF' : file.content_type}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex items-center">
              {!editingMessage && (
                <EditIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 cursor-pointer text-close-button dark:text-gray-icon
                             2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px]"
                  onClick={handleEditClick}
                  role="img"
                  aria-label="Edit icon"
                />
              )}
              {editingMessage ? (
                <div
                  className={clsx(
                    maxWidthClass,
                    'flex bg-background-gray text-grey rounded-[24px] flex-col',
                    'px-3 py-[8px]',
                    'dark:bg-grid-gray dark:text-white text-left',
                  )}
                >
                  <textarea
                    type="text"
                    value={newMessageText}
                    onPaste={handleEditPaste}
                    onChange={handleEditTextChange}
                    onKeyDown={handleKeyDown}
                    className={clsx(
                      'w-full min-h-[150px] outline-none border-2 border-b-red-button bg-transparent rounded-lg p-1.5 mb-2 dark:text-white',
                      '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                      showHistoryPanel
                        ? '2xl:w-[748px] xl:w-[552px] lg:w-[352px] md:w-[202px] sm:w-[182px] w-[102px] '
                        : '2xl:w-[748px] xl:w-[652px] lg:w-[502px] md:w-[472px] sm:w-[312px] w-[152px]',
                    )}
                  />
                  <div className="flex space-x-4 w-full justify-end">
                    <button
                      onClick={handleCancelEdit}
                      className={clsx(
                        'px-3 py-1 bg-background-gray text-close-button rounded-lg',
                        '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                      )}
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleEditSave}
                      className={clsx(
                        'flex items-center px-3 py-1 text-white bg-red-button rounded-lg',
                        '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                        newMessageText === text ? 'opacity-50 cursor-not-allowed' : '',
                      )}
                      disabled={isLoading || newMessageText === text}
                    >
                      {isLoading ? (
                        <div className="loading-spinner mr-2" />
                      ) : (
                        <MdSend className="mr-1" />
                      )}
                      {isLoading ? 'Saving...' : 'Save'}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className={clsx(
                    maxWidthClass,
                    'bg-background-gray text-grey rounded-2xl',
                    '2xl:p-3 xl:p-3 lg:p-2 md:p-2 sm:p-2 p-1.5',
                    'relative transition-all duration-100 dark:bg-grid-gray dark:text-white text-left',
                    '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                  )}
                >
                  {parse(processedText)}
                </div>
              )}
            </div>
          </div>
          <div className="2xl:ml-2 xl:ml-2 lg:ml-2 md:ml-1.5 sm:ml-1.5 ml-1">
            {profilePicture ? (
              <img
                src={profilePicture}
                alt="User avatar"
                className="2xl:w-[40px] xl:w-[38px] lg:w-[35px] md:w-[30px] sm:w-[28px] w-[25px] rounded-full"
              />
            ) : (
              <div className="2xl:w-[40px] 2xl:h-[40px] xl:w-[38px] xl:h-[38px] lg:w-[35px] lg:h-[35px] md:w-[30px] md:h-[30px] sm:w-[28px] sm:h-[28px] w-[25px] h-[25px] rounded-full object-cover ml-1 bg-gray-200" />
            )}
          </div>
        </div>
      ) : (
        <div className={`flex flex-1 justify-start mb-4`}>
          <div className="mr-2">
            <img
              src={robo}
              alt="AI avatar"
              className="2xl:w-[40px] xl:w-[38px] lg:w-[35px] md:w-[30px] sm:w-[28px] w-[25px] rounded-full"
            />
          </div>

          <div>
            {isFreshMessage ? (
              <TypingRenderer
                text={processedText}
                setIsElysiumTyping={setIsElysiumTyping}
                typingSpeed={20}
                className={clsx(
                  maxWidthClass,
                  'bg-background-gray text-grey rounded-2xl',
                  '2xl:p-3 xl:p-3 lg:p-2 md:p-2 sm:p-2 p-1.5',
                  'relative transition-all duration-100 dark:bg-grid-gray dark:text-white text-left',
                  '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                )}
              />
            ) : (
              <div
                className={clsx(
                  maxWidthClass,
                  'bg-background-gray text-grey rounded-2xl',
                  '2xl:p-3 xl:p-3 lg:p-2 md:p-2 sm:p-2 p-1.5',
                  'relative transition-all duration-100 dark:bg-grid-gray dark:text-white text-left',
                  '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                )}
              >
                {parse(processedText)}
              </div>
            )}

            {!contextIsGeneratingAnswer && (
              <div className="flex text-close-button gap-2 text-1.5rem m-3 cursor-pointer dark:text-gray-icon">
                <div className="flex items-center">
                  {isThisMessagePlaying ? (
                    <MdOutlineStopCircle
                      className="text-white bg-red-button rounded-lg 
                      2xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[10px] text-[8px]"
                      onClick={toggleAudioState}
                      aria-label="Stop Audio"
                    />
                  ) : (
                    <AudioIcon
                      width="16"
                      height="16"
                      className="
                        fill-icon-light-fill 
                        dark:fill-white 
                        2xl:text-[18px] xl:text-[16px] 
                        lg:text-[14px] md:text-[13px] sm:text-[11px] text-[10px]
                      "
                      onClick={toggleAudioState}
                      role="img"
                      aria-label="Play Audio"
                    />
                  )}
                  <TextToSpeech
                    ref={audioRef}
                    text={cleanTextForSpeech}
                    isPlaying={isThisMessagePlaying}
                    onEnd={handleAudioEnd}
                  />
                </div>
                <div className="relative">
                  <CopyIcon
                    width="16"
                    height="16"
                    className="
                      fill-icon-light-fill 
                      dark:fill-white
                      2xl:text-[18px] xl:text-[16px] lg:text-[14px] 
                      md:text-[13px] sm:text-[11px] text-[10px]
                    "
                    onClick={handleCopy}
                    role="img"
                    aria-label="Copy icon"
                  />
                  {isCopied && (
                    <div
                      className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-success text-white py-1 px-2 rounded-lg shadow
                      2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[8px]"
                    >
                      Copied
                    </div>
                  )}
                </div>
                <LikeIcon
                  width="16"
                  height="16"
                  className={clsx(
                    'fill-icon-light-fill cursor-pointer transition-transform duration-200',
                    '2xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[11px] text-[10px] dark:fill-white',
                    isLiked ? 'scale-125 fill-red-button dark:!fill-red-button' : 'scale-100',
                    isLoading && 'opacity-50 cursor-not-allowed',
                  )}
                  onClick={handleLikeIconClick}
                  role="img"
                  aria-label="Like icon"
                />
                <DislikeIcon
                  width="16"
                  height="16"
                  className={clsx(
                    'fill-icon-light-fill cursor-pointer transition-transform duration-200',
                    '2xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[11px] text-[10px] dark:fill-white',
                    isDisliked ? 'scale-125 fill-red-button dark:!fill-red-button' : 'scale-100',
                    isLoading && 'opacity-50 cursor-not-allowed',
                  )}
                  onClick={handleDislikeIconClick}
                  role="img"
                  aria-label="Dislike icon"
                />

                {/* References dropdown trigger */}
                <div className="relative">
                  <button
                    type="button"
                    onClick={toggleReferences}
                    className="flex items-center bg-transparent border-0 p-0"
                    aria-expanded={showReferences}
                    aria-label="Show references"
                  >
                    {showReferences ? (
                      <FaChevronDown className="mr-1 " />
                    ) : (
                      <FaChevronRight className="mr-1" />
                    )}
                  </button>

                  {/* References dropdown */}
                  {showReferences && (
                    <div className="absolute left-0 mt-2 w-96 bg-white dark:bg-[#1b1c1e] rounded-md shadow-lg z-10 p-2 border border-gray-200 dark:border-gray-700">
                      <h3 className="text-sm font-medium text-gray-900 dark:text-white px-2 py-1">
                        References
                        {isLoadingReferences && (
                          <span className="ml-2 inline-block w-4 h-4">
                            <div className="loading-spinner-small" />
                          </span>
                        )}
                      </h3>

                      {/* Show message if no references found */}
                      {!isLoadingReferences &&
                        referenceData.company.length === 0 &&
                        referenceData.thread.length === 0 && (
                          <p className="text-xs text-gray-500 dark:text-gray-400 px-2 py-1">
                            No reference data available for this message.
                          </p>
                        )}

                      {/* Company related documents */}
                      {referenceData.company.length > 0 && (
                        <div className="mb-2">
                          <button
                            type="button"
                            onClick={(e) => toggleSection('company', e)}
                            className="flex items-center w-full text-left px-2 py-1 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                          >
                            {expandedSections.company ? (
                              <FaChevronDown className="mr-1" />
                            ) : (
                              <FaChevronRight className="mr-1" />
                            )}
                            <span>Company related documents ({referenceData.company.length})</span>
                          </button>

                          {expandedSections.company && (
                            <div className="pl-6">
                              {referenceData.company.map((doc, idx) => (
                                <div key={doc.id || `company-${idx}`} className="mb-1">
                                  <button
                                    type="button"
                                    onClick={(e) => toggleFile(`company-${idx}`, e)}
                                    className="flex items-center w-full text-left px-2 py-1 text-xs text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                                  >
                                    {expandedFiles[`company-${idx}`] ? (
                                      <FaChevronDown className="mr-1" />
                                    ) : (
                                      <FaChevronRight className="mr-1" />
                                    )}
                                    <span>{doc.filename}</span>
                                  </button>

                                  {expandedFiles[`company-${idx}`] && (
                                    <div className="pl-6 py-1 text-xs text-gray-600 dark:text-gray-400 border-l-2 border-gray-300 dark:border-gray-600 ml-2">
                                      {doc.value}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Thread related documents */}
                      {referenceData.thread.length > 0 && (
                        <div>
                          <button
                            type="button"
                            onClick={(e) => toggleSection('thread', e)}
                            className="flex items-center w-full text-left px-2 py-1 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                          >
                            {expandedSections.thread ? (
                              <FaChevronDown className="mr-1" />
                            ) : (
                              <FaChevronRight className="mr-1" />
                            )}
                            <span>Thread related documents ({referenceData.thread.length})</span>
                          </button>

                          {expandedSections.thread && (
                            <div className="pl-6">
                              {referenceData.thread.map((doc, idx) => (
                                <div key={doc.id || `thread-${idx}`} className="mb-1">
                                  <button
                                    type="button"
                                    onClick={(e) => toggleFile(`thread-${idx}`, e)}
                                    className="flex items-center w-full text-left px-2 py-1 text-xs text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                                  >
                                    {expandedFiles[`thread-${idx}`] ? (
                                      <FaChevronDown className="mr-1" />
                                    ) : (
                                      <FaChevronRight className="mr-1" />
                                    )}
                                    <span>{doc.filename}</span>
                                  </button>

                                  {expandedFiles[`thread-${idx}`] && (
                                    <div className="pl-6 py-1 text-xs text-gray-600 dark:text-gray-400 border-l-2 border-gray-300 dark:border-gray-600 ml-2">
                                      {doc.value}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
