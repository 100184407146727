import React, { useEffect, useState } from 'react';
import { ChatContextProvider } from '../../context/chatContext';
import Modal from '../Modal';
import Setting from '../SettingsComponent/Setting';
import { useChatContext } from '../../context/chatContext';
import PeopleComponent from './PeopleComponent';

const People = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setShowWelcomeModal, setShowHistoryGrid } = useChatContext();

  useEffect(() => {
    setShowWelcomeModal(true);
    setShowHistoryGrid(true);
  }, [setShowWelcomeModal, setShowHistoryGrid]);

  return (
    <ChatContextProvider>
      {/* Outer wrapper to enable full-page scrolling */}
      <div className="flex h-screen overflow-hidden">
        {/* Wrapper to allow scrolling from sides */}
        <div className="flex-1 h-full overflow-y-auto">
          {/* ChatView wrapper */}
          <div className="h-full">
            <PeopleComponent />
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal title="Setting" modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Setting setModalOpen={setModalOpen} />
      </Modal>
    </ChatContextProvider>
  );
};

export default People;
