import React from 'react';
import { Dialog } from '@headlessui/react';
import { MdClose } from 'react-icons/md';

const CompanyDetailsForm = ({ isOpen, onClose, formData, onChange, onSubmit, errors }) => {
  const inputClassName = `
    w-full p-2 rounded-lg
    bg-text-gray dark:bg-grid-gray
    border border-gray-300 dark:border-gray-600
    text-black dark:text-white
    focus:outline-none focus:ring-2 focus:ring-red-500
    placeholder:text-gray-500 dark:placeholder:text-gray-400
    transition-colors duration-200
  `;

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-2xl bg-white dark:bg-gray-800 rounded-xl shadow-lg">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <Dialog.Title className="text-2xl font-bold dark:text-white">
                Company Details
              </Dialog.Title>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <MdClose size={24} />
              </button>
            </div>

            <form onSubmit={onSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">
                  Company Name
                </label>
                <input
                  type="text"
                  name="target_company_name"
                  value={formData.target_company_name}
                  onChange={onChange}
                  className={inputClassName}
                  required
                  placeholder="Enter company name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">Location</label>
                <input
                  type="text"
                  name="target_location"
                  value={formData.target_location}
                  onChange={onChange}
                  className={inputClassName}
                  required
                  placeholder="Enter company location"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">
                  Portfolio Link
                </label>
                <input
                  type="text"
                  name="target_portfolio_link"
                  value={formData.target_portfolio_link}
                  onChange={onChange}
                  className={`${inputClassName} ${
                    errors?.target_portfolio_link ? 'border-red-500 focus:ring-red-500' : ''
                  }`}
                  placeholder="https://example.com"
                />
                {errors?.target_portfolio_link && (
                  <p className="mt-1 text-sm text-red-500">{errors.target_portfolio_link}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">
                  Description
                </label>
                <textarea
                  name="target_description"
                  value={formData.target_description}
                  onChange={onChange}
                  rows={4}
                  className={`${inputClassName} resize-none`}
                  required
                  placeholder="Enter company description"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">Industry</label>
                <input
                  type="text"
                  name="target_industry"
                  value={formData.target_industry}
                  onChange={onChange}
                  className={inputClassName}
                  required
                  placeholder="Enter industry type"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">
                  Company Size
                </label>
                <input
                  type="text"
                  name="target_company_size"
                  value={formData.target_company_size}
                  onChange={onChange}
                  className={inputClassName}
                  required
                  placeholder="Enter company size"
                />
              </div>

              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-red-button text-white rounded-lg hover:bg-red-600 transition-colors"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CompanyDetailsForm;
