export class TokenValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TokenValidationError';
  }
}

// Function to decode JWT token without using external libraries
const decodeJWT = (token) => {
  try {
    // JWT tokens are made of three parts: header.payload.signature
    // We only need the payload which is the second part
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return null;
  }
};

// Check if token is expired
const isTokenExpired = (token) => {
  const decodedToken = decodeJWT(token);
  if (!decodedToken) return true;
  
  // exp is the expiration time in seconds since Unix epoch
  const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
  const currentTime = Date.now();
  
  // Add a buffer of 30 seconds to account for clock skew
  const isExpired = currentTime >= expirationTime - 30000;
  
  if (isExpired) {
    console.log('Token is expired or about to expire');
  }
  
  return isExpired;
};

export const validateToken = () => {
  const token = sessionStorage.getItem('accesstoken');
  
  if (!token) {
    throw new TokenValidationError('Access token not available');
  }

  try {
    // Parse the token to check if it's valid JSON
    const parsedToken = JSON.parse(token);
    if (!parsedToken) {
      throw new TokenValidationError('Invalid token format');
    }
    
    // Check if token is expired
    if (isTokenExpired(parsedToken)) {
      throw new TokenValidationError('Token is expired');
    }
    
    return parsedToken;
  } catch (error) {
    if (error instanceof TokenValidationError) {
      throw error;
    }
    throw new TokenValidationError('Invalid token format');
  }
};

export const getValidatedToken = () => {
  try {
    return validateToken();
  } catch (error) {
    console.error('Token validation failed:', error.message);
    return null;
  }
}; 
