import React, { useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { FaRegDotCircle } from 'react-icons/fa';
import { HiMiniXMark } from 'react-icons/hi2';
import { FiGitCommit } from 'react-icons/fi';
import { useChatContext } from '../context/chatContext';
import { GoHome } from 'react-icons/go';
import { ReactComponent as OfficeIcon } from '../Svg/office.svg';
import clsx from 'clsx';

export const NewDropdown = ({ isOpen, toggleNewDropdown, onSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="absolute w-[159px] top-[60px] -left-[160px] bg-white shadow-md rounded-lg z-20 dark:bg-black dark:text-white">
      <div
        className="flex gap-4 p-2 cursor-pointer hover:bg-[#FF00000D]"
        onClick={() => {
          onSelect('Available');
          toggleNewDropdown(false);
        }}
      >
        <BsCheckCircle className="text-success 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[15px] sm:text-[13px] text-[10px]" />
        <span className="text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
          Available
        </span>
      </div>
      <div
        className="flex gap-4 p-2 cursor-pointer hover:bg-[#FF00000D]"
        onClick={() => {
          onSelect('Do not disturb');
          toggleNewDropdown(false);
        }}
      >
        <FaRegDotCircle className="text-red-button 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[15px] sm:text-[13px] text-[10px]" />
        <span className="text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
          Do not disturb
        </span>
      </div>
      <div
        className="flex gap-4 p-2 cursor-pointer hover:bg-[#FF00000D]"
        onClick={() => {
          onSelect('Set away');
          toggleNewDropdown(false);
        }}
      >
        <FiGitCommit className="text-close-button 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[15px] sm:text-[13px] text-[10px] fill-icon-light-fill stroke-icon-light-stroke dark:fill-white dark:stroke-white" />
        <span className="text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
          Set away
        </span>
      </div>
    </div>
  );
};

export const WorkLocationMenu = ({ isOpen, toggleWorkLocationMenu, onSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="absolute w-[159px] top-[100px] -left-[160px] bg-white shadow-md rounded-lg z-20 dark:bg-black dark:text-white">
      <div
        className={clsx('flex gap-2 p-2 cursor-pointer hover:bg-[#FF00000D]')}
        onClick={() => {
          onSelect('office');
          toggleWorkLocationMenu(false);
        }}
      >
        <OfficeIcon
          alt="Office"
          width="20"
          height="20"
          className="text-close-button 2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[15px] sm:text-[13px] text-[10px] fill-icon-light-fill dark:fill-white"
        />
        <span className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] text-black dark:text-white">
          Office
        </span>
      </div>
      <div
        className={clsx('flex gap-2 p-2 cursor-pointer hover:bg-[#FF00000D]')}
        onClick={() => {
          onSelect('home');
          toggleWorkLocationMenu(false);
        }}
      >
        <GoHome className="text-close-button 2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[15px] sm:text-[13px] text-[10px] dark:text-white" />
        <span className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] text-black dark:text-white">
          Home
        </span>
      </div>
    </div>
  );
};

export const StatusTextarea = ({ isOpen, setIsStatusTextareaOpen, onSubmit }) => {
  const { userStatusDescription } = useChatContext();
  const [localStatusText, setLocalStatusText] = useState(userStatusDescription || '');

  const MAX_CHARS = 400;
  const currentLength = localStatusText?.length || 0;
  const isOverLimit = currentLength === MAX_CHARS;

  if (!isOpen) return null;

  const handleSubmit = () => {
    if (isOverLimit) {
      return;
    }
    onSubmit(localStatusText);
    setIsStatusTextareaOpen(false);
  };

  const handleCancel = () => {
    setIsStatusTextareaOpen(false);
    setLocalStatusText(userStatusDescription || '');
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= MAX_CHARS) {
      setLocalStatusText(newText);
    }
  };

  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-10" />}
      <div className="absolute top-[160px] -left-[310px] bg-white rounded-lg p-4 w-[300px] shadow-lg z-20 dark:bg-black">
        <button className="absolute top-2 right-2" onClick={handleCancel}>
          <HiMiniXMark className="w-6 h-6 dark:text-white" />
        </button>

        <h3 className="font-bold text-[#141414] mb-4 dark:text-white">Set status message</h3>

        <label className="form-control w-full">
          <textarea
            className="textarea textarea-bordered h-24 w-full mb-2 bg-white dark:bg-black dark:text-white dark:border-gray-600"
            placeholder="Type here..."
            value={localStatusText || ''}
            onChange={handleTextChange}
            maxLength={MAX_CHARS}
          />
          <div className="label justify-end">
            <span
              className={`label-text-alt ${
                isOverLimit ? 'text-red-500' : 'text-gray-500 dark:text-gray-400'
              }`}
            >
              {currentLength} / {MAX_CHARS}
            </span>
          </div>
        </label>

        <div className="flex justify-between mt-4">
          <button
            className="py-2 bg-[#F1F1F1] text-[#14141499] font-bold rounded-lg w-1/4 dark:bg-background-gray dark:text-close-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 text-white font-bold rounded-lg w-1/4 ${
              isOverLimit || !localStatusText?.trim()
                ? 'bg-[#FFE6E6] cursor-not-allowed'
                : 'bg-red-button'
            }`}
            onClick={handleSubmit}
            disabled={isOverLimit || !localStatusText?.trim()}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
};
