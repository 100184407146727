import React, { useState, useEffect, forwardRef } from 'react';
import ReactJoyride from 'react-joyride';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LuPlus } from 'react-icons/lu';
import { useChatContext } from '../context/chatContext';

import useAxiosInstance from '../utils/axiosInstance';
import Modal from './Modal';
import Setting from './SettingsComponent/Setting';
import robo from '../assets/robo.png';
import { ReactComponent as ChatSvg } from '../Svg/chat.svg';
import { ReactComponent as WorkflowSvg } from '../Svg/workflow.svg';
import { ReactComponent as PeopleSvg } from '../Svg/people.svg';
import { ReactComponent as HelpSvg } from '../Svg/help.svg';
import { ReactComponent as SettingsSvg } from '../Svg/settings.svg';
import { ReactComponent as DarkModeSvg } from '../Svg/dark_mode.svg';
import { ReactComponent as LightModeSvg } from '../Svg/light_mode.svg';

import clsx from 'clsx';

const SideBar = ({
  setShowFeatureUnavailable,
  setActiveMenuIcon,
  setShowFilesSection,
  setShowChatInput,
  setShowHistoryPanel,
}) => {
  const navigate = useNavigate();
  const {
    handleNewThread,
    darkMode,
    setShowSuggestionGrid,
    setDarkMode,
    setShowPanelIcon,
    lastVisitedThreadId,
    setLastVisitedThreadId,
  } = useChatContext();
  const axiosInstance = useAxiosInstance();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeIcon, setActiveIcon] = useState('chat');
  const [joyrideRun, setJoyrideRun] = useState(false);
  const location = useLocation();
  const { select } = useParams();

  const handleIconClick = (icon) => {
    if (icon === 'help') {
      setJoyrideRun(true);
    } else if (icon === 'chat') {
      setShowSuggestionGrid(false);
      setShowFeatureUnavailable(false);
      if (lastVisitedThreadId.length > 0) {
        navigate(`/threads/${lastVisitedThreadId}`);
      } else {
        navigate('/threads/');
      }
    } else if (icon === 'workflow') {
      setShowPanelIcon(false);
      navigate('/workflow'); // Direct navigation to workflow
    } else if (icon === 'people') {
      setShowPanelIcon(false);
      navigate('/people'); // Direct navigation to people
    } else if (['files', 'labels'].includes(icon)) {
      setShowPanelIcon(false);
      setShowFeatureUnavailable(true);
      if (location.pathname === '/home' && lastVisitedThreadId.length > 0) {
        navigate(`/threads/${lastVisitedThreadId}/${icon}`);
      }
    }
    setActiveIcon(icon === activeIcon ? '' : icon);
  };

  const isActive = (icon) => {
    if (icon === 'chat') {
      return location.pathname.includes('/threads');
    }
    if (icon === 'workflow') {
      return location.pathname.includes('/workflow');
    }
    if (icon === 'people') {
      return location.pathname.includes('/people');
    }
    return activeIcon === icon;
  };

  const handleNewChat = async () => {
    try {
      const modelType = 'azure';
      const threadName = `New Chat`;
      const response = await axiosInstance.post('/api/threads/', {
        thread_name: threadName,
        model_name: modelType,
      });
      const newChat = response.data;
      handleNewThread(newChat);
      setLastVisitedThreadId(newChat.thread_id);
      handleIconClick('chat');
      setActiveMenuIcon('chat');
      setShowFilesSection(false);
      setShowChatInput(true);
      navigate(`/threads/${newChat.thread_id}`);
    } catch (error) {
      console.error('Error creating thread:', error.message);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode((prev) => {
      const newMode = !prev;
      document.documentElement.classList.toggle('dark', newMode);
      localStorage.setItem('darkMode', newMode);
      return newMode;
    });
  };
  /**
   * CustomTooltip component
   * @param {Object} props - The props object
   * @param {boolean} props.continuous - Whether the tooltip is continuous
   * @param {number} props.index - The index of the step
   * @param {Object} props.primaryProps - The primary props
   * @param {Object} props.skipProps - The skip props
   * @returns {React.ReactNode} - The rendered tooltip component
   */
  function CustomTooltip(props) {
    const { continuous, index, primaryProps, skipProps, step, tooltipProps, size } = props;

    const currentStep = index + 1;
    const totalSteps = size;

    return (
      <div
        className="tooltip__body dark:bg-black w-[300px] bg-white rounded-lg shadow-lg p-4 z-50"
        {...tooltipProps}
      >
        <div className="w-full flex justify-between text-black z-50">
          <h3 className="text-black font-bold dark:text-white">Dashboard</h3>
          <button
            className="tooltip__close text-black dark:text-white hover:text-gray-700  "
            {...skipProps}
          >
            &times;
          </button>
        </div>

        <div className="text-black text-sm my-2 dark:text-white">
          Step {currentStep} of {totalSteps}
        </div>
        {step.title && (
          <h4 className="tooltip__title text-lg font-semibold text-gray-800 dark:text-white">
            {step.title}
          </h4>
        )}
        <div className="tooltip__content text-black dark:text-white 2xl:text-[16px] xl:text-[14px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[9px]">
          {step.content}
        </div>
        <div className="tooltip__footer flex justify-between mt-4">
          <button
            className="tooltip__button px-4 py-2 mr-2 bg-background-gray text-close-button rounded-lg 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
            {...skipProps}
          >
            {skipProps.title}
          </button>
          <div className="tooltip__spacer flex items-center">
            {index > 0 && <p></p>}
            {continuous && (
              <button
                className="
            tooltip__button
                      px-4 py-2 text-white bg-red-button rounded-lg 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
                {...primaryProps}
              >
                {currentStep === totalSteps ? 'Got It' : 'Next'}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  const steps = [
    {
      target: '.threads-creation',
      content: 'Your dashboard gives an overview of all active threads and recent activity.',
      disableBeacon: true,
      placement: 'top',
      disableScrolling: true,
    },
    {
      target: '.navigation-links',
      content: 'This is where you can access chats, workflows, people, and more.',
      disableBeacon: true,
      placement: 'top',
      disableScrolling: true,
    },
    {
      target: '.bottom-section',
      content: "From dashboard it's easy to stay organized and on top of your tasks.",
      disableBeacon: true,
      placement: 'top',
      disableScrolling: true,
    },
  ];

  useEffect(() => {
    if (location.pathname.includes('/threads')) {
      setActiveIcon('chat');
    } else if (location.pathname.includes('/workflow')) {
      setActiveIcon('workflow');
    } else if (location.pathname.includes('/people')) {
      setActiveIcon('people');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (select) {
      handleIconClick(select);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);
  const isDarkMode = JSON.parse(localStorage.getItem('darkMode'));

  return (
    <div className="relative flex bg-background-gray dark:bg-black  z-16">
      {/* React Joyride */}
      <ReactJoyride
        steps={steps}
        run={joyrideRun}
        showSkipButton
        showProgress
        continuous
        disableOverlayClose
        hideBackButton={true}
        spotlightPadding={0}
        disableOverlay={false}
        tooltipComponent={(props) => <CustomTooltip {...props} setJoyrideRun={setJoyrideRun} />}
        floaterProps={{
          styles: {
            arrow: {
              color: isDarkMode ? '#141414' : 'white',
            },
          },
        }}
        callback={(data) => {
          const { status } = data;
          if (status === 'finished' || status === 'skipped') {
            setJoyrideRun(false);
          }
        }}
      />

      <section className="2xl:w-[64px] lg:w-[60px] h-[85vh] flex flex-col bg-background-gray text-text-black dark:bg-black dark:text-white">
        <div className="relative flex-grow flex flex-col items-center space-y-4 mt-4 ">
          <div className="threads-creation flex flex-col items-center space-y-2">
            {/* Threads Creation */}
            <div
              className="relative inline-block group cursor-pointer px-3"
              onClick={handleNewChat}
            >
              {isActive('home') && (
                <div className="absolute right-0 h-full border-r-4 border-red-button" />
              )}
              <div className={`relative flex items-center w-full ${isActive('home') ? '' : ''}`}>
                <img
                  src={robo}
                  alt="Robo Logo"
                  className="lg:w-[40px] md:w-[26px] sm:w-[24px] w-[22px] transition-opacity duration-200"
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-button opacity-0 hover:opacity-50 transition-all duration-200 z-10 rounded-md flex items-center justify-center w-full h-full">
                  <LuPlus className="text-white cursor-pointer 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]" />
                </div>
              </div>
            </div>
          </div>
          {/* Navigation Links */}
          <div className="relative  navigation-links flex flex-col items-center space-y-2">
            <div
              onClick={() => !isActive('chat') && handleIconClick('chat')}
              className={`cursor-pointer justify-center w-full relative flex items-center${
                isActive('chat') ? '' : ''
              }`}
            >
              {isActive('chat') && (
                <div className="absolute right-0 h-full border-r-4 border-red-button" />
              )}
              <div
                className={`rounded-md  justify-center items-center flex flex-col w-full ${
                  isActive('chat')
                    ? 'text-red-button'
                    : 'text-close-button hover:bg-text-gray hover:text-black'
                } py-1 px-2 dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon`}
              >
                <ChatSvg
                  width="22"
                  height="22"
                  className={clsx(
                    'mx-auto 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]',
                    {
                      'fill-red-button': isActive('chat'),
                      'fill-icon-light-fill dark:fill-white': !isActive('chat'),
                    },
                  )}
                />
                <p
                  className={clsx(
                    '2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]',
                    {
                      'text-red-button': isActive('chat'),
                      'text-close-button dark:text-gray-icon': !isActive('chat'),
                    },
                  )}
                >
                  Chat
                </p>
              </div>
            </div>

            <div
              onClick={() => !isActive('workflow') && handleIconClick('workflow')}
              className={`cursor-pointer relative flex items-center ${
                isActive('workflow') ? '' : ''
              }`}
            >
              {isActive('workflow') && (
                <div className="absolute right-0 h-full border-r-4 border-red-button" />
              )}
              <div
                className={`rounded-md ${
                  isActive('workflow')
                    ? 'text-red-button'
                    : 'text-close-button hover:bg-text-gray hover:text-black'
                } p-1 dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon`}
              >
                <WorkflowSvg
                  width="22"
                  height="22"
                  className={clsx(
                    'mx-auto 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]',
                    {
                      'fill-red-button': isActive('workflow'),
                      'fill-icon-light-fill dark:fill-white': !isActive('workflow'),
                    },
                  )}
                />

                <p
                  className={clsx(
                    '2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] mr-1 text-[6px]',
                    {
                      'text-red-button': isActive('workflow'),
                      'text-close-button dark:text-gray-icon': !isActive('workflow'),
                    },
                  )}
                >
                  Workflow
                </p>
              </div>
            </div>

            <div
              onClick={() => !isActive('people') && handleIconClick('people')}
              className={`cursor-pointer justify-center  w-full relative flex items-center ${
                isActive('people') ? ' ' : ''
              }`}
            >
              {isActive('people') && (
                <div className="absolute right-0 h-full border-r-4 border-red-button" />
              )}
              <div
                className={`rounded-md justify-center items-center flex flex-col  w-full ${
                  isActive('people')
                    ? 'text-red-button'
                    : 'text-close-button hover:bg-text-gray hover:text-black'
                } py-1 px-2 dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon`}
              >
                <PeopleSvg
                  width="22"
                  height="22"
                  className={clsx(
                    'mx-auto 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]',
                    {
                      'fill-red-button': isActive('people'),
                      'fill-icon-light-fill dark:fill-white': !isActive('people'),
                    },
                  )}
                />
                <p
                  className={clsx(
                    '2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]',
                    {
                      'text-red-button': isActive('people'),
                      'text-close-button dark:text-gray-icon': !isActive('people'),
                    },
                  )}
                >
                  People
                </p>
              </div>
            </div>

            {/* <div
              onClick={() => handleIconClick('labels')}
              className={`cursor-pointer relative flex items-center ${
                isActive('labels') ? '' : ''
              }`}
            >
              {isActive('labels') && (
                <div className="absolute right-0 h-full border-r-4 border-red-button" />
              )}
              <div
                className={`rounded-md ${
                  isActive('labels')
                    ? 'text-red-button'
                    : 'text-close-button hover:bg-text-gray hover:text-black'
                } py-1 px-2 dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon`}
              >
                <LabelsSvg
                  width="22"
                  height="22"
                  className="2xl:ml-1 xl:ml-1 lg:ml-2 md:ml-1.5 sm:ml-1 ml-1 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
                />
                <p className="2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]">
                  Labels
                </p>
              </div>
            </div> */}

            {/* <div
              onClick={() => handleIconClick('files')}
              className={`cursor-pointer relative flex items-center ${isActive('files') ? '' : ''}`}
            >
              {isActive('files') && (
                <div className="absolute right-0 h-full border-r-4 border-red-button" />
              )}
              <div
                className={`rounded-md ${
                  isActive('files')
                    ? 'text-red-button'
                    : 'text-close-button hover:bg-text-gray hover:text-black'
                } py-1 px-3 dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon`}
              >
                <FilesSvg
                  width="22"
                  height="22"
                  className="2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
                />
                <p className="2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]">
                  Files
                </p>
              </div>
            </div> */}
          </div>
        </div>

        {/* Bottom Section */}
        <div className="bottom-section flex flex-col items-center space-y-2 py-4">
          <div
            onClick={() => handleIconClick('help')}
            className="text-close-button cursor-pointer hover:bg-text-gray hover:text-black py-1 px-3 rounded-md dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon"
          >
            <HelpSvg
              width="22"
              height="22"
              className="fill-icon-light-fill dark:fill-white 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
            />
            <p className="2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]">
              Help
            </p>
          </div>
          <div
            className="text-close-button cursor-pointer hover:bg-text-gray hover:text-black p-1 rounded-md dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon"
            onClick={() => {
              setModalOpen(true);
              setShowHistoryPanel(false);
            }}
          >
            <SettingsSvg
              width="22"
              height="22"
              className="mx-auto fill-icon-light-fill dark:fill-white  2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
            />
            <p className="2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]">
              Settings
            </p>
          </div>
          <div
            className="text-close-button cursor-pointer hover:bg-text-gray p-0.5 hover:text-black rounded-md dark:hover:bg-grid-gray dark:hover:text-white dark:text-gray-icon"
            onClick={toggleDarkMode}
          >
            {darkMode ? (
              <LightModeSvg
                width="22"
                height="22"
                className="mx-auto fill-icon-light-fill dark:fill-white 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
              />
            ) : (
              <DarkModeSvg
                width="22"
                height="22"
                className="mx-auto fill-icon-light-fill dark:fill-white 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]"
              />
            )}
            <p className="2xl:text-[10px] xl:text-[10px] lg:text-[9px] md:text-[8px] sm:text-[7px] text-[6px]">
              {darkMode ? 'Light Mode' : 'Dark Mode'}
            </p>
          </div>
        </div>
      </section>

      <Modal title="Setting" modalOpen={modalOpen} setModalOpen={setModalOpen} size="medium">
        <Setting modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Modal>
    </div>
  );
};

export default SideBar;
