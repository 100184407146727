/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import ShareChat from './ShareChat';
import RenameModal from './RenameModal';
import { useChatContext } from '../context/chatContext';
import { ReactComponent as ImagesIcon } from '../Svg/images.svg';
import { ReactComponent as ChatIcon } from '../Svg/chat.svg';
import { ReactComponent as SearchIcon } from '../Svg/search.svg';
import { ReactComponent as MoreIcon } from '../Svg/more_horiz.svg';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';
import { ReactComponent as WriteIcon } from '../Svg/write.svg';
import { ReactComponent as ShareIcon } from '../Svg/share.svg';
import { ReactComponent as DownloadIcon } from '../Svg/download.svg';
import { ReactComponent as ArrowRightIcon } from '../Svg/arrow_right.svg';
import { ReactComponent as DeleteIcon } from '../Svg/delete.svg';
import removeMarkdown from 'remove-markdown';
import '../index.css';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

const ChatMenu = ({
  onImageIconClick,
  onChatIconClick,
  showHistoryPanel,
  activeMenuIcon,
  setActiveMenuIcon,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isExportSubMenuOpen, setIsExportSubMenuOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isFilesSectionOpen, setIsFilesSectionOpen] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [threadName, setThreadName] = useState('');
  const searchInputRef = useRef(null);
  const menuRef = useRef(null);
  const searchRef = useRef(null);
  const renameRef = useRef(null);
  const { threadId } = useParams();

  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const {
    handleDeleteClick,
    defaultThread,
    chats,
    messages,
    updateThreadTitle,
    fetchThreads,
    searchQuery,
    setSearchQuery,
    searchResultExists,
  } = useChatContext();

  const handleRenameClick = (event) => {
    setIsRenameModalOpen(true);
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    const currentThread = chats.find((chat) => chat.thread_id === defaultThread);
    setThreadName(currentThread ? currentThread.thread_name : '');
    setModalPosition({ top: top + height - 55, left: left - 15 });
    setIsMenuOpen(false);

    setTimeout(() => {
      if (renameRef.current) {
        renameRef.current.focus();
      }
    }, 0);
  };

  const handleCloseRenameModal = () => setIsRenameModalOpen(false);

  const handleConfirmRename = async () => {
    try {
      await updateThreadTitle(defaultThread, threadName);
      await fetchThreads();
    } catch (error) {
      toast.error('Error updating thread title:');
    } finally {
      setIsRenameModalOpen(false);
    }
  };

  const closeSearchBar = () => {
    setSearchQuery('');
    setShowSearchBar(false);
  };

  useEffect(() => {
    if (showSearchBar && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearchBar]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target) &&
        !event.target.closest('.search-icon')
      ) {
        closeSearchBar();
      }

      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchIconClick = () => {
    setShowSearchBar((prev) => !prev);
    setActiveMenuIcon('search');
  };

  const handleSearchSubmit = () => {
    if (!searchResultExists && searchQuery.trim() !== '') {
      toast.error('No results found');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveMenuIcon('menu');
  };
  const toggleExportSubMenu = () => setIsExportSubMenuOpen(!isExportSubMenuOpen);

  const handleShareIconClick = () => {
    setIsMenuOpen(false);
    setIsShareModalOpen(true);
  }
  const closeShareModal = () => setIsShareModalOpen(false);

  const handleDownload = async (format) => {
    const currentMessages = messages;
    setIsMenuOpen(false);
    const currentThread = chats.find((chat) => chat.thread_id === threadId);

    if (!currentMessages || currentMessages.length === 0) return;

    let fileBlob;
    let fileName = `chat-${currentThread?.thread_id}`;

    if (format === 'docx') {
      fileBlob = await generateDocxBlob(currentThread);
      fileName += '.docx';
    } else if (format === 'txt') {
      fileBlob = generateTXTBlob(currentThread);
      fileName += '.txt';
    } else if (format === 'pdf') {
      fileBlob = await generatePDFBlob(currentThread);
      fileName += '.pdf';
    }

    const url = URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const generateTXTBlob = (currentThread) => {
    const exportDate = new Date().toLocaleString();
    const readableDate = (date) => new Date(date).toLocaleString();
    const dashedLine = '-'.repeat(80);
    const textFileHeader = `Title: ${
      currentThread?.thread_name
    } \n\nChat Transcript: ${readableDate(new Date())}\n\nBy: ${
      currentThread?.thread_admin
    }\n\nExport Date: ${exportDate}\n\n`;
    let textFileBody = '\n\n';
    for (let i = 0; i < currentThread?.chats.length; i++) {
      textFileBody += `${!currentThread?.chats[i]?.ai ? `${dashedLine}\n\n` : ''}${
        !currentThread?.chats[i]?.ai
          ? `[${currentThread?.thread_admin}] ${readableDate(
              currentThread?.chats[i]?.createdAt,
            )} \n\n${currentThread?.chats[i]?.text}\n\n\n`
          : `[Ava] ${readableDate(currentThread.chats[i]?.createdAt)} \n\n${removeMarkdown(
              currentThread?.chats[i]?.text,
            )}\n\n\n`
      }`;
    }
    const File = textFileHeader + textFileBody;

    return new Blob([File], { type: 'text/plain' });
  };

  const generateDocxBlob = async (currentThread) => {
    const exportDate = new Date().toLocaleString();
    const readableDate = (date) => new Date(date).toLocaleString();
    const { Packer, Document, Paragraph, TextRun, Header, Footer, PageNumber, AlignmentType } =
      await import('docx');

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: 24, // 12pt font
              font: {
                name: 'Poppins',
              },
            },
            paragraph: {
              spacing: {
                line: 360, // This is equivalent to 1.5 line spacing (240 = single spacing)
                after: 50, // Space after paragraph
              },
            },
          },
        },
      },
      sections: [
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${currentThread?.thread_name}`,
                      color: '#D3D3D3',
                      size: 18,
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'Ava[f4/agi_reasoner]',
                      color: '#D3D3D3',
                      size: 16,
                    }),
                  ],
                  alignment: AlignmentType.LEFT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                      size: 16,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
              ],
            }),
          },
          children: [
            // Chat Transcript
            new Paragraph({
              children: [
                new TextRun({
                  text: `Chat Transcript: ${readableDate(new Date())}`,
                  size: 20,
                }),
              ],
            }),
            // By
            new Paragraph({
              children: [
                new TextRun({
                  text: `By: ${currentThread?.thread_admin}`,
                  size: 20,
                }),
              ],
            }),
            // Export Date
            new Paragraph({
              children: [
                new TextRun({
                  text: `Export Date: ${exportDate}`,
                  size: 20,
                }),
              ],
            }),
            // Spacing
            new Paragraph({
              children: [new TextRun({ text: '' })],
            }),
            // Chat Messages
            ...(currentThread?.chats || [])
              .map((chat) => [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `[${!chat.ai ? currentThread?.thread_admin : 'Ava'}]`,
                      bold: true,
                    }),
                    new TextRun({
                      text: ` ${readableDate(chat.createdAt)}`,
                    }),
                  ],
                  spacing: {
                    after: 200,
                    line: 360,
                  },
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: chat.ai ? removeMarkdown(chat.text) : chat.text,
                    }),
                  ],
                  spacing: {
                    after: chat?.ai ? 400 : 0,
                    line: 360,
                  },
                }),
              ])
              .flat(),
          ],
        },
      ],
    });

    return Packer.toBlob(doc);
  };

  const generatePDFBlob = async (currentThread) => {
    const exportDate = new Date().toLocaleString();
    const readableDate = (date) => new Date(date).toLocaleString();

    // Import pdfmake dynamically
    const pdfMakeModule = await import('pdfmake/build/pdfmake');
    const pdfFontsModule = await import('pdfmake/build/vfs_fonts');

    // Access the pdfmake instance
    const pdfMake = pdfMakeModule.default || pdfMakeModule;

    // Assign the virtual file system
    pdfMake.vfs = pdfFontsModule.pdfMake ? pdfFontsModule.pdfMake.vfs : pdfFontsModule.default;

    // Register Poppins font - Using Roboto as fallback since Poppins files may not be available
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf',
      },
    };

    // Define document content
    const docDefinition = {
      defaultStyle: {
        font: 'Roboto', // Use Roboto which is built into pdfmake
        fontSize: 12,
        lineHeight: 1.5,
      },
      footer: function (currentPage) {
        return [
          {
            columns: [
              {
                text: 'Ava[f4/agi_reasoner]',
                color: '#A9A9A9',
                fontSize: 8,
                alignment: 'left',
                margin: [40, 0, 0, 0],
                bold: true,
              },
              {
                text: `${currentPage}`,
                fontSize: 8,
                margin: [0, 0, 40, 0],
                bold: true,
                alignment: 'right',

                color: '#A9A9A9',
              },
            ],
          },
        ];
      },
      content: [
        // Chat Transcript Title
        {
          text: `${currentThread?.thread_name}`,
          fontSize: 24,
          margin: [0, 0, 0, 10],
          bold: true,
        },
        {
          text: `Chat Transcript: ${readableDate(new Date())}`,
          fontSize: 12,
          margin: [0, 0, 0, 5],
          bold: true,
        },
        // By
        {
          text: `By: ${currentThread?.thread_admin}`,
          fontSize: 12,
          margin: [0, 0, 0, 5],
          bold: true,
        },
        // Export Date
        {
          text: `Export Date: ${exportDate}`,
          fontSize: 12,
          margin: [0, 0, 0, 15],
          bold: true,
        },
        { text: '' },
        // Chat Messages
        ...(currentThread?.chats || [])
          .map((chat) => [
            {
              text: `[${!chat.ai ? currentThread?.thread_admin : 'Ava'}] ${readableDate(
                chat.createdAt,
              )}`,
              margin: [0, 0, 0, 10],
            },
            {
              text: chat.ai ? removeMarkdown(chat.text) : chat.text,
              margin: [0, 0, 0, chat.ai ? 30 : 10],
            },
          ])
          .flat(),
      ],
    };

    // Generate PDF as blob
    return new Promise((resolve) => {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        resolve(blob);
      });
    });
  };

  return (
    <div className="relative flex items-center justify-start space-x-2 z-20  chat-menu">
      <div
        className={`${
          activeMenuIcon === 'chat' ? ' border-red-button' : 'border-transparent'
        } border-b-2 p-2`}
      >
        <ChatIcon
          width="20"
          height="20"
          className={clsx('fill-icon-light-fill ', {
            'fill-red-button ': activeMenuIcon === 'chat',
            'dark:fill-white': activeMenuIcon !== 'chat',
          })}
          onClick={() => {
            onChatIconClick();
          }}
          role="img"
          aria-label="Chat icon"
        />
      </div>
      <div
        className={`${
          activeMenuIcon === 'image' ? ' border-red-button' : 'border-transparent'
        } p-2 border-b-2`}
      >
        <ImagesIcon
          width="20"
          height="20"
          className={clsx('fill-icon-light-fill', {
            'fill-red-button ': activeMenuIcon === 'image',
            'dark:fill-white': activeMenuIcon !== 'image',
          })}
          onClick={onImageIconClick}
          role="img"
          aria-label="Images icon"
        />
      </div>
      {/* {isFilesSectionOpen && (
        <div className="absolute top-8 right-0 w-[300px]">
          <FilesSection />
        </div>
      )} */}

      <div className="cursor-pointer text-close-button relative search-icon" ref={searchRef}>
        <div
          className={`${
            activeMenuIcon === 'search' ? ' border-red-button' : 'border-transparent'
          } border-b-2 p-2`}
        >
          <SearchIcon
            width="20"
            height="20"
            className={clsx('fill-icon-light-fill', {
              'fill-red-button ': activeMenuIcon === 'search',
              'dark:fill-white': activeMenuIcon !== 'search',
            })}
            onClick={handleSearchIconClick}
            role="img"
            aria-label="Search icon"
          />
        </div>

        {showSearchBar && (
          <div
            className={`absolute top-full bg-white text-[#141414] rounded-lg -left-[4.7rem] mt-2 dark:bg-[#1B1C1E] ${
              showHistoryPanel
                ? '2xl:w-[750px] xl:w-[600px] lg:w-[440px] md:w-[290px] sm:w-[240px] w-[250px]'
                : '2xl:w-[790px] xl:w-[700px] lg:w-[530px] md:w-[490px] sm:w-[400px] w-[220px] -left-[70px]'
            }`}
          >
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                ref={searchInputRef}
                className="w-full px-4 2xl:py-1.5 xl:py-1.5 lg:py-1.5 md:py-1.5 sm:py-1 py-1 px-8 pr-8 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] placeholder:text-black rounded-lg bg-transparent border border-gray-500 dark:text-white placeholder:dark:text-white focus:outline-none "
                onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
              />

              <SearchIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white absolute top-[50%] left-3 transform -translate-y-1/2 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[12px] text-[10px] text-close-button dark:text-white"
                role="img"
                aria-label="Search icon"
              />

              <CloseIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white absolute right-2 top-2 cursor-pointer 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] text-close-button dark:text-white"
                role="img"
                aria-label="Close icon"
                onClick={closeSearchBar}
              />
            </div>
          </div>
        )}
      </div>

      <div className="relative" ref={menuRef}>
        <div
          className={`${
            activeMenuIcon === 'menu' ? ' border-red-button' : 'border-transparent'
          } border-b-2 p-2`}
        >
          <MoreIcon
            width="20"
            height="20"
            className={clsx('fill-icon-light-fill', {
              'fill-red-button': activeMenuIcon === 'menu',
              'dark:fill-white': activeMenuIcon !== 'menu',
            })}
            onClick={toggleMenu}
            role="img"
            aria-label="More options"
          />
        </div>
        {isMenuOpen && (
          <div className="absolute w-[8rem] bg-white border border-gray-200 rounded-md shadow-lg dark:bg-black">
            <ul className="py-1">
              <li
                className="flex items-center text-sm font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                onClick={handleRenameClick}
              >
                <WriteIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Write icon"
                />
                Rename
              </li>

              <li
                className="flex items-center text-sm font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                onClick={handleShareIconClick}
              >
                <ShareIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Share icon"
                />
                Share
              </li>

              <div
                className="flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-[#1B1C1E]"
                onClick={toggleExportSubMenu}
              >
                <li className="relative flex items-center text-sm font-semibold px-4 py-2 text-gray-800 dark:text-white">
                  <DownloadIcon
                    width="20"
                    height="20"
                    className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                    role="img"
                    aria-label="Download icon"
                  />
                  Export
                </li>

                <ArrowRightIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white ml-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Arrow right icon"
                />

                {isExportSubMenuOpen && (
                  <ul className="absolute left-full top-20 mt-0 w-[9rem] bg-white border border-gray-200 rounded-md shadow-lg dark:bg-black">
                    <li
                      className="px-4 py-2 hover:bg-[#FFFAFA] text-black text-sm cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                      onClick={() => handleDownload('pdf')}
                    >
                      Export to PDF
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-[#FFFAFA] text-black text-sm cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                      onClick={() => handleDownload('docx')}
                    >
                      Export to DOCX
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-[#FFFAFA] text-black text-sm cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                      onClick={() => handleDownload('txt')}
                    >
                      Export to TXT
                    </li>
                  </ul>
                )}
              </div>

              <li
                className="flex items-center font-semibold text-sm px-4 py-2 text-black cursor-pointer hover:bg-gray-100 dark:text-white dark:hover:bg-[#1B1C1E]"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpen(false);
                  handleDeleteClick(chats.find((chat) => chat.thread_id === defaultThread));
                }}
              >
                <DeleteIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Delete icon"
                />
                Delete
              </li>
            </ul>
          </div>
        )}

        <ShareChat isOpen={isShareModalOpen} onClose={closeShareModal} />

        {isRenameModalOpen && (
          <RenameModal
            isOpen={isRenameModalOpen}
            onClose={handleCloseRenameModal}
            initialThreadName={threadName}
            onConfirm={handleConfirmRename}
            position={modalPosition}
            onThreadNameChange={setThreadName}
            renameRef={renameRef}
          />
        )}
      </div>
    </div>
  );
};

export default ChatMenu;
